import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'

import DataTable from 'react-data-table-component';

function ChallengePage() {
  
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)
  const [ items, setItems ] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    callGetUserApi()
  }, [])


  const columns = [
    {
        name: 'รายการ',
        selector: row => row.name,
        wrap : true
    },
    {
        name: 'เจ้าของกิจกรรม',
        selector: row => row.owner,
        wrap : true
    },
    {
        name: 'วันเริ่มต้น',
        selector: row => row.start_date,
        wrap : true
    },
    {
        name: 'วันสิ้นสุด',
        selector: row => row.end_date,
        wrap : true
    },
    {
        name: 'สถานะ',
        selector: row => (row.is_show === '0')?  <span className='text-xs px-2 py-1 bg-red-500 text-white'>ไม่แสดง</span> : <span className='text-xs px-4 py-1 bg-green-500 text-white'>แสดง</span>,
        wrap : true
    },
    {
      name: '',
      cell:(row)=> <div className='flex flex-wrap'><button onClick={ () => gotoDetail( row.challenge_id ) } className="flex py-1 px-2 mt-1 rounded-md bg-blue-700 text-sm text-white mx-2 ">แก้ไข</button></div>,
      wrap : true
    },
    {
      name: '',
      cell:(row)=> <div className='flex flex-wrap'><button onClick={ () => gotoRanking( row.challenge_id ) } className="flex py-1 px-2 mt-1 rounded-md bg-blue-700 text-sm text-white mx-2 ">อันดับคะแนน</button></div>,
      wrap : true
    },
  ];

  function gotoDetail( token ){
    navigate("/challenge/edit", { state : {
      token : token
    }})
  }

  function gotoRanking( token ){
    navigate("/challenge/ranking", { state : {
      token : token
    }})
  }
  


  function gotoNewUser(){
    navigate("/challenge/create")
  }



  function callGetUserApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallenge.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    setItems(data.data.result )
  }
}


  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

          <div className=" w-full flex flex-wrap  bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4 ">
            
            <div className='flex-wrap mb-2'>
              <div className='w-full flex'>
                <span className=' w-full text-lg md:text-2xl font-bold  '>รายการกิจกรรม</span>
              </div>
            </div>

            <div className='flex flex-wrap w-full mb-2 mt-4'>
            
              <button onClick={ gotoNewUser } className='py-2 px-6  rounded-md bg-blue-700 text-sm text-white'>สร้างกิจกรรมใหม่</button>


              <div className='w-full mt-4'>

                  <DataTable
                    columns={columns}
                    data={  items }
                    pagination
                  />
              </div>
            </div>
          </div>
        </div>

      </div>
  )
}

export default ChallengePage;