import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'

import DataTable from 'react-data-table-component';

function ChallengeRankingPage() {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const [ rankingId, setRankingId ] = useState(0)
  const [ challengeId, setChallengeId ] = useState("")

  const MySwal = withReactContent(Swal)
  const [ items, setItems ] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    setChallengeId(state.token)
    callApi(0,state.token)
  }, [])


  const columns = [
    {
        name: 'อันดับ',
        selector: row => row.rank,
        width: '150px'
    },
    {
        name: 'ชื่อผู้เข้าร่วม',
        selector: row => row.display_name,
        wrap : true
    },
    {
        name: 'จำนวน',
        selector: row => row.total,
        wrap : true
    },
  ];


  function callApi(id, challengeId) {
    setRankingId(id)
    if(id === 0) {
      callGetUserAll(challengeId)
    }else {
      callGetUserDisticnt(challengeId)
    }
  }


  function callGetUserDisticnt( challengeId ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: challengeId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/get_challenge_rank_by_distinct.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function callGetUserAll( challengeId ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: challengeId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/get_challenge_rank_by_all.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  console.log(data)
  if( data.data.top !== undefined){
    setItems(data.data.top )
  }
}


  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200 justify-center">

          <div className=" w-3/4 flex flex-wrap  bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4 ">
            
            <div className='flex-wrap mb-2'>
              <div className='w-full flex'>
                <span className=' w-full text-lg md:text-2xl font-bold  '>อันดับคะแนน</span>
              </div>

              <div className='w-[480px] mt-3 flex flex-wrap'>
                <div 
                onClick={(e) => { callApi(0, challengeId) }}
                className={(rankingId === 0 ? ' bg-blue-500 ' : ' bg-gray-200  ')+' w-1/2  py-3 px-4 rounded-l-lg cursor-pointer'}>
                  <span>จัดอันดับตามผลิตภัณฑ์ที่ส่ง</span>
                </div>
                <div 
                onClick={(e) => { callApi(1, challengeId) }}
                className={(rankingId === 1 ? ' bg-blue-500 ' : ' bg-gray-200  ')+' w-1/2 py-3 px-4 rounded-r-lg cursor-pointer'}>
                  <span>จัดอันดับตามผลิตภัณฑ์ที่ไม่ซ้ำ</span>
                </div>
              </div>

            </div>

            <div className='flex flex-wrap w-full mb-2 mt-4'>
            
         
              <div className='w-full mt-4'>

                  <DataTable
                    columns={columns}
                    data={  items }
                    pagination
                  />
              </div>
            </div>
          </div>
        </div>

      </div>
  )
}

export default ChallengeRankingPage;