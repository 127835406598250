import React, { useState, useEffect, useRef } from 'react';
import  { useNavigate, useLocation,  } from 'react-router-dom'

import Header from '../pages/Header.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import { MapInteractionCSS } from 'react-map-interaction';

import AutoCompleteGroupText from '../components/AutoCompleteGroupText';
import AutoCompleteText from '../components/AutoCompleteText';

import DataTable from 'react-data-table-component';

function ProductHistoryDetail( props ) {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)



  useEffect(() => {
    window.scrollTo(0, 0)
    setBarcode(state.barcode)
    setLogId(state.log_id)
    setRevisionProduct(state.revision_of_product)
    setRevisionUpdate(state.update_at)
    setRevisionCreateBy(state.create_by)
    callGetPackageUnitApi()
  }, [])

  function isNumberNullOrNotInput( data ){
    if( !data){
      return "-" //ไม่มี
    }
    else if( data === '-1'){
      return "" //ไม่ได้กรอก
    }
    return parseFloat(data)
  }

  function callGetPackageUnitApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getPackageUnits.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetPackageUnitApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetPackageUnitApi( data ){
    if( data.data.result !== undefined) {
      const packages = data.data.result
      setUnitList(packages)

      callGetGroupFoodApi()
    }
  }


  function callGetGroupFoodApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getGroupFoods.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetGroupFoodApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetGroupFoodApi( data ){
    if( data.data.result !== undefined) {
      const groups = data.data.result
      setGroupFoodList(groups)

      callGetProductsApi(state.log_id)

    }
  }




  function callGetProductsApi( logId){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          log_id: logId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductHistoryByLogId.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      
      }
    });
  
}

function validateGetProductApi(data ){
  if( data.data.result !== undefined){
    const product = data.data.result

    //
    setProductsId(product.products_id)

    const overview = product.overview
    setProductCodeFDA(overview.fda_no) 
    setProductBrand(overview.brand) 
    setProductName(overview.name) 
    setProductGroup(parseInt(overview.group.id)) 
    setProductPackingUnit(parseInt(overview.package_unit.id)) 
    setProductNetWeight(isNumberNullOrNotInput(overview.total_weight)) 



    const nutrition_information = product.nutrition_information
    //Nutrition Information
    setNutritionInfoLabel(parseInt(nutrition_information.type_of_label.id))
    //หนึ่งหน่วยบริโภค
    setNutritionInfoOneServingAmount(isNumberNullOrNotInput(nutrition_information.per_one_portion.amount))
    setNutritionInfoOneServingUnit(parseInt(nutrition_information.per_one_portion.package_unit.id))
    setNutritionInfoOneServingGram(isNumberNullOrNotInput(nutrition_information.per_one_portion.gram))
    //จำนวนหน่วยบริโภคต่อ
    setNutritionInfoNumberOfServingsPer(parseInt(nutrition_information.count_portion.type.id))
    setNutritionInfoNumberOfServingsPerAmount(isNumberNullOrNotInput(nutrition_information.count_portion.amount))

    const nutrition_value = product.nutrition_value
    //คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค
    //พลังงานทั้งหมด
    setTotalPower(isNumberNullOrNotInput(nutrition_value.cal.amount))
    //พลังงานจากไขมัน
    setEnergyFromFat(isNumberNullOrNotInput(nutrition_value.calfat.amount))
    //ไขมันทั้งหมด
    setTotalFatAmount(isNumberNullOrNotInput(nutrition_value.fat.amount))
    setTotalFatPercentage(isNumberNullOrNotInput(nutrition_value.fat.percentage))
    //ไขมันอิ่มตัว
    setSaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.saturated_fat.amount))
    setSaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.saturated_fat.percentage))
    //โคเลสเตอรอล
    setCholesterolSymbol(nutrition_value.cholesterol.symbol.id)
    setCholesterolAmount(isNumberNullOrNotInput(nutrition_value.cholesterol.amount))
    setCholesterolPercentage(isNumberNullOrNotInput(nutrition_value.cholesterol.percentage))
    //โปรตีน
    setProteinSymbol(nutrition_value.protein.symbol.id)
    setProteinAmount(isNumberNullOrNotInput(nutrition_value.protein.amount))
    //คาร์โบไฮเดรต
    setCarbohydrateSymbol(nutrition_value.carbohydrate.symbol.id)
    setCarbohydrateAmount(isNumberNullOrNotInput(nutrition_value.carbohydrate.amount))
    setCarbohydratePercentage(isNumberNullOrNotInput(nutrition_value.carbohydrate.percentage))
    //ใยอาหาร
    setDietaryFiberSymbol(nutrition_value.fiber.symbol.id)
    setDietaryFiberAmount(isNumberNullOrNotInput(nutrition_value.fiber.amount))
    setDietaryFiberPercentage(isNumberNullOrNotInput(nutrition_value.fiber.percentage))
    //น้ำตาล
    setSugarSymbol(nutrition_value.sugar.symbol.id)
    setSugarAmount(isNumberNullOrNotInput(nutrition_value.sugar.amount))
    //โซเดียม
    setSodiumAmount(isNumberNullOrNotInput(nutrition_value.sodium.amount))
    setSodiumPercentage(isNumberNullOrNotInput(nutrition_value.sodium.percentage))
    //Vitamin A
    setVitaminASymbol(nutrition_value.vit_a.symbol.id)
    setVitaminAPersentage(isNumberNullOrNotInput(nutrition_value.vit_a.percentage))
    //Vitamin B1
    setVitaminB1Symbol(nutrition_value.vit_b1.symbol.id)
    setVitaminB1Persentage(isNumberNullOrNotInput(nutrition_value.vit_b1.percentage))
    //Vitamin B2
    setVitaminB2Symbol(nutrition_value.vit_b2.symbol.id)
    setVitaminB2Persentage(isNumberNullOrNotInput(nutrition_value.vit_b2.percentage))
    //แคลเซียม
    setCalciumSymbol(nutrition_value.calcium.symbol.id)
    setCalciumPersentage(isNumberNullOrNotInput(nutrition_value.calcium.percentage))
    //เหล็ก
    setIronSymbol(nutrition_value.iron.symbol.id)
    setIronPersentage(isNumberNullOrNotInput(nutrition_value.iron.percentage))
    //Vitamin C
    setVitaminCSymbol(nutrition_value.vit_c.symbol.id)
    setVitaminCPersentage(isNumberNullOrNotInput(nutrition_value.vit_c.percentage))

    let nutOther = []
    for(let i = 0 ; i < nutrition_value.other.length; i++){
      let other = nutrition_value.other[i]
      nutOther.push({
        name : other.name, 
        symbol: other.symbol.id,  
        percentage : isNumberNullOrNotInput(other.value), 
      })
    }
    setOtherNutrientsList(nutOther)

    //ไขมันไม่อิ่มตัวเชิงเดี่ยว
    setMonounsaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.monounsaturated_fat.amount))
    setMonounsaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.monounsaturated_fat.percentage))
    //ไขมันไม่อิ่มตัวเชิงซ้อน
    setPolyunsaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.polyunsaturated_fat.amount))
    setPolyunsaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.polyunsaturated_fat.percentage))
    //ไขมันทรานส์
    setTransFatAmount(isNumberNullOrNotInput(nutrition_value.trans_fat.amount))
    setTransFatPercentage(isNumberNullOrNotInput(nutrition_value.trans_fat.percentage))


    const more_information = product.more_information
    //More information
    //ส่วนประกอบ
    setMoreInfoComponent(parseInt(more_information.component.id))
    //รายละเอียดส่วนประกอบ
    //น้ำตาลที่เติมเข้าไปในอาหาร
    setSugarAddedToFoodPercentage(isNumberNullOrNotInput(more_information.sugar_add_percentage))

    //**** ส่วนประกอบโดยประมาณ ****
    let componentOther = []
    for(let i = 0 ; i < more_information.components.length; i++){
      let other = more_information.components[i]
      componentOther.push({
        name : other.name, 
        symbol: other.symbol.id,  
        percentage : isNumberNullOrNotInput(other.value), 
      })
    }
    setApproximateComponentsList(componentOther)



    const food_aciditys = more_information.food_aciditys
    // ข้อมูลวัตถุเจือปน
    setAdditivesInformation(parseInt(more_information.allergy_of_label.id))
    // สี
    setColorTags(food_aciditys.colors)
    // สารกันเสีย หรือวัตถุกันเสีย
    setPreservativeTags(food_aciditys.preservatives)
    // วัตถุที่ให้ความหวานแทนน้ำตาล
    setSweetenersInsteadOfSugarTags(food_aciditys.sweeterners)
    // สารควบคุมความเป็นกรด
    setAcidityRegulatorTags(food_aciditys.acidity_regulators)
    // สารป้องกันการจับเป็นก้อน
    setAntiCakingAgentTags(food_aciditys.anticaking_agents)
    // สารป้องกันการเกิดฟอง
    setAntiFoamingAgentTags(food_aciditys.antifoaming_agents)
    // สารป้องกันการเกิดออกซิเดชั่น
    setAntiOxidationSubstanceTags(food_aciditys.antioxidants)
    // สารฟอกสี
    setBleachTags(food_aciditys.bleaching_agents)
    // สารเพิ่มปริมาณ
    setExcipientTags(food_aciditys.bulking_agents)
    // //สารให้ก๊าซคาร์บอนไดออกไซด์
    setCarbonDioxideTags(food_aciditys.carbonating_agents)
    // //สารช่วยทำละลาย หรือช่วยพา
    setSolventsTags(food_aciditys.carriers)
    // //สารคงสภาพของสี
    setColorStabilizerTags(food_aciditys.colour_retention_agents)
    // //อิมัลซิไฟเออร์
    setEmulsifierTags(food_aciditys.emulsifiers)
    // //เกลืออิมัลซิไฟอิ้งค์
    setEmulsifyingSaltTags(food_aciditys.emulsifying_salts)
    // //สารทำให้แน่น
    setFirmingAgentTags(food_aciditys.firming_agents)
    // //วัตถุปรุงแต่งรสอาหาร
    setFoodSeasoningTags(food_aciditys.flavour_enhancers)
    // //สารปรับปรุงคุณภาพแป้ง
    setFlourImproverTags(food_aciditys.flour_treatment_agents)
    // //สารทำให้เกิดฟอง
    setFoamingAgentTags(food_aciditys.foaming_agents)
    // //สารทำให้เกิดเจล
    setGellingAgentTags(food_aciditys.gelling_agents)
    // //สารเคลือบผิว
    setCoatingTags(food_aciditys.glazing_agents)
    // //สารทำให้เกิดความชุ่มชื้น
    setMoisturizingAgentTags(food_aciditys.humectants)
    // //ก๊าซที่ช่วยในการเก็บรักษาอาหาร
    setGasPreserveFoodTags(food_aciditys.packaging_gas)
    // //ก๊าซที่ใช้ขับดัน
    setPropellantGasTags(food_aciditys.propellants)
    // //สารช่วยให้ฟู
    setLeaveningAgentTags(food_aciditys.raising_agents)
    // //สารช่วยจับอนุมูลโลหะ
    setMetalScavengerTags(food_aciditys.sequestrants)
    // //สารทำให้คงตัว
    setStabilizerTags(food_aciditys.stabilizers)
    // //สารให้ความข้นเหนียว
    setThickenerTags(food_aciditys.thickners)
    // //อื่นๆ (สาร)
    setOtherSubstanceTags(food_aciditys.others)


    const allergens = more_information.allergens
    //ข้อมูลสำหรับผู้แพ้อาหาร
    setInformationForFoodAllergens(parseInt(more_information.allergy_name_label.id))
    //กลูเตน
    setGlutenTags(allergens.gluten)
    // //สัตว์น้ำที่มีเปลือกแข็ง
    setCrustaceanTags(allergens.crustaceans)
    // //ไข่ และผลิตภัณฑ์จากไข่
    setEggTags(allergens.eggs)
    // //ปลา และผลิตภัณฑ์จากปลา
    setFishTags(allergens.fish)
    // //ถั่วลิสง ถั่วเหลือง
    setSoybeansTags(allergens.peanuts)
    // //นม แลคโตส
    setMilkTags(allergens.milk)
    // //ถั่วที่มีเปลือกแข็ง
    setBeanTags(allergens.nuts)
    // //ซัลไฟต์
    setSulphiteTags(allergens.sulfites)
    // //อื่น ๆ
    setOtherLoseTags(allergens.others)


    //GDA
    setCodeGDA(parseInt(more_information.gda.id))
    //ข้อความแนะนำ
    setRecommend(parseInt(more_information.present_label.type.id))
    //ข้อความแนะนำ - บริโภคแต่น้อยและออกกำลังกายเพื่อสุขภาพ
    setRecommendExcercise(parseInt(more_information.present_label.present_choice_less_eat_and_exercise))
    //ข้อความแนะนำ - ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ
    setRecommend5Food(parseInt(more_information.present_label.present_choice_eat_5_group))
    //ข้อความแนะนำ - อื่น ๆ
    setRecommendOthers(more_information.present_label.other)

    //ข้อความกล่าวอ้าง
    setQuote(parseInt(more_information.quote.type.id))
      //ข้อความแนะนำ - อื่น ๆ
    setQuoteOthers(more_information.quote.other)
      

    const production = product.production
    //แหล่งผลิต
    //ผลิตจาก
    setMadeFrom(parseInt(production.foreign_country.id))
    //ผู้ผลิต
    setManufacturer(production.made_from)
    //ผู้นำเข้า
    setImporter(production.importer)
    //ผู้จัดจำหน่าย
    setDistributor(production.distribute)


    setImageTypeListAll(product.images)
  
  
  }
}

function gotoHistoryDetail( id ){

}

  const [ rotateImageViewDegree, setRotateImageViewDegree ] = useState(0)
  const [ imgControlValue, setImgControlValue ] = useState(
    {
      scale: 1,
      translation: { x: 0, y: 0 }
    }
  )
  const [ imagePreview, setImagePreview ] = useState("")
  const [ imageType, setImageType ] = useState(1)
  const [ imageTypeListAll, setImageTypeListAll ] = useState([])
  const [ imageTypeList, setImageTypeList ] = useState([])


  function getImageList(){

    let oldList = [...imageTypeListAll]
    let result = []
    for(let i = 0; i < oldList.length; i++){
      let img = oldList[i]
      if(parseInt(img.image_type.id) === imageType){
        result.push(img)
      }
  
    }
    //setImageTypeList(result)
    if(result.length > 0){
      //setImagePreview(result[0].path)
    }
    //console.log(result)
    return result
  }
  
  const refOverview = useRef(null)
  const refNutrient = useRef(null)
  const refMoreInfo = useRef(null)
  const refMade = useRef(null)
  const refHistory = useRef(null)


  
  const [ logId, setLogId ] = useState(0)
  const [ barcode, setBarcode ] = useState("")
  const [ productsId, setProductsId ] = useState("")

  const [ revisionProduct, setRevisionProduct ] = useState("")
  const [ revisionUpdate, setRevisionUpdate ] = useState("")
  const [ revisionCreateBy, setRevisionCreateBy ] = useState("")
    

  const [ productRevision, setProductRevision ] = useState(1)
  const [ productHistory, setProductHistory ] = useState([])


  const columns = [
    {
        name: 'วันที่แก้ไข',
        selector: row => row.update_at,
        wrap : true
    },
    {
        name: 'ผู้ที่แก้ไข',
        selector: row => row.create_by.username,
        wrap : true
    },
    {
        name: 'สถานะ',
        selector: row => row.editor_status.description
        ,
        wrap : true
    },
    {
      name: '',
      cell:(row)=> <button onClick={ () => gotoHistoryDetail( row.log_id) } className="py-2 px-3 my-1 rounded-md bg-blue-700 text-sm text-white">...</button>,
      wrap : true
    },
  ];
  
  
  const [ revisionList, setRevisionList ] = useState([])
  const [ groupFoodList, setGroupFoodList ] = useState([])
  const [ unitList, setUnitList ] = useState([ ])


  //Overview
  //รหัส อย.
  const [ productCodeFDA, setProductCodeFDA ] = useState("")
  const [ productBrand, setProductBrand ] = useState("")
  const [ productName, setProductName ] = useState("")
  const [ productGroup, setProductGroup ] = useState(0)
  const [ productPackingUnit, setProductPackingUnit ] = useState(0)
  const [ productNetWeight, setProductNetWeight ] = useState("")


  //Nutrition Information
  const [ nutritionInfoLabel, setNutritionInfoLabel ] = useState(-1)
  //หนึ่งหน่วยบริโภค
  const [ nutritionInfoOneServingAmount, setNutritionInfoOneServingAmount ] = useState("")
  const [ nutritionInfoOneServingUnit, setNutritionInfoOneServingUnit ] = useState(0)
  const [ nutritionInfoOneServingGram, setNutritionInfoOneServingGram ] = useState("")
  //จำนวนหน่วยบริโภคต่อ
  const [ nutritionInfoNumberOfServingsPer, setNutritionInfoNumberOfServingsPer ] = useState(-1)
  const [ nutritionInfoNumberOfServingsPerAmount, setNutritionInfoNumberOfServingsPerAmount ] = useState("")


  //คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค
  //พลังงานทั้งหมด
  const [ totalPower, setTotalPower ] = useState("")
  //พลังงานจากไขมัน
  const [ energyFromFat, setEnergyFromFat ] = useState("")
  //ไขมันทั้งหมด
  const [ totalFatAmount, setTotalFatAmount ] = useState("")
  const [ totalFatPercentage, setTotalFatPercentage ] = useState("")
  //ไขมันอิ่มตัว
  const [ saturatedFatAmount, setSaturatedFatAmount ] = useState("")
  const [ saturatedFatPercentage, setSaturatedFatPercentage ] = useState("")
  //โคเลสเตอรอล
  const [ cholesterolSymbol, setCholesterolSymbol ] = useState(1)
  const [ cholesterolAmount, setCholesterolAmount ] = useState("")
  const [ cholesterolPercentage, setCholesterolPercentage] = useState("")
  //โปรตีน
  const [ proteinSymbol, setProteinSymbol ] = useState(1)
  const [ proteinAmount, setProteinAmount ] = useState("")
  //คาร์โบไฮเดรต
  const [ carbohydrateSymbol, setCarbohydrateSymbol ] = useState(1)
  const [ carbohydrateAmount, setCarbohydrateAmount ] = useState("")
  const [ carbohydratePercentage, setCarbohydratePercentage] = useState("")
  //ใยอาหาร
  const [ dietaryFiberSymbol, setDietaryFiberSymbol ] = useState(1)
  const [ dietaryFiberAmount, setDietaryFiberAmount ] = useState("")
  const [ dietaryFiberPercentage, setDietaryFiberPercentage] = useState("")
  //น้ำตาล
  const [ sugarSymbol, setSugarSymbol ] = useState(1)
  const [ sugarAmount, setSugarAmount ] = useState("")
  //โซเดียม
  const [ sodiumAmount, setSodiumAmount ] = useState("")
  const [ sodiumPercentage, setSodiumPercentage] = useState("")
  //Vitamin A
  const [ vitaminASymbol, setVitaminASymbol ] = useState(1)
  const [ vitaminAPersentage, setVitaminAPersentage ] = useState("")
  //Vitamin B1
  const [ vitaminB1Symbol, setVitaminB1Symbol ] = useState(1)
  const [ vitaminB1Persentage, setVitaminB1Persentage ] = useState("")
  //Vitamin B2
  const [ vitaminB2Symbol, setVitaminB2Symbol ] = useState(1)
  const [ vitaminB2Persentage, setVitaminB2Persentage ] = useState("")
  //แคลเซียม
  const [ calciumSymbol, setCalciumSymbol ] = useState(1)
  const [ calciumPersentage, setCalciumPersentage ] = useState("") 
  //เหล็ก
  const [ ironSymbol, setIronSymbol ] = useState(1)
  const [ ironPersentage, setIronPersentage ] = useState("") 
  //Vitamin C
  const [ vitaminCSymbol, setVitaminCSymbol ] = useState(1)
  const [ vitaminCPersentage, setVitaminCPersentage ] = useState("")

  //**** สารอาหารอื่น ๆ ****
  const [ otherNutrientsAmount, setOtherNutrientsAmount ] = useState("")
  const [ otherNutrientsSymbol, setOtherNutrientsSymbol ] = useState(1)
  const [ otherNutrientsPercentage, setOtherNutrientsPercentage ] = useState("")
  const [ otherNutrientsList, setOtherNutrientsList ] = useState([]) // { amount, symbol, percentage }

  //ไขมันไม่อิ่มตัวเชิงเดี่ยว
  const [ monounsaturatedFatAmount, setMonounsaturatedFatAmount ] = useState("")
  const [ monounsaturatedFatPercentage, setMonounsaturatedFatPercentage] = useState("")
  //ไขมันไม่อิ่มตัวเชิงซ้อน
  const [ polyunsaturatedFatAmount, setPolyunsaturatedFatAmount ] = useState("")
  const [ polyunsaturatedFatPercentage, setPolyunsaturatedFatPercentage] = useState("")
  //ไขมันทรานส์
  const [ transFatAmount, setTransFatAmount ] = useState("")
  const [ transFatPercentage, setTransFatPercentage] = useState("")


  //More information
  //ส่วนประกอบ
  const [ moreInfoComponent, setMoreInfoComponent ] = useState(-1)
  //รายละเอียดส่วนประกอบ
  //น้ำตาลที่เติมเข้าไปในอาหาร
  const [ sugarAddedToFoodPercentage, setSugarAddedToFoodPercentage ] = useState("")

  //**** ส่วนประกอบโดยประมาณ ****
  const [ approximateComponentsAmount, setApproximateComponentsAmount ] = useState("")
  const [ approximateComponentsSymbol, setApproximateComponentsSymbol ] = useState(1)
  const [ approximateComponentsPercentage, setApproximateComponentsPercentage ] = useState("")
  const [ approximateComponentsList, setApproximateComponentsList ] = useState([]) // { amount, symbol, percentage }


  //ข้อมูลวัตถุเจือปน
  const [ additivesInformation, setAdditivesInformation ] = useState(-1)
  //สี
  const [ colorTag, setColorTag ] = useState("")
  const [ colorTags, setColorTags ] = useState([]) // { id, name }
  //สารกันเสีย หรือวัตถุกันเสีย
  const [ preservativeTag, setPreservativeTag ] = useState("")
  const [ preservativeTags, setPreservativeTags ] = useState([]) // { id, name }
  //วัตถุที่ให้ความหวานแทนน้ำตาล
  const [ sweetenersInsteadOfSugarTag, setSweetenersInsteadOfSugarTag ] = useState("")  
  const [ sweetenersInsteadOfSugarTags, setSweetenersInsteadOfSugarTags ] = useState([]) // { id, name }
  //สารควบคุมความเป็นกรด
  const [ acidityRegulatorTag, setAcidityRegulatorTag ] = useState("")  
  const [ acidityRegulatorTags, setAcidityRegulatorTags ] = useState([]) // { id, name }
  //สารป้องกันการจับเป็นก้อน
  const [ antiCakingAgentTag, setAntiCakingAgentTag ] = useState("")
  const [ antiCakingAgentTags, setAntiCakingAgentTags ] = useState([]) // { id, name }
  //สารป้องกันการเกิดฟอง
  const [ antiFoamingAgentTag, setAntiFoamingAgentTag ] = useState("")
  const [ antiFoamingAgentTags, setAntiFoamingAgentTags ] = useState([]) // { id, name }
  //สารป้องกันการเกิดออกซิเดชั่น
  const [ antiOxidationSubstanceTag, setAntiOxidationSubstanceTag ] = useState("")
  const [ antiOxidationSubstanceTags, setAntiOxidationSubstanceTags ] = useState([]) // { id, name }
  //สารฟอกสี
  const [ bleachTag, setBleachTag ] = useState("")
  const [ bleachTags, setBleachTags ] = useState([]) // { id, name }
  //สารเพิ่มปริมาณ
  const [ excipientTag, setExcipientTag ] = useState("")
  const [ excipientTags, setExcipientTags ] = useState([]) // { id, name }
  //สารให้ก๊าซคาร์บอนไดออกไซด์
  const [ carbonDioxideTag, setCarbonDioxideTag ] = useState("")
  const [ carbonDioxideTags, setCarbonDioxideTags ] = useState([]) // { id, name }
  //สารช่วยทำละลาย หรือช่วยพา
  const [ solventsTag, setSolventsTag ] = useState("")
  const [ solventsTags, setSolventsTags ] = useState([]) // { id, name }
  //สารคงสภาพของสี
  const [ colorStabilizerTag, setColorStabilizerTag ] = useState("")
  const [ colorStabilizerTags, setColorStabilizerTags ] = useState([]) // { id, name }
  //อิมัลซิไฟเออร์
  const [ emulsifierTag, setEmulsifierTag ] = useState("")
  const [ emulsifierTags, setEmulsifierTags ] = useState([]) // { id, name }
  //เกลืออิมัลซิไฟอิ้งค์
  const [ emulsifyingSaltTag, setEmulsifyingSaltTag ] = useState("")
  const [ emulsifyingSaltTags, setEmulsifyingSaltTags ] = useState([]) // { id, name }
  //สารทำให้แน่น
  const [ firmingAgentTag, setFirmingAgentTag ] = useState("")
  const [ firmingAgentTags, setFirmingAgentTags ] = useState([]) // { id, name }
  //วัตถุปรุงแต่งรสอาหาร
  const [ foodSeasoningTag, setFoodSeasoningTag ] = useState("")
  const [ foodSeasoningTags, setFoodSeasoningTags ] = useState([]) // { id, name }
  //สารปรับปรุงคุณภาพแป้ง
  const [ flourImproverTag, setFlourImproverTag ] = useState("")
  const [ flourImproverTags, setFlourImproverTags ] = useState([]) // { id, name }
  //สารทำให้เกิดฟอง
  const [ foamingAgentTag, setFoamingAgentTag ] = useState("")
  const [ foamingAgentTags, setFoamingAgentTags ] = useState([]) // { id, name }
  //สารทำให้เกิดเจล
  const [ gellingAgentTag, setGellingAgentTag ] = useState("")
  const [ gellingAgentTags, setGellingAgentTags ] = useState([]) // { id, name }
  //สารเคลือบผิว
  const [ coatingTag, setCoatingTag ] = useState("")
  const [ coatingTags, setCoatingTags ] = useState([]) // { id, name }
  //สารทำให้เกิดความชุ่มชื้น
  const [ moisturizingAgentTag, setMoisturizingAgentTag ] = useState("")
  const [ moisturizingAgentTags, setMoisturizingAgentTags ] = useState([]) // { id, name }
  //ก๊าซที่ช่วยในการเก็บรักษาอาหาร
  const [ gasPreserveFoodTag, setGasPreserveFoodTag ] = useState("")
  const [ gasPreserveFoodTags, setGasPreserveFoodTags ] = useState([]) // { id, name }
  //ก๊าซที่ใช้ขับดัน
  const [ propellantGasTag, setPropellantGasTag ] = useState("")
  const [ propellantGasTags, setPropellantGasTags ] = useState([]) // { id, name }
  //สารช่วยให้ฟู
  const [ leaveningAgentTag, setLeaveningAgentTag ] = useState("")
  const [ leaveningAgentTags, setLeaveningAgentTags ] = useState([]) // { id, name }
  //สารช่วยจับอนุมูลโลหะ
  const [ metalScavengerTag, setMetalScavengerTag ] = useState("")
  const [ metalScavengerTags, setMetalScavengerTags ] = useState([]) // { id, name }
  //สารทำให้คงตัว
  const [ stabilizerTag, setStabilizerTag ] = useState("") 
  const [ stabilizerTags, setStabilizerTags ] = useState([]) // { id, name }
  //สารให้ความข้นเหนียว
  const [ thickenerTag, setThickenerTag ] = useState("")
  const [ thickenerTags, setThickenerTags ] = useState([]) // { id, name }
  //อื่นๆ (สาร)
  const [ otherSubstancesTag, setOtherSubstanceTag ] = useState("")
  const [ otherSubstancesTags, setOtherSubstanceTags ] = useState([]) // { id, name }


  //ข้อมูลสำหรับผู้แพ้อาหาร
  const [ informationForFoodAllergens, setInformationForFoodAllergens ] = useState(-1)
  //กลูเตน
  const [ glutenTag, setGlutenTag ] = useState("")
  const [ glutenTags, setGlutenTags ] = useState([]) // { id, name }
  //สัตว์น้ำที่มีเปลือกแข็ง
  const [ crustaceanTag, setCrustaceanTag ] = useState("")
  const [ crustaceanTags, setCrustaceanTags ] = useState([]) // { id, name }
  //ไข่ และผลิตภัณฑ์จากไข่
  const [ eggTag, setEggTag ] = useState("")
  const [ eggTags, setEggTags ] = useState([]) // { id, name }
  //ปลา และผลิตภัณฑ์จากปลา
  const [ fishTag, setFishTag ] = useState("")
  const [ fishTags, setFishTags ] = useState([]) // { id, name }
  //ถั่วลิสง ถั่วเหลือง
  const [ soybeansTag, setSoybeansTag ] = useState("")
  const [ soybeansTags, setSoybeansTags ] = useState([]) // { id, name }
  //นม แลคโตส
  const [ milkTag, setMilkTag ] = useState("")
  const [ milkTags, setMilkTags ] = useState([]) // { id, name }
  //ถั่วที่มีเปลือกแข็ง
  const [ beanTag, setBeanTag ] = useState("")
  const [ beanTags, setBeanTags ] = useState([]) // { id, name }
  //ซัลไฟต์
  const [ sulphiteTag, setSulphiteTag ] = useState("")
  const [ sulphiteTags, setSulphiteTags ] = useState([]) // { id, name }
  //อื่น ๆ
  const [ otherLoseTag, setOtherLoseTag ] = useState("")
  const [ otherLoseTags, setOtherLoseTags ] = useState([]) // { id, name }



  //GDA
  const [ codeGDA, setCodeGDA ] = useState(-1)
  //ข้อความแนะนำ
  const [ recommend, setRecommend ] = useState(-1)
  //ข้อความแนะนำ - บริโภคแต่น้อยและออกกำลังกายเพื่อสุขภาพ
  const [ recommendExcercise, setRecommendExcercise ] = useState(-1)
  //ข้อความแนะนำ - ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ
  const [ recommend5Food, setRecommend5Food ] = useState(-1)
  //ข้อความแนะนำ - อื่น ๆ
  const [ recommendOther, setRecommendOther ] = useState("")
  const [ recommendOthers, setRecommendOthers ] = useState([]) // { name }
  
  //ข้อความกล่าวอ้าง
  const [ quote, setQuote ] = useState(-1)
    //ข้อความแนะนำ - อื่น ๆ
    const [ quoteOther, setQuoteOther ] = useState("")
    const [ quoteOthers, setQuoteOthers ] = useState([]) // { name }
    

  //แหล่งผลิต
  //ผลิตจาก
  const [ madeFrom, setMadeFrom ] = useState(-1)
  //ผู้ผลิต
  const [ manufacturer, setManufacturer ] = useState("")
  //ผู้นำเข้า
  const [ importer, setImporter ] = useState("")
  //ผู้จัดจำหน่าย
  const [ distributor, setDistributor ] = useState("")



  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function validateNumber( event ){
    return (!/[.0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }


  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function moveToSection( ref ){
    ref.current.scrollIntoView()  
  }

  function onChangeNumber( event ){
    if ( validateNumber( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }
  


  return (
      <div className="flex flex-wrap bg-white font-sarabun w-full justify-center " >


        <div class="w-full flex ">
          <Header/>
        </div>

        <div class="w-full flex my-4">

 

            { /* Detail */}
            <div className='w-4/6 h-screen mx-auto px-0 lg:px-6 py-12 overflow-auto bg-gray-100 '>
   
                <div className='w-full flex flex-wrap justify-end '>
                  <span className='w-full text-xl'>เลขที่ Barcode : {barcode} ปรับปรุงครั้งที่ {revisionProduct}</span>
                  <span className='w-full text-sm mt-2'>วันที่แก้ไข : {revisionUpdate}</span>
                  <span className='w-full text-sm mt-2'>แก้ไขโดย : {revisionCreateBy}</span>
                </div>


                <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='flex flex-wrap  '>
                      <button onClick={ (e)=>{moveToSection( refOverview )} } className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>ข้อมูลภาพรวม</button>
                    </div>    
                    <div className='flex flex-wrap'>
                      <button onClick={ (e)=>{moveToSection( refNutrient )} }className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>ข้อมูลโภชนาการ</button>
                    </div>    
                </div>

                <div ref={refOverview} className='flex flex-wrap w-full border-b-1 border-gray-200  py-3 px-3 lg:px-6 '>
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  ข้อมูลภาพรวม</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>รหัส อย.</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input disabled value={ productCodeFDA } onChange={ (e)=> { setProductCodeFDA(onChangeText(e)); } } type={"search"} className={" border w-full text-sm py-2 px-3 rounded-md "+((productCodeFDA === "")? " border-grey-500 " : " border-green-600  border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ตราสินค้า</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input disabled value={ productBrand } onChange={ (e)=> { setProductBrand(onChangeText(e)) } } type={"search"} className={" border w-full text-sm py-2 px-3 rounded-md  "+((productBrand === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ชื่อสินค้า</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input disabled value={ productName } onChange={ (e)=> { setProductName(onChangeText(e)) } } type={"search"}  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md   "+((productName === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>กลุ่มอาหาร</span>
                    </div>
                    <div className={"w-full  md:w-3/4 "+ ((productGroup === "")? "" : "border-green-600 border-2 rounded-lg p-1")}>
                      <AutoCompleteGroupText  is_disabled={true} sources={ groupFoodList } selectId={ productGroup } handlerChangeDropdown={setProductGroup} placeholder={"ค้นหากลุ่มอาหาร"}></AutoCompleteGroupText>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>หน่วยบรรจุภัณฑ์</span>
                    </div>
                    <div className={"w-full  md:w-3/4 "+ ((productPackingUnit === "")? "" : "border-green-600 border-2 rounded-lg p-1")}>
                      <AutoCompleteText is_disabled={true}  sources={ unitList } selectId={ productPackingUnit } handlerChangeDropdown={setProductPackingUnit} placeholder={"ค้นหาหน่วยบรรจุภัณฑ์"}></AutoCompleteText>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>น้ำหนักสุทธิ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input disabled value={ productNetWeight } onChange={ (e)=> { setProductNetWeight(onChangeNumber(e)) } }  type={"search"}  className={" border w-full text-sm py-2 px-3 rounded-md  "+((productNetWeight === "")? " border-grey-500 " : " border-green-600 border-2 ")} />

                    </div>
                  </div>


                </div>

                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>

                {/* Section#2 */}
                <div ref={refNutrient} className='flex flex-wrap w-full py-3 px-3 lg:px-6'>
              
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  ข้อมูลโภชนาการ</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3'>

                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ฉลากโภชนาการ</span>
                    </div>
                    <div className='w-full flex flex-wrap  text-sm md:w-3/4'>
                      <div className='w-full lg:w-1/4'>
                        <input disabled type={"radio"}  className="mr-2"   onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={0}  checked={ (nutritionInfoLabel === 0 )? ' checked ' : ''}  defaultChecked />ไม่แสดง
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'>
                        <input disabled type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={1}  checked={ (nutritionInfoLabel === 1 )? ' checked ' : ''} />แบบอื่น ๆ
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'    >
                        <input disabled type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={2}  checked={ (nutritionInfoLabel === 2 )? ' checked ' : ''} />แบบเต็ม
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'    >
                        <input disabled type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={3}  checked={ (nutritionInfoLabel === 3 )? ' checked ' : ''} />แบบย่อ
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>หนึ่งหน่วยบริโภค</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>
                
                      <div className='w-full lg:w-1/3 px-0 lg:px-3'>
                        <input disabled value={ nutritionInfoOneServingAmount } onChange={ (e)=> { setNutritionInfoOneServingAmount(onChangeNumber(e)) } } type="search" className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md    "+((nutritionInfoOneServingAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                      </div>
                      <div className={'w-full lg:w-1/3 px-0 lg:px-3 mt-2 lg:mt-0 '+ ((nutritionInfoOneServingUnit === "")? "" : "border-green-600 border-2 rounded-lg")}>
                        <AutoCompleteText  is_disabled={true} sources={ unitList } selectId={ nutritionInfoOneServingUnit } handlerChangeDropdown={setNutritionInfoOneServingUnit} placeholder={"ค้นหาหน่วยบรรจุภัณฑ์"}></AutoCompleteText>
                      </div>
                      <div className='w-full lg:w-1/3 px-0 lg:px-3 
                      flex  text-sm  mt-2 lg:mt-0'>
                        <div className='flex-none mt-2 pr-2'>(</div>
                        <div className='flex-grow '><input disabled value={ nutritionInfoOneServingGram } onChange={ (e)=> { setNutritionInfoOneServingGram(onChangeNumber(e)) } } className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((nutritionInfoOneServingGram === "")? " border-grey-500 " : " border-green-600 border-2 ")} /></div>
                        <div className='flex-none mt-2 pl-2'>กรัม )</div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>จำนวนหน่วยบริโภคต่อ</span>
                    </div>
                    <div className='w-full flex flex-wrap  md:w-3/4'>
                      <div className='w-full flex  text-sm md:w-3/4'>
                        <div className='w-full lg:w-1/4'>
                          <input disabled type={"radio"} className="mr-2" onChange={(e)=>{ setNutritionInfoNumberOfServingsPer( parseInt(e.currentTarget.value)) }} name="radio-nutrient-no-serving" value={0}  checked={ (nutritionInfoNumberOfServingsPer === 0 )? ' checked ' : ''} defaultChecked />-
                        </div>
                        <div className='w-full lg:w-1/4'>
                          <input disabled  type={"radio"} className="mr-2" onChange={(e)=>{ setNutritionInfoNumberOfServingsPer( parseInt(e.currentTarget.value)) }} name="radio-nutrient-no-serving" value={1}  checked={ (nutritionInfoNumberOfServingsPer === 1 )? ' checked ' : ''}  />ประมาณ
                        </div>
                      </div>
                      <div className='w-full mt-2'>
                        <input disabled type={"search"} value={ nutritionInfoNumberOfServingsPerAmount } onChange={ (e)=> { setNutritionInfoNumberOfServingsPerAmount(onChangeNumber(e)) } } className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((nutritionInfoNumberOfServingsPerAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-6'>
                    <div className='w-full'>
                      <span className='font-bold text-sm'>คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค</span>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm mr-1'>พลังงานทั้งหมด</span>
                    </div>
                    <div className='w-full flex md:w-3/4'>
                      <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input  disabled value={ totalPower } onChange={ (e)=> { setTotalPower(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-3 "+((totalPower === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300  text-sm px-4 py-3  rounded-r-md'>
                            <span className=' text-sm'>กิโลแคลลอรี</span>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4 pr-1'>
                      <span className='font-bold text-sm '>พลังงานจากไขมัน</span>
                    </div>
                    <div className='w-full flex md:w-3/4  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input  disabled value={ energyFromFat } onChange={ (e)=> { setEnergyFromFat(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-3 "+((energyFromFat === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none  text-sm bg-gray-300  px-4 py-3  rounded-r-md'>
                            <span>กิโลแคลลอรี</span>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันทั้งหมด</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>

                      <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input disabled value={ totalFatAmount } onChange={ (e)=> { setTotalFatAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((totalFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 lg:pl-2'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input disabled value={ totalFatPercentage } onChange={ (e)=> { setTotalFatPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((totalFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันอิ่มตัว</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>

                      <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input disabled value={ saturatedFatAmount } onChange={ (e)=> { setSaturatedFatAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((saturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 lg:pl-2'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input disabled value={ saturatedFatPercentage } onChange={ (e)=> { setSaturatedFatPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((saturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โคเลสเตอรอล</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select disabled  defaultValue={1}  value={cholesterolSymbol} onChange={(e)=> { setCholesterolSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ cholesterolAmount } onChange={ (e)=> { setCholesterolAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((cholesterolAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>มก.</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ cholesterolPercentage } onChange={ (e)=> { setCholesterolPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((cholesterolPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โปรตีน</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={proteinSymbol} onChange={(e)=> { setProteinSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ proteinAmount } onChange={ (e)=> { setProteinAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((proteinAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>กรัม</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>คาร์โบไฮเดรต</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select disabled defaultValue={1}  value={carbohydrateSymbol} onChange={(e)=> { setCarbohydrateSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ carbohydrateAmount } onChange={ (e)=> { setCarbohydrateAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((carbohydrateAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ carbohydratePercentage } onChange={ (e)=> { setCarbohydratePercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((carbohydratePercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ใยอาหาร</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select disabled defaultValue={1}  value={dietaryFiberSymbol} onChange={(e)=> { setDietaryFiberSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ dietaryFiberAmount } onChange={ (e)=> { setDietaryFiberAmount(onChangeNumber(e)) } } type="search"  className={"h-full w-full text-sm py-2 px-2 "+((dietaryFiberAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ dietaryFiberPercentage } onChange={ (e)=> { setDietaryFiberPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((dietaryFiberPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>น้ำตาล</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={sugarSymbol} onChange={(e)=> { setSugarSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled  value={ sugarAmount } onChange={ (e)=> { setSugarAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((sugarAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>กรัม</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โซเดียม</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>


                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ sodiumAmount } onChange={ (e)=> { setSodiumAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((sodiumAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>มก.</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ sodiumPercentage } onChange={ (e)=> { setSodiumPercentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((sodiumPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินเอ</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={vitaminASymbol} onChange={(e)=> { setVitaminASymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ vitaminAPersentage } onChange={ (e)=> { setVitaminAPersentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((vitaminAPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินบี 1</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={vitaminB1Symbol} onChange={(e)=> { setVitaminB1Symbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ vitaminB1Persentage } onChange={ (e)=> { setVitaminB1Persentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((vitaminB1Persentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินบี 2</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={vitaminB2Symbol} onChange={(e)=> { setVitaminB2Symbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ vitaminB2Persentage } onChange={ (e)=> { setVitaminB2Persentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((vitaminB2Persentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>แคลเซียม</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={calciumSymbol} onChange={(e)=> { setCalciumSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ calciumPersentage } onChange={ (e)=> { setCalciumPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((calciumPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>เหล็ก</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={ironSymbol} onChange={(e)=> { setIronSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled  value={ ironPersentage } onChange={ (e)=> { setIronPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((ironPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินซี</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select disabled defaultValue={1}  value={vitaminCSymbol} onChange={(e)=> { setVitaminCSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input disabled value={ vitaminCPersentage } onChange={ (e)=> { setVitaminCPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((vitaminCPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full'>
                      <span className='font-bold text-sm'>สารอาหารอื่น ๆ</span>
                    </div>
                    {

                      otherNutrientsList.map(function(item, index){
                        return (
                          <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/6 flex'>
                              </div>
                              <div className='w-full md:w-2/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                  <input value={item.name} type={"search"} className="h-full w-full text-sm py-2 px-3" disabled />
                                </div>
                              </div>
                              <div className='w-full md:w-1/2 mt-2 md:mt-0  flex pr-1'>
                                <div className='w-full flex text-sm rounded-md '>

                                  <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                                    <select value={item.symbol} className='bg-gray-300 mt-1' disabled>
                                      <option  value={1}>=</option>
                                      <option  value={2}>&#10094;</option>
                                    </select>
                                  </div>
                                  <div className='grow w-full border-grey-500 border '>
                                    <input value={item.percentage} type={"search"} className="h-full w-full text-sm py-2 px-2" disabled />
                                  </div>
                                  <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                                    <span>%</span>
                                  </div>
                                </div>
                              </div>
                          </div>
                        )
                      })

                    }
                  </div>

                  <div className='w-full flex flex-wrap mt-12  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันไม่อิ่มตัวเชิงเดี่ยว</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ monounsaturatedFatAmount } onChange={ (e)=> { setMonounsaturatedFatAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((monounsaturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ monounsaturatedFatPercentage } onChange={ (e)=> { setMonounsaturatedFatPercentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((monounsaturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันไม่อิ่มตัวเชิงซ้อน</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ polyunsaturatedFatAmount } onChange={ (e)=> { setPolyunsaturatedFatAmount(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((polyunsaturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ polyunsaturatedFatPercentage } onChange={ (e)=> { setPolyunsaturatedFatPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((polyunsaturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันทรานส์</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled value={ transFatAmount } onChange={ (e)=> { setTransFatAmount(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((transFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input disabled  value={ transFatPercentage } onChange={ (e)=> { setTransFatPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((transFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>


                </div>

                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>

                



            </div>

        </div>
      </div>
  )
}

export default ProductHistoryDetail;