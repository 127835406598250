import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Header from '../pages/Header.js'
import { formatDateThaiShort, formatMonthYearThaiShort } from '../utils/thai-date-time-format.ts'


import iconProduct from '../images/ic_product.svg';
import iconTrophy from '../images/ic_trophy.svg';
import iconDel from '../images/ic_delete_orange.svg';


function ChallengeMemberDetailPage() {
  const navigate = useNavigate();

  const { state } = useLocation()
  const [ challengeId, setChallengeId ] = useState('')
  const [ topic, setTopic ] = useState('')
  const [ startDate, setStartDate ] = useState('')
  const [ endDate, setEndDate ] = useState('')

  const [ leaderBoardMonth, setLeaderBoardMonth ] = useState(0)

  const [ leaderBoards, setLeaderBoards ] = useState([])

  const [ leaderBoardsTop1, setLeaderBoardsTop1 ] = useState(null)
  const [ leaderBoardsTop2, setLeaderBoardsTop2 ] = useState(null)
  const [ leaderBoardsTop3, setLeaderBoardsTop3 ] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    setChallengeId(state.challenge_id)
    callGetUserApi(state.challenge_id)
    callGetLeaderApi(state.challenge_id, leaderBoardMonth)
  }, [])



  function callGetUserApi( challenge_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: state.challenge_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallengeDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    const result = (data.data.result )
    if( result !== undefined && result !== null){
      setTopic(result.name)
      setStartDate(result.start_date)
      setEndDate(result.end_date)
    }
  
  }
}


  function callGetLeaderApi( challenge_id , is_month){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: challenge_id,
          is_month: is_month,
          is_all: 1
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/app/get_challenge_rank_by_person.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetLeaderApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }


  function validateGetLeaderApi(data ){
    setLeaderBoardsTop1(null)
    setLeaderBoardsTop2(null)
    setLeaderBoardsTop3(null)
    if( data.data !== undefined){
      const result = (data.data )
      if( result !== undefined && result !== null){

        let list = []
        for(let i = 0; i < (result.top).length; i++) {
          let r  = result.top[i]
          list.push(r)
          if(i === 0 ){
            setLeaderBoardsTop1(r)
          }
        }

        setLeaderBoards(list)
      }
    
    }
  }
  

  function getRanking(item)  {
    
    if (item.ranking === '1'){
      return <span className=' text-foodchoice-green-600  bg-foodchoice-green-400 px-4 py-1 rounded-xl '>อันดับที่ 1</span>
    }
    else if (item.ranking === '2'){
      return <span className=' text-foodchoice-yellow-600  bg-foodchoice-yellow-400 px-4 py-1 rounded-xl '>อันดับที่ 2</span>
    }
    else if (item.ranking === '3'){
      return      <span className=' text-foodchoice-red-600  bg-foodchoice-red-400 px-4 py-1 rounded-xl '>อันดับที่ 3</span>
    }

    return null

  }


  function deleteMember(item) {
    callDeleteMemberApi(item)
  }

  
  const gotoBack = (file) => {
    navigate("/challenge/main" , { replace: true })
  }


  function callDeleteMemberApi( item ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          user_id: item.user_id,
          challenge_id: challengeId,
          challenge_team_id: ''
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/deleteChallengeMemberInTeam.php', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteMemberApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateDeleteMemberApi(data ){
  callGetUserApi( challengeId)
}


const gotoNews = () => {
  navigate('/news/main')
}

  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12 py-[20px]">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button className='button-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button onClick={gotoNews}  className='button-non-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6'>
            <div className='w-fit flex'>
              <span className=' text-foodchoice-red-500 text-3xl font-bold'>Challenge</span>
            </div>
            <div className='w-fit flex pl-6 items-end'>
              <span onClick={gotoBack}  className=' text-black text-lg'>หน้าหลัก</span>
              <span onClick={gotoBack} className=' text-foodchoice-violet-500 text-lg ml-2'>&lt;&lt; กลับไปหน้าที่แล้ว</span>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6 '>

            <div className='w-full flex '>

              <div className=' flex-none flex flex-wrap'>

                <div className='w-full flex items-end'>
                  <span className=' text-black text-md'>รายชื่อผู้เข้าร่วมกิจกรรม</span>
                </div>
                <div className='w-full flex'>
                  <span className=' text-foodchoice-violet-500 text-2xl'>{ topic }</span>
                </div>
                <div className='w-full flex'>
                <span className=' text-foodchoice-gray-500 text-md'>ระยะเวลา</span>
                  <span className=' text-black text-md ml-3'>{ formatDateThaiShort(startDate) } - { formatDateThaiShort(endDate)  }</span>
                </div>

              </div>

              <div className=' grow w-full flex flex-wrap'>
                <div className=' w-[200px] flex '>
                      <div className=' flex-none w-fit flex my-auto '>
                        <img
                          src={iconProduct}
                          className="w-10 h-10"
                          alt=""
                          />
                      </div>
                      <div className=' grow w-full  flex-wrap ml-4 my-auto'>
                        <div className=' w-full flex flex-wrap'>

                          <span className=' w-full text-black text-2xl font-bold'>{ leaderBoards.length }</span>
                          <span className=' w-full  text-foodchoice-gray-500 text-md'>สมาชิก</span>
                        </div>
                      </div>
                </div>
                <div className=' w-[200px] flex '>
                      <div className=' flex-none w-fit flex my-auto '>
                        <img
                          src={iconTrophy}
                          className="w-10 h-10"
                          alt=""
                          />
                      </div>
                      <div className=' grow w-full  flex-wrap ml-4 my-auto'>
                        <div className=' w-full flex flex-wrap'>

                          <span className=' w-full text-black text-2xl font-bold'>{ ( leaderBoardsTop1 !== null ? leaderBoardsTop1.total : 0) }</span>
                          <span className=' w-full  text-foodchoice-gray-500 text-md'>คะแนนสูงสุด</span>
                        </div>
                      </div>
                </div>
                {/* <div className=' w-[200px] flex '>
                      <div className=' flex-none w-fit flex my-auto '>
                        <img
                          src={iconTrophy}
                          className="w-10 h-10"
                          alt=""
                          />
                      </div>
                      <div className=' grow w-full  flex-wrap ml-4 my-auto'>
                        <div className=' w-full flex flex-wrap'>

                          <span className=' w-full text-black text-2xl font-bold'>900</span>
                          <span className=' w-full  text-foodchoice-gray-500 text-md'>คะแนนต่ำสุด</span>
                        </div>
                      </div>
                </div> */}
              </div>

            </div>

            <div className='w-full flex flex-wrap bg-foodchoice-blue-400 p-6 mt-2'>
              <div className='w-full flex flex-wrap mb-2'>
                <div className='w-1/12 flex flex-wrap py-2 pl-2'>
                  <span className=' text-foodchoice-gray-500'>ลำดับที่</span>
                </div>
                <div className='w-2/12 flex flex-wrap items-center py-2'>
                  <span className=' text-foodchoice-gray-500'>ชื่อสมาชิก</span>
                </div>
                <div className='w-2/12 flex flex-wrap  py-2'>
                  <span className=' text-foodchoice-gray-500'>อีเมล</span>
                </div>
                <div className='w-2/12 flex flex-wrap py-2'>
                  <span className=' text-foodchoice-gray-500'>เบอร์โทรศัพท์</span>
                </div>
                <div className='w-1/12 flex flex-wrap  py-2'>
                  <span className=' text-foodchoice-gray-500'>คะแนน</span>
                </div>
                <div className='w-2/12 flex flex-wrap  py-2'>
                
                </div>
                <div className='w-2/12 flex flex-wrap'>
        
                </div>
              </div>
              {
                leaderBoards.map(function(item, index) {
                  return (

                    <div className='w-full flex flex-wrap mb-4'>
                    <div className='w-1/12 flex flex-wrap bg-white py-2 pl-2'>
                      <span className=' flex text-foodchoice-violet-600  bg-foodchoice-gray-400 px-2 rounded-full'>{ (index+1) }</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap items-center bg-white py-2'>
                      <span className=' flex h-4 w-4 bg-foodchoice-violet-600 rounded-full ring-2 ring-foodchoice-violet-300 '></span>
                      <span className=' flex text-foodchoice-violet-600 ml-2'>{ item.display_name }</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap bg-white py-2'>
                      <span className=' text-foodchoice-gray-500'>{ item.email }</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap bg-white  py-2'>
                      <span className=' text-foodchoice-gray-500'>{ item.tel }</span>
                    </div>
                    <div className='w-1/12 flex flex-wrap bg-white  py-2'>
                      <span className=' text-black'>{ item.total }</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap bg-white  py-2'>
                          { getRanking(item) }
                    </div>
                    <div className='w-2/12 flex flex-wrap'>
                    <div className='w-fit cursor-pointer  flex bg-white border border-foodchoice-orange-500 ml-4 py-2 px-4'>
                          <div className='w-fit flex my-auto '>
                            <img
                              src={iconDel}
                              className="w-4 h-4"
                              alt=""
                              />
                          </div>
                          <div className='w-fit ml-4'>
                            <span className=' text-black'>ลบ</span>
                          </div>
                        </div>
                    </div>
                    </div>
                  )
                })
              }
            </div>  
          </div>  
        </div>



      </div>
  )
}

export default ChallengeMemberDetailPage;