import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



import DataTable from 'react-data-table-component';

function ProblemProductList(props) {


  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)
  const [ isResetTable, setIsResetTable ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ word, setWord ] = useState("")

  const [ menuId, setMenuId ] = useState(props.menu)

  useEffect(() => {
    window.scrollTo(0, 0)
    setMenuId(props.menu)
    handlerCallGetProductApiByMenu( props.menu, 1, 10 )
  }, [ props.menu ])
  

  

  const handlePageChange = page => {
		setPage(page);
    if(word.length >= 3){
      handlerCallSearchProductApiByMenu(menuId, word, page, perPage )
    }
    else if( word.length === 0 ){
      handlerCallGetProductApiByMenu( menuId, page, perPage )
    }
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    if(word.length >= 3){
      handlerCallSearchProductApiByMenu(menuId, word, page, newPerPage )
    }
    else if( word.length === 0 ){
      handlerCallGetProductApiByMenu( menuId, page, newPerPage )
    }

	};


  const columns = [
    {
      name: 'Barcode',
      selector: row => (row.barcode !== null)? row.barcode : "-",
      wrap : true
    },
    {
        name: 'ชื่อ',
        selector: row => row.overview.name,
        wrap : true
    },
    {
        name: 'ปัญหา',
        selector: row => row.problem_info.problem.description,
        wrap : true
    },
    {
      name: '',
      cell:(row)=> <button onClick={ () => gotoDetail( row.barcode) } className="py-2 px-3 my-1 rounded-md bg-blue-700 text-sm text-white">...</button>,
      wrap : true
    },
  ];

  function gotoDetail( barcode ){
    navigate("/problemproduct/detail", {
      state : {
        barcode : barcode
      }
    })
  }



  function callGetProductsApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          page: page,
          per_page: per_page
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProblemProducts.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }



  function callGetProductsShowApi( page, per_page, is_show ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          page: page,
          per_page: per_page,
          is_show: is_show
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductsByShow.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetProductApi(data ){
    if( data.data.result !== undefined){
      setItems(data.data.result )
      setTotalRowAll(data.data.total)
    }
  }




function callSearchProductsApi( word, page, perPage ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        word: word,
        page: page,
        per_page: perPage
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/getProblemProducts.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetProductApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}



function callSearchProductsShowApi( word, page, perPage, is_show ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        word: word,
        page: page,
        per_page: perPage,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/searchProblemProducts.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetProductApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}



function handleKeyPress(event){
  if(event.key === 'Enter'){
    let word = event.target.value
    setWord(word)
    submit( menuId )
  }
}



function submit( menuId ){
    if(word.length >= 3){
      setIsResetTable(true)
      handlerCallSearchProductApiByMenu(menuId, word, page, perPage )
    }
    else if( word.length === 0 ){
      setIsResetTable(true)
      handlerCallGetProductApiByMenu( menuId, page, perPage )
    }
    else{
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุตั้งแต่ 3 ตัวอักษร",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
  }

  function handlerCallGetProductApiByMenu( menu_id , page, perPage){
      callGetProductsApi( page, perPage)
  }

  

  function handlerCallSearchProductApiByMenu( menu_id , word, page, perPage){
    callSearchProductsShowApi(word, page, perPage, 1)
  }

  


  function getMenuTitle(){
    if( menuId === 1){
      return "จำนวนทั้งหมดในระบบ"
    }
    else if( menuId === 2){
      return "ข้อมูลที่แสดงผล"
    }
    else if( menuId === 3){
      return "ข้อมูลที่รายงานภาพเข้ามาใหม่"
    }
    else if( menuId === 4){
      return "ข้อมูลที่ลบแล้ว"
    }
  }

  return (
    <div className="flex flex-wrap w-full">
      <div className=" w-full  flex-wrap bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4">
          
          <div className='flex-wrap mb-2'>
            <div className='w-full flex'>
              <span className=' w-full text-lg md:text-2xl font-bold  '>รายงานผลิตภัณฑ์ที่พบปัญหา</span>
            </div>
          </div>
          

          <div className='flex w-full mb-2 mt-4'>

              <div className='grow w-full '>
                <div className='flex'>
                  <span className=' w-full text-xs md:text-md  '>จำนวนผลลัพธ์</span>
                </div>
                <div className='flex'>
                  <span className=' text-sm md: text-md bg-gray-200 py-2 px-3 md:py-4 md:px-6 rounded-lg font-bold text-center'>{totalRowAll} รายการ</span>
                </div>
              </div>
              
              <div className="flex-none  justify-end  flex  my-auto mx-2  ">
                <input value={word} onChange={ (e)=>{ setWord(e.target.value) }} onKeyPress={(e)=>{handleKeyPress(e)}} type={"text"} className="w-[220px] py-3 px-6 border border-gray-300 rounded-md text-sm" placeholder='ค้นหา Barcode' />
                <button onClick={ ()=> { submit( menuId ) } }  className='ml-3 bg-green-600 px-4 py-2 rounded-lg text-white'>ค้นหา</button>
              </div>

    
            

          </div>

          <div className=" w-full flex flex-wrap   ">
            <DataTable
              columns={columns}
              data={ items }
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
            />
          </div>
      </div>
    </div>
  );

}

export default ProblemProductList;