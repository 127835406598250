import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import DataTable from 'react-data-table-component';
import Header from '../pages/Header.js'

function UserHistoryPage() {
  const { state } = useLocation()
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const [ memberId, setMemberId ] = useState(0)
  const [ username, setUsername ] = useState("")
  
  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)
  const [ items, setItems ] = useState([])


  const columns = [

    {
      name: 'Barcode',
      selector: row => row.overview.barcode_id,
      wrap : true
    },
    {
        name: 'ชื่อผลิตภัณฑ์',
        selector: row => row.overview.name,
        wrap : true
    },
    {
        name: 'ยี่ห้อ',
        selector: row => row.overview.brand,
        wrap : true
    },
    {
        name: 'วันและเวลาที่แก้ไข',
        selector: row => row.update_at,
        wrap : true
    },
    {
        name: 'สถานะ',
        selector: row => row.editor_status.description,
        wrap : true
    },

  ];


  
  useEffect(() => {
    window.scrollTo(0, 0)
    setMemberId(state.id)
    setUsername(state.username)
    callGetProductsHistoryApi(state.id ,  1, 10 )
  }, [])

  const handlePageChange = page => {
		setPage(page);
    callGetProductsHistoryApi(  memberId,  page, perPage )
    
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    callGetProductsHistoryApi(  memberId,  page, newPerPage )
    

	};



  function callGetProductsHistoryApi( memberId, page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          page: page,
          per_page: per_page,
          create_by : memberId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductRevisionByCreate.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetProductApi(data ){
    if( data.data.result !== undefined){
      setItems(data.data.result )
      setTotalRowAll(data.data.total)
    }
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

        <div className=" w-full flex flex-wrap  bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4 ">
          
          <div className='flex-wrap mb-2'>
            <div className='w-full flex'>
              <span className=' w-full text-lg md:text-2xl font-bold  '>ประวัติการแก้ไขของ {username}</span>
            </div>
          </div>


          <div className='flex flex-wrap w-full mb-2 mt-4'>

            <div className='w-full mt-4'>
            
            

              <DataTable
                columns={columns}
                data={ items }
                pagination
                paginationServer
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                paginationTotalRows={totalRowAll}
              />
            </div>

          </div>
        </div>
        </div>
      </div>
  )
}

export default UserHistoryPage;