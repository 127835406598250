import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'


function ChallengeEditPage() {
  
  const navigate = useNavigate();
  const { state } = useLocation()
  const MySwal = withReactContent(Swal)

  const [ cover, setCover ] = useState(null)
  const [ oldCover, setOldCover ] = useState("")
  
  const [ challengeId, setChallengeId ] = useState("")
  const [ topic, setTopic ] = useState("")
  const [ owner, setOwner ] = useState("")
  const [ startDate, setStartDate ] = useState("")
  const [ endDate, setEndDate ] = useState("")
  const [ description, setDescription ] = useState("")
  const [ prize, setPrize ] = useState("")
  const [ isShow, setIsShow ] = useState("")
  

  const [ isRequireRegister, setIsRequireRegister ] = useState(0)
  const [ rankingType, setRankingType ] = useState(0)
  const [ isClosed, setIsClosed ] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchDetail(state.token)
    setChallengeId(state.token)
  }, [])

  function gotoHome( id ){
    navigate("/challenge")
  }


  function fetchDetail( message_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "challenge_id": message_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallengeDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
      }
    });
  
  }

  function validateGetUserApi(data) {
 
    if(data.data){
      let result = data.data.result 
      setOldCover(result.cover)
      setTopic(result.name)
      setOwner(result.owner)
      setStartDate(result.start_date)
      setEndDate(result.end_date)
      setDescription(result.description)
      setPrize(result.prize)
      setIsShow(result.is_show)
      setIsRequireRegister(parseInt(result.is_require_register))
      setRankingType(parseInt(result.is_monthly_ranking))
      setIsClosed(parseInt(result.is_closed))
    }
  }

  

  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }


  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }




  function submit(){

    if( topic === "" ||  owner === "" || startDate === "" || endDate === ""  ) {
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else {
      callInsertMessagerApi()
    }

    

  }


  function callInsertMessagerApi(  ){

    const formData = new FormData();
    if( cover !== null ){
      formData.append("cover", cover)
    }
    formData.append("challenge_id", challengeId)
    formData.append("name", topic)
    formData.append("owner", owner)
    formData.append("description", description)
    formData.append("prize", prize)
    formData.append("start_date", startDate)
    formData.append("end_date", endDate)
    formData.append("is_require_register", isRequireRegister)
    formData.append("is_monthly_ranking", rankingType)
    formData.append("is_closed", isClosed)
    
    const requestOptions = {
      method: 'POST',
      body: formData
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateChallenge.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertUserApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

function validateInsertUserApi(data ){
  if( data.code === 200){
    MySwal.fire({
      icon: 'warining',
      title: <p>บันทึก</p>,
      text: "บันทึกสำเร็จ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
    gotoHome()
  }
  else {
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: data.message,
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}


function showConfirmDelete(){
  callUpdateShowApi(0)
}

function showConfirmShow(){
  callUpdateShowApi(1)
}


function callUpdateShowApi( is_show ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        "challenge_id": challengeId,
        "is_show": is_show,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/updateChallengeShow.php', requestOptions)
  .then(response => response.json())
  .then(data => validateUpdateShowApi(data), (error) => {
    if (error) {
      // handle error here
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: error,
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
  });

}

function validateUpdateShowApi( data ){
  fetchDetail(challengeId)
}


  return (
      <div className="antialiased h-full min-h-screen  flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

          <div className=" w-full flex flex-wrap   ">
            <div className=" w-full flex flex-wrap m-4  justify-center">
            
              
                  <div className='w-full md:w-[600px] max-w-[1440px] bg-white flex flex-wrap mt-3 text-sm  py-8 px-4 rounded-lg'>
                    <div className='w-full flex my-4 justify-center'>
                      <span className='font-bold text-xl'>แก้ไขกิจกรรม</span>
                    </div>

                    <div className='w-full flex flex-wrap justify-end '>
                        {
                          ( isShow === '1' )?
                            <button onClick={showConfirmDelete} className=' px-4 bg-red-600 rounded-lg text-white my-2 py-2 '>นำออก</button>
                          :
                            <button onClick={showConfirmShow} className=' px-4 bg-green-600 rounded-lg text-white my-2 py-2 '>แสดงกิจกรรม</button>
                        }
                    
                      </div>

                    <div className='w-full flex'>
                      <span className='font-bold'>ภาพ*</span>
                    </div>
                    <div className='w-full flex'>
                      <img src={oldCover} className='w-[150px] h-[150px]' />
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setCover(e.target.files[0]) }}  type="file" accept='image/png, image/jpg' className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>
                    
                    <div className='w-full flex  mt-4'>
                      <span className='font-bold'>ชื่อรายการ*</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setTopic(onChangeText(e)) }} value={ topic }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>เจ้าของกิจกรรม*</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setOwner(onChangeText(e)) }} value={ owner }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>วันเริ่มกิจกรรม *</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setStartDate(e.target.value) }} value={ startDate } type="date" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>วันสิ้นสุดกิจกรรม *</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setEndDate((e.target.value)) }} value={ endDate } type="date" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>รายละเอียด</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <textarea onChange={(e)=>{ setDescription(onChangeText(e)) }} value={ description }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>กติกา และของรางวัล</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <textarea onChange={(e)=>{ setPrize(onChangeText(e)) }} value={ prize }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>ต้องสมัครก่อนเข้าร่วม</span>
                    </div>
                    <div className='w-full flex flex-wrap mt-3'>
                      <div className='w-full flex flex-wrap '>
                        <div className='w-full flex '>
                          <div className='w-fit'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-register" 
                            value={0}  
                            checked={isRequireRegister === 0}
                            onChange={(e)=>{ setIsRequireRegister( parseInt(e.currentTarget.value)) }} 
                            defaultChecked />ต้องสมัคร
                          </div>
                          <div className='w-fit pl-4'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-register" 
                            value={1} 
                            checked={isRequireRegister === 1}
                            onChange={(e)=>{ setIsRequireRegister( parseInt(e.currentTarget.value)) }} 
                            />ไม่จำเป็น
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>การจัดอันดับคะแนน</span>
                    </div>
                    <div className='w-full flex flex-wrap mt-3'>
                      <div className='w-full flex flex-wrap '>
                        <div className='w-full flex '>
                          <div className='w-fit'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-ranking" 
                            value={0}  
                            checked={rankingType === 0}
                            onChange={(e)=>{ setRankingType( parseInt(e.currentTarget.value)) }} 
                            defaultChecked />จัดลำดับตลอดระยะเวลา
                          </div>
                          <div className='w-fit pl-4'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-ranking" 
                            value={1}
                            checked={rankingType === 1}
                            onChange={(e)=>{ setRankingType( parseInt(e.currentTarget.value)) }} 
                            />รายเดือน
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>สถานะของกิจกรรม</span>
                    </div>
                    <div className='w-full flex flex-wrap mt-3'>
                      <div className='w-full flex flex-wrap '>
                        <div className='w-full flex '>
                          <div className='w-fit'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-opened" 
                            value={0}  
                            checked={isClosed === 0}
                            onChange={(e)=>{ setIsClosed( parseInt(e.currentTarget.value)) }} 
                            defaultChecked />เปิด
                          </div>
                          <div className='w-fit pl-4'>
                            <input type={"radio"} className="mr-2"  
                            name="radio-opened" 
                            value={1}
                            checked={isClosed === 1}
                            onChange={(e)=>{ setIsClosed( parseInt(e.currentTarget.value)) }} 
                            />ปิด
                          </div>
                        </div>
                      </div>
                    </div>



                    <button  onClick={submit} className='w-full bg-green-600 rounded-lg text-white my-8 py-2 '>บันทึก</button>
                

                  </div>

            </div>
          </div>
        </div>

      </div>
  )
}

export default ChallengeEditPage;