import React, { useEffect, useState } from 'react'

import iconArrowUp from '../images/ic_dropdown_up.svg';
import iconArrowDown from '../images/ic_dropdown_down.svg';

function AutoCompleteText(props) {

  const [dropdownSources, setDropdownSources] = useState(props.sources)
  const [dropdownPlaceholder] = useState(props.placeholder)

  const [ selectedId, setSelectedId ] = useState(props.selectId)
  const [ isDisabled, setIsDisabled ] = useState(props.is_disabled)
  const [ selectedDesc, setSelectedDesc ] = useState('')

  const [ display, setDisplay ] = useState(props.selectId)

  useEffect(() => {
    setDropdownSources(props.sources)
    for(let i = 0; i < dropdownSources.length; i++){
      if(parseInt(dropdownSources[i].id) === parseInt(props.selectId)){
        setSelectedId(parseInt(dropdownSources[i].id))
        setSelectedDesc(dropdownSources[i].description)
        break;
      }
    }
 

  })



  let handlerChangeDropdown = props.handlerChangeDropdown

  const [show, setShow ] = useState(false)
  const [search, setSearch ] = useState('')
  const [searchParams ] = useState(["description"])
  



  function getStateArrow() {
    if(show){
      return iconArrowUp
    }
    return iconArrowDown
  }

  function searchItem(){
    let c =  dropdownSources.filter((item) => {
      return searchParams.some((newItem) => { 
          return item[newItem]
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) > -1
        ;
      })
    });

    return c

    
  }

  const onSelectedItem = ( item ) => {

    
    setSelectedId(item.id)
    setSelectedDesc(item.description)
    setShow(false)

    setDisplay(item.description)
    setSearch('')
    handlerChangeDropdown(item.id)
  }

  return (
      <div className="w-full flex flex-wrap bg-white text-sm">

        <div className="w-full flex ">
          <div className="grow  border rounded-l-md ">
            <input 
              type="text" 
              value={selectedDesc}
              disabled={true}
              className="flex w-full  px-3  py-2 "
            />
          </div> 
          <div className="grow-0 bg-gray-300 pl-1 pr-2 py-3 rounded-r-md"
            onClick={
            () => {
              if(!isDisabled){
                setShow((show ? false : true ));
                if(show){
                  setSearch('')
                }
              }
            }}
          >
          <img
            src={getStateArrow()}
            className="w-3 h-3"
            alt=""
            />
          </div>  
        </div>  


        <div className={'w-full flex flex-wrap p-3 border mt-2 rounded-md '+( show? ' block ' : ' hidden ') }>
          <div className="w-full flex flex-wrap">
            <input 
            type="text" 
            value={search}
            placeholder={dropdownPlaceholder}
            onChange={(event) => setSearch(event.target.value)}
            className="flex w-full  p-3 border rounded-md"
            />
          </div>
          <div className="w-full flex flex-wrap  h-48 overflow-y-auto pt-3 mt-3">
            
            {
              searchItem().map(function(item, index){
                return (
                  <div 
                  key={item.id.toString()}
                  onClick={() => onSelectedItem(item) }
                  className="w-full flex flex-wrap h-10 py-1 px-1 hover:bg-gray-100 ">
                    <span className="ml-2  text-sm text-black flex my-auto">{item.description}</span>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>     
  )
  
}

export default AutoCompleteText;