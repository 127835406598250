import React, { useEffect, useState } from 'react'

import iconArrowUp from '../images/ic_dropdown_up.svg';
import iconArrowDown from '../images/ic_dropdown_down.svg';

function AutoCompleteGroupText(props) {

  const [dropdownSources, setDropdownSources] = useState(props.sources)
  const [dropdownPlaceholder] = useState(props.placeholder)

  const [ selectedId, setSelectedId ] = useState(props.selectId)
  const [ isDisabled, setIsDisabled ] = useState(props.is_disabled)
  const [ selectedDesc, setSelectedDesc ] = useState('')

  const [ display, setDisplay ] = useState(props.selectId)

  useEffect(() => {
    setDropdownSources(props.sources)

    for(let i = 0; i < dropdownSources.length; i++){
      for(let j = 0; j < (dropdownSources[i].group_foods).length; j++){
        
        if(parseInt(dropdownSources[i].group_foods[j].id )=== parseInt(props.selectId)){
          setSelectedId(parseInt(dropdownSources[i].group_foods[j].id))
          setSelectedDesc(dropdownSources[i].group_foods[j].description)
          break;
        }
      }
    }
 

  })



  let handlerChangeDropdown = props.handlerChangeDropdown

  const [show, setShow ] = useState(false)
  const [search, setSearch ] = useState('')
  const [searchParams ] = useState(["description"])
  



  function getStateArrow() {
    if(show){
      return iconArrowUp
    }
    return iconArrowDown
  }

  function searchItem(){
    let oldList = [...dropdownSources]
    let newList = []
    newList.push({
      id: 0,
      description: "",
      group_foods: [{
        id: 0,
        description: "ไม่ระบุ"
      }]
    })
    for(let i = 0; i < oldList.length; i++ ){
      let foodGroup = oldList[i]
      let group_foods = foodGroup.group_foods

      let newSubList = []
      for(let m = 0; m < (group_foods).length; m++ ){
        let group_food = group_foods[m]
        if( (search === "") || (group_food.description
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) > -1)){
          newSubList.push({
            "id": group_food.id,
            "description": group_food.description
          })
        }
      }
      if(newSubList.length > 0 && foodGroup.id !== 0){
        newList.push({
          id: foodGroup.id,
          description: foodGroup.description,
          group_foods: [...newSubList]
        })
      }

      newSubList.length = 0
      newSubList = []
    
    }
    return newList

    
  }

  const onSelectedItem = ( item ) => {

  
    
    setSelectedId(item.id)
    setSelectedDesc(item.description)
    setShow(false)

    setDisplay(item.description)
    setSearch('')
    handlerChangeDropdown(item.id)
  }

  return (
      <div className="w-full flex flex-wrap bg-white text-sm">

        <div className="w-full flex ">
          <div className="grow  border rounded-l-md ">
            <input 
              type="text" 
              value={selectedDesc}
              disabled={true}
              className="flex w-full  px-3  py-2 "
            />
          </div> 
          <div className="grow-0 bg-gray-300 pl-1 pr-2 py-3 rounded-r-md"
            onClick={
            () => {
              if(!isDisabled){
                setShow((show ? false : true ));
                if(show){
                  setSearch('')
                }
              }
            }}
          >
          <img
            src={getStateArrow()}
            className="w-3 h-3"
            alt=""
            />
          </div>  
        </div>  


        <div className={'w-full flex flex-wrap p-3 border mt-2 rounded-md '+( show? ' block ' : ' hidden ') }>
          <div className="w-full flex flex-wrap">
            <input 
            type="text" 
            value={search}
            disabled={ (isDisabled)?  "disabled" : "" }
            placeholder={dropdownPlaceholder}
            onChange={(event) => setSearch(event.target.value)}
            className="flex w-full  p-3 border rounded-md"
            />
          </div>
          <div className="w-full flex flex-wrap  h-48 overflow-y-auto pt-3 mt-3">
            
            {
              searchItem().map(function(item, index){

                return (
                  <div className='w-full border-t'>
                    {
                      (item.description !== "")?
                        <div 
                          key={item.id.toString()}
                          className="w-full flex flex-wrap h-10 py-1 px-1  ">
                          <span className="ml-2 font-bold  text-sm text-black flex my-auto">{item.description}</span>
                        </div>
                      :
                        null
                    }
                    {

                      (item.group_foods).map(function(item_sub, index_sub){

                          return (
                            <div 
                            key={item_sub.id.toString()}

                            onClick={() => onSelectedItem(item_sub) }
                            className="ml-3 w-full flex flex-wrap h-10 py-1 px-1 hover:bg-gray-100 ">
                              <span className="ml-2  text-sm text-black flex my-auto">{item_sub.description}</span>
                            </div>
                          )
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>     
  )
  
}

export default AutoCompleteGroupText;