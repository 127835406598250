import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'


function MessageEditPage() {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)

  const [ token, setToken ] = useState("")

  const [ topic, setTopic ] = useState("")
  const [ message, setMessage ] = useState("")
  const [ isShow, setIsShow ] = useState("")
  


  useEffect(() => {
    window.scrollTo(0, 0)
    setToken(state.token)
    fetchDetail(state.token)
  }, [])


  function fetchDetail( message_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "message_id": message_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getMessageDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
      }
    });
  
  }

  function validateGetUserApi(data) {
 
    if(data.data){
      let result = data.data.result 
      setTopic(result.topic)
      setMessage(result.content)
      setIsShow(result.is_show)
    }
  }



  function gotoHome( id ){
    navigate("/message")
  }

  

  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }


  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function submitInfo(){

    if(   topic === "" || message === "" ) {
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else {
      callUpdateUserApi()
    }

    

  }

  function showConfirmDelete(){
    callUpdateShowApi(0)
  }

  function showConfirmShow(){
    callUpdateShowApi(1)
  }


  function callUpdateShowApi( is_show ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "message_id": token,
          "is_show": is_show,
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateMessageShow.php', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateShowApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

  function validateUpdateShowApi( data ){
    fetchDetail(token)
  }

  function callUpdateUserApi(  ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "message_id": token,
          "topic": topic,
          "content": message,
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateMessage.php', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateUserApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

function validateUpdateUserApi(data ){
  if( data.code === 200){
    MySwal.fire({
      icon: 'warining',
      title: <p>บันทึก</p>,
      text: "บันทึกสำเร็จ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
    gotoHome()
  }
  else {
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: data.message,
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}


  return (
      <div className="antialiased h-full min-h-screen  flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

          <div className=" w-full flex flex-wrap   ">
            <div className=" w-full  flex m-4  justify-center">
            
              <div className='w-fit flex-wrap'>
                <div className='w-full md:w-[600px] max-w-[1440px] bg-white flex flex-wrap mt-3 text-sm  py-8 px-4 rounded-lg'>
                      <div className='w-full flex my-4 justify-center'>
                        <span className='font-bold text-xl'>แก้ไขข้อความ</span>
                      </div>
                      <div className='w-full flex flex-wrap justify-end '>
                        {
                          ( isShow === '1' )?
                            <button onClick={showConfirmDelete} className=' px-4 bg-red-600 rounded-lg text-white my-2 py-2 '>นำออก</button>
                          :
                            <button onClick={showConfirmShow} className=' px-4 bg-green-600 rounded-lg text-white my-2 py-2 '>แสดงข้อความ</button>
                        }
                    
                      </div>
                      
                      <div className='w-full flex'>
                        <span className='font-bold'>หัวข้อ*</span>
                      </div>
                      <div className='w-full flex mt-2'>
                        <input onChange={(e)=>{ setTopic(onChangeText(e)) }} value={ topic }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      </div>

                      <div className='w-full flex mt-4'>
                        <span className='font-bold'>รายละเอียด*</span>
                      </div>
                      <div className='w-full flex mt-2'>
                        <input onChange={(e)=>{ setMessage(onChangeText(e)) }} value={ message } type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      </div>

                      <button  onClick={submitInfo} className='w-full bg-green-600 rounded-lg text-white my-8 py-2 '>บันทึก</button>
                  

                </div>



              </div>


            </div>
          </div>
        </div>

      </div>
  )
}

export default MessageEditPage;