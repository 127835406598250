import React, { useState, useEffect, useRef } from 'react';
import  { useNavigate, useLocation,  } from 'react-router-dom'

import Header from '../pages/Header.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import { MapInteractionCSS } from 'react-map-interaction';

import AutoCompleteGroupText from '../components/AutoCompleteGroupText';
import AutoCompleteText from '../components/AutoCompleteText';

import DataTable from 'react-data-table-component';


function EditProductPage(props) {


  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)

  function gotoHome(){
    navigate("/", { replace : true })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setBarcode(state.barcode)
    callGetPackageUnitApi()
  }, [])

  function isNumberNullOrNotInput( data ){
    if( !data){
      return "-" //ไม่มี
    }
    else if( data === '-1'){
      return "" //ไม่ได้กรอก
    }
    return parseFloat(data)
  }

  function callGetPackageUnitApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getPackageUnits.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetPackageUnitApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetPackageUnitApi( data ){
    if( data.data.result !== undefined) {
      const packages = data.data.result
      setUnitList(packages)

      callGetGroupFoodApi()
    }
  }


  function callGetGroupFoodApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getGroupFoods.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetGroupFoodApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetGroupFoodApi( data ){
    if( data.data.result !== undefined) {
      const groups = data.data.result
      setGroupFoodList(groups)

      callGetProductsApi(state.barcode, 1)

    }
  }



  function callGetProductRevisionApi( barcode ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          barcode : barcode
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getRevisionProductByBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductRevisionApi(barcode, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetProductRevisionApi( barcode, data ){
    if( data.data.result !== undefined){
      let result = data.data.result
      setRevisionList( result )
      if(result.length > 0){
        let defaultRevision = result[0].revision_of_product
        let defaultProductId = result[0].products_id
        if(productRevision === ""){
          // setProductRevision(defaultRevision)
          // callGetProductsApi( barcode, defaultRevision )
          // callGetProductHistoryApi(defaultProductId, defaultRevision)
        }
      }
    }
  }


  function callGetProductHistoryApi( productsId, productRevision ){
    if(productsId === ""){
      return
    }
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "product_id" : productsId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductHistoryByProductId.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductHistoryApi(productsId, data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

  function validateGetProductHistoryApi(productsId, data ){
    if( data.data.result !== undefined){
      let result = data.data.result
      setProductHistory( result )
      
    }


  }


  function callInsertProductsApi( json ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        json
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/insertProduct.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertProductApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'error',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  }

  function validateInsertProductApi( data ){

    if( data.data !== undefined){
        let result = data.data
  
        if(
          result.result_nutrient === true && 
          result.result_nutrient_main === true && 
          result.result_nutrient_second === true 
          ){
            MySwal.fire({
              icon: 'success',
              title: <p>บันทึก</p>,
              text: "บันทึกข้อมูลเรียบร้อยแล้ว",
              confirmButtonText: '<span class="flex w-full">ตกลง</span>',
              confirmButtonColor: '#009640',
            })

            callCalculateFoodChoiceApi(productsId)
  
            callGetProductRevisionApi( barcode )
        }
        else{
          MySwal.fire({
            icon: 'error',
            title: <p>ขออภัย</p>,
            text: "กรุณาลองใหม่อีกครั้ง",
            confirmButtonText: '<span class="flex w-full">ตกลง</span>',
            confirmButtonColor: '#009640',
          })
        }
    }
  }


  function callUpdateProductsApi( json ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        json
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateProduct.php', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateProductApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'error',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

  function validateUpdateProductApi( data ){

    if( data.data !== undefined){
        let result = data.data

        if(result.result_overview === true && 
          result.result_nutrient === true && 
          result.result_nutrient_main === true && 
          result.result_nutrient_second === true 
          ){
            MySwal.fire({
              icon: 'success',
              title: <p>บันทึก</p>,
              text: "บันทึกข้อมูลเรียบร้อยแล้ว",
              confirmButtonText: '<span class="flex w-full">ตกลง</span>',
              confirmButtonColor: '#009640',
            })
            callCalculateFoodChoiceApi(productsId)

            callGetProductHistoryApi( productsId, productRevision )
        }
        else{
          MySwal.fire({
            icon: 'error',
            title: <p>ขออภัย</p>,
            text: "กรุณาลองใหม่อีกครั้ง",
            confirmButtonText: '<span class="flex w-full">ตกลง</span>',
            confirmButtonColor: '#009640',
          })
        }
 
    }
  }


  function callDeleteProductsApi( is_show  ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        {
          token : localStorage.getItem("foodchoice_token"),
          "barcode": barcode,
          "products_id": productsId,
          "is_show": is_show
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateProductShow.php', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteProductApi(is_show, data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'error',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

  function validateDeleteProductApi( is_show, data ){

    if( data.data !== undefined){
      
          if(is_show === 1){

            MySwal.fire({
              icon: 'success',
              title: <p>แสดงผลิตภัณฑ์</p>,
              text: "แสดงผลิตภัณฑ์เรียบร้อยแล้ว",
              confirmButtonText: '<span class="flex w-full">ตกลง</span>',
              confirmButtonColor: '#009640',
            })
          }
          else{

            MySwal.fire({
              icon: 'success',
              title: <p>นำผลิตภัณฑ์ออก</p>,
              text: "นำผลิตภัณฑ์ออกเรียบร้อยแล้ว",
              confirmButtonText: '<span class="flex w-full">ตกลง</span>',
              confirmButtonColor: '#009640',
            })
          }
          callGetProductsApi( barcode, productRevision)
        }
        else{
          MySwal.fire({
            icon: 'error',
            title: <p>ขออภัย</p>,
            text: "กรุณาลองใหม่อีกครั้ง",
            confirmButtonText: '<span class="flex w-full">ตกลง</span>',
            confirmButtonColor: '#009640',
          })
        }
    
  }


  function callGetProductsApi( barcode , product_revision){

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          barcode: barcode,
          revision_of_product : product_revision
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductByBarcodeAndRevision.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      
      }
    });
  
}

function validateGetProductApi(data ){
  if( data.data.result !== undefined){
    const product = data.data.result

    //
    setProductsId(product.products_id)
    setProductShow((product.is_show === "0")? 0 : 1)

    const overview = product.overview
    setProductCodeFDA(overview.fda_no) 
    setProductBrand(overview.brand) 
    setProductName(overview.name) 
    setProductGroup(parseInt(overview.group.id)) 
    setProductPackingUnit(parseInt(overview.package_unit.id)) 
    setProductNetWeight(isNumberNullOrNotInput(overview.total_weight)) 



    const nutrition_information = product.nutrition_information
    //Nutrition Information
    setNutritionInfoLabel(parseInt(nutrition_information.type_of_label.id))
    //หนึ่งหน่วยบริโภค
    setNutritionInfoOneServingAmount(isNumberNullOrNotInput(nutrition_information.per_one_portion.amount))
    setNutritionInfoOneServingUnit(parseInt(nutrition_information.per_one_portion.package_unit.id))
    setNutritionInfoOneServingGram(isNumberNullOrNotInput(nutrition_information.per_one_portion.gram))
    //จำนวนหน่วยบริโภคต่อ
    setNutritionInfoNumberOfServingsPer(parseInt(nutrition_information.count_portion.type.id))
    setNutritionInfoNumberOfServingsPerAmount(isNumberNullOrNotInput(nutrition_information.count_portion.amount))

    const nutrition_value = product.nutrition_value
    //คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค
    //พลังงานทั้งหมด
    setTotalPower(isNumberNullOrNotInput(nutrition_value.cal.amount))
    //พลังงานจากไขมัน
    setEnergyFromFat(isNumberNullOrNotInput(nutrition_value.calfat.amount))
    //ไขมันทั้งหมด
    setTotalFatAmount(isNumberNullOrNotInput(nutrition_value.fat.amount))
    setTotalFatPercentage(isNumberNullOrNotInput(nutrition_value.fat.percentage))
    //ไขมันอิ่มตัว
    setSaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.saturated_fat.amount))
    setSaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.saturated_fat.percentage))
    //โคเลสเตอรอล
    setCholesterolSymbol(nutrition_value.cholesterol.symbol.id)
    setCholesterolAmount(isNumberNullOrNotInput(nutrition_value.cholesterol.amount))
    setCholesterolPercentage(isNumberNullOrNotInput(nutrition_value.cholesterol.percentage))
    //โปรตีน
    setProteinSymbol(nutrition_value.protein.symbol.id)
    setProteinAmount(isNumberNullOrNotInput(nutrition_value.protein.amount))
    //คาร์โบไฮเดรต
    setCarbohydrateSymbol(nutrition_value.carbohydrate.symbol.id)
    setCarbohydrateAmount(isNumberNullOrNotInput(nutrition_value.carbohydrate.amount))
    setCarbohydratePercentage(isNumberNullOrNotInput(nutrition_value.carbohydrate.percentage))
    //ใยอาหาร
    setDietaryFiberSymbol(nutrition_value.fiber.symbol.id)
    setDietaryFiberAmount(isNumberNullOrNotInput(nutrition_value.fiber.amount))
    setDietaryFiberPercentage(isNumberNullOrNotInput(nutrition_value.fiber.percentage))
    //น้ำตาล
    setSugarSymbol(nutrition_value.sugar.symbol.id)
    setSugarAmount(isNumberNullOrNotInput(nutrition_value.sugar.amount))
    //โซเดียม
    setSodiumAmount(isNumberNullOrNotInput(nutrition_value.sodium.amount))
    setSodiumPercentage(isNumberNullOrNotInput(nutrition_value.sodium.percentage))
    //Vitamin A
    setVitaminASymbol(nutrition_value.vit_a.symbol.id)
    setVitaminAPersentage(isNumberNullOrNotInput(nutrition_value.vit_a.percentage))
    //Vitamin B1
    setVitaminB1Symbol(nutrition_value.vit_b1.symbol.id)
    setVitaminB1Persentage(isNumberNullOrNotInput(nutrition_value.vit_b1.percentage))
    //Vitamin B2
    setVitaminB2Symbol(nutrition_value.vit_b2.symbol.id)
    setVitaminB2Persentage(isNumberNullOrNotInput(nutrition_value.vit_b2.percentage))
    //แคลเซียม
    setCalciumSymbol(nutrition_value.calcium.symbol.id)
    setCalciumPersentage(isNumberNullOrNotInput(nutrition_value.calcium.percentage))
    //เหล็ก
    setIronSymbol(nutrition_value.iron.symbol.id)
    setIronPersentage(isNumberNullOrNotInput(nutrition_value.iron.percentage))
    //Vitamin C
    setVitaminCSymbol(nutrition_value.vit_c.symbol.id)
    setVitaminCPersentage(isNumberNullOrNotInput(nutrition_value.vit_c.percentage))

    let nutOther = []
    for(let i = 0 ; i < nutrition_value.other.length; i++){
      let other = nutrition_value.other[i]
      nutOther.push({
        name : other.name, 
        symbol: other.symbol.id,  
        percentage : isNumberNullOrNotInput(other.value), 
      })
    }
    setOtherNutrientsList(nutOther)

    //ไขมันไม่อิ่มตัวเชิงเดี่ยว
    setMonounsaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.monounsaturated_fat.amount))
    setMonounsaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.monounsaturated_fat.percentage))
    //ไขมันไม่อิ่มตัวเชิงซ้อน
    setPolyunsaturatedFatAmount(isNumberNullOrNotInput(nutrition_value.polyunsaturated_fat.amount))
    setPolyunsaturatedFatPercentage(isNumberNullOrNotInput(nutrition_value.polyunsaturated_fat.percentage))
    //ไขมันทรานส์
    setTransFatAmount(isNumberNullOrNotInput(nutrition_value.trans_fat.amount))
    setTransFatPercentage(isNumberNullOrNotInput(nutrition_value.trans_fat.percentage))


    const more_information = product.more_information
    //More information
    //ส่วนประกอบ
    setMoreInfoComponent(parseInt(more_information.component.id))
    //รายละเอียดส่วนประกอบ
    //น้ำตาลที่เติมเข้าไปในอาหาร
    setSugarAddedToFoodPercentage(isNumberNullOrNotInput(more_information.sugar_add_percentage))

    //**** ส่วนประกอบโดยประมาณ ****
    let componentOther = []
    for(let i = 0 ; i < more_information.components.length; i++){
      let other = more_information.components[i]
      componentOther.push({
        name : other.component.description, 
        symbol: other.symbol.id,  
        percentage : isNumberNullOrNotInput(other.value), 
      })
    }
    setApproximateComponentsList(componentOther)



    const food_aciditys = more_information.food_aciditys
    // ข้อมูลวัตถุเจือปน
    setAdditivesInformation(parseInt(more_information.allergy_of_label.id))
    // สี
    setColorTags(food_aciditys.colors)
    // สารกันเสีย หรือวัตถุกันเสีย
    setPreservativeTags(food_aciditys.preservatives)
    // วัตถุที่ให้ความหวานแทนน้ำตาล
    setSweetenersInsteadOfSugarTags(food_aciditys.sweeterners)
    // สารควบคุมความเป็นกรด
    setAcidityRegulatorTags(food_aciditys.acidity_regulators)
    // สารป้องกันการจับเป็นก้อน
    setAntiCakingAgentTags(food_aciditys.anticaking_agents)
    // สารป้องกันการเกิดฟอง
    setAntiFoamingAgentTags(food_aciditys.antifoaming_agents)
    // สารป้องกันการเกิดออกซิเดชั่น
    setAntiOxidationSubstanceTags(food_aciditys.antioxidants)
    // สารฟอกสี
    setBleachTags(food_aciditys.bleaching_agents)
    // สารเพิ่มปริมาณ
    setExcipientTags(food_aciditys.bulking_agents)
    // //สารให้ก๊าซคาร์บอนไดออกไซด์
    setCarbonDioxideTags(food_aciditys.carbonating_agents)
    // //สารช่วยทำละลาย หรือช่วยพา
    setSolventsTags(food_aciditys.carriers)
    // //สารคงสภาพของสี
    setColorStabilizerTags(food_aciditys.colour_retention_agents)
    // //อิมัลซิไฟเออร์
    setEmulsifierTags(food_aciditys.emulsifiers)
    // //เกลืออิมัลซิไฟอิ้งค์
    setEmulsifyingSaltTags(food_aciditys.emulsifying_salts)
    // //สารทำให้แน่น
    setFirmingAgentTags(food_aciditys.firming_agents)
    // //วัตถุปรุงแต่งรสอาหาร
    setFoodSeasoningTags(food_aciditys.flavour_enhancers)
    // //สารปรับปรุงคุณภาพแป้ง
    setFlourImproverTags(food_aciditys.flour_treatment_agents)
    // //สารทำให้เกิดฟอง
    setFoamingAgentTags(food_aciditys.foaming_agents)
    // //สารทำให้เกิดเจล
    setGellingAgentTags(food_aciditys.gelling_agents)
    // //สารเคลือบผิว
    setCoatingTags(food_aciditys.glazing_agents)
    // //สารทำให้เกิดความชุ่มชื้น
    setMoisturizingAgentTags(food_aciditys.humectants)
    // //ก๊าซที่ช่วยในการเก็บรักษาอาหาร
    setGasPreserveFoodTags(food_aciditys.packaging_gas)
    // //ก๊าซที่ใช้ขับดัน
    setPropellantGasTags(food_aciditys.propellants)
    // //สารช่วยให้ฟู
    setLeaveningAgentTags(food_aciditys.raising_agents)
    // //สารช่วยจับอนุมูลโลหะ
    setMetalScavengerTags(food_aciditys.sequestrants)
    // //สารทำให้คงตัว
    setStabilizerTags(food_aciditys.stabilizers)
    // //สารให้ความข้นเหนียว
    setThickenerTags(food_aciditys.thickners)
    // //อื่นๆ (สาร)
    setOtherSubstanceTags(food_aciditys.others)


    const allergens = more_information.allergens
    //ข้อมูลสำหรับผู้แพ้อาหาร
    setInformationForFoodAllergens(parseInt(more_information.allergy_name_label.id))
    //กลูเตน
    setGlutenTags(allergens.gluten)
    // //สัตว์น้ำที่มีเปลือกแข็ง
    setCrustaceanTags(allergens.crustaceans)
    // //ไข่ และผลิตภัณฑ์จากไข่
    setEggTags(allergens.eggs)
    // //ปลา และผลิตภัณฑ์จากปลา
    setFishTags(allergens.fish)
    // //ถั่วลิสง ถั่วเหลือง
    setSoybeansTags(allergens.peanuts)
    // //นม แลคโตส
    setMilkTags(allergens.milk)
    // //ถั่วที่มีเปลือกแข็ง
    setBeanTags(allergens.nuts)
    // //ซัลไฟต์
    setSulphiteTags(allergens.sulfites)
    // //อื่น ๆ
    setOtherLoseTags(allergens.others)


    //GDA
    setCodeGDA(parseInt(more_information.gda.id))
    //ข้อความแนะนำ
    setRecommend(parseInt(more_information.present_label.type.id))
    //ข้อความแนะนำ - บริโภคแต่น้อยและออกกำลังกายเพื่อสุขภาพ
    setRecommendExcercise(parseInt(more_information.present_label.present_choice_less_eat_and_exercise))
    //ข้อความแนะนำ - ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ
    setRecommend5Food(parseInt(more_information.present_label.present_choice_eat_5_group))
    //ข้อความแนะนำ - อื่น ๆ
    setRecommendOthers(more_information.present_label.other)

    //ข้อความกล่าวอ้าง
    setQuote(parseInt(more_information.quote.type.id))
      //ข้อความแนะนำ - อื่น ๆ
    setQuoteOthers(more_information.quote.other)
      

    const production = product.production
    //แหล่งผลิต
    //ผลิตจาก
    setMadeFrom(parseInt(production.foreign_country.id))
    //ผู้ผลิต
    setManufacturer(production.made_from)
    //ผู้นำเข้า
    setImporter(production.importer)
    //ผู้จัดจำหน่าย
    setDistributor(production.distribute)


    setImageTypeListAll(product.images)
  
  
    //Call revision 
    callGetProductRevisionApi(product.overview.barcode_id)

    //Call history

    callGetProductHistoryApi(product.products_id, product.revision_of_product)


    callGetFoodChoiceApi(product.products_id)
  }
}


function callGetFoodChoiceApi(productId){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        product_id: productId,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/app/productById.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetFoodChoiceApi(data), (error) => {
    if (error) {
      // handle error here
    
    }
  });

}

function validateGetFoodChoiceApi( data ){
  if( data.data.result !== undefined){
    setFoodchoiceData(data.data.result)

  }
}


function callCalculateFoodChoiceApi(productId){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        products_id: productId,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/calculateScoreDepartmentOfHealth.php', requestOptions)
  .then(response => response.json())
  .then(data => validateCalculateFoodChoiceApi(productId, data), (error) => {
    if (error) {
      // handle error here
    
    }
  });

}

function validateCalculateFoodChoiceApi(productId, data ){
  if( data.data.result !== undefined){
    


  }
  callGetFoodChoiceApi(productId)
}

function gotoHistoryDetail( id, create_by ,update_at ){
  navigate("/detail/history", 
  { 
    state : {
      barcode : barcode,
      revision_of_product : productRevision,
      log_id : id,
      update_at : update_at,
      create_by : create_by
    }
  })
}

  const [ rotateImageViewDegree, setRotateImageViewDegree ] = useState(0)
  const [ imgControlValue, setImgControlValue ] = useState(
    {
      scale: 1,
      translation: { x: 0, y: 0 }
    }
  )
  const [ imagePreview, setImagePreview ] = useState("")
  const [ imageType, setImageType ] = useState(1)
  const [ imageTypeListAll, setImageTypeListAll ] = useState([])
  const [ imageTypeList, setImageTypeList ] = useState([])


  function getImageList(){

    let oldList = [...imageTypeListAll]
    let result = []
    for(let i = 0; i < oldList.length; i++){
      let img = oldList[i]
      if(parseInt(img.image_type.id) === imageType){
        result.push(img)
      }
  
    }
    //setImageTypeList(result)
    if(result.length > 0){
      //setImagePreview(result[0].path)
    }
    //console.log(result)
    return result
  }
  
  const refOverview = useRef(null)
  const refNutrient = useRef(null)
  const refMoreInfo = useRef(null)
  const refMade = useRef(null)
  const refHistory = useRef(null)

  const [ foodchoiceData, setFoodchoiceData ] = useState(null)

  const [ barcode, setBarcode ] = useState("")
  const [ productsId, setProductsId ] = useState("")

  const [ productRevision, setProductRevision ] = useState(1)
  const [ productHistory, setProductHistory ] = useState([])


  const columns = [
    {
        name: 'วันที่แก้ไข',
        selector: row => row.update_at,
        wrap : true
    },
    {
        name: 'ผู้ที่แก้ไข',
        selector: row => row.create_by.username,
        wrap : true
    },
    {
        name: 'สถานะ',
        selector: row => row.editor_status.description
        ,
        wrap : true
    },
    {
      name: '',
      cell:(row)=> ( row.editor_status.id === "3" ||  row.editor_status.id === "4" )? null : <button onClick={ () => gotoHistoryDetail( row.log_id, row.create_by.username, row.update_at) } className="py-2 px-3 my-1 rounded-md bg-blue-700 text-sm text-white">...</button>,
      wrap : true
    },
  ];
  
  
  const [ revisionList, setRevisionList ] = useState([])
  const [ groupFoodList, setGroupFoodList ] = useState([])
  const [ unitList, setUnitList ] = useState([ ])

  

  const [ productShow, setProductShow ] = useState(1)

  //Overview
  //รหัส อย.
  const [ productCodeFDA, setProductCodeFDA ] = useState("")
  const [ productBrand, setProductBrand ] = useState("")
  const [ productName, setProductName ] = useState("")
  const [ productGroup, setProductGroup ] = useState(0)
  const [ productPackingUnit, setProductPackingUnit ] = useState(0)
  const [ productNetWeight, setProductNetWeight ] = useState("")


  //Nutrition Information
  const [ nutritionInfoLabel, setNutritionInfoLabel ] = useState(-1)
  //หนึ่งหน่วยบริโภค
  const [ nutritionInfoOneServingAmount, setNutritionInfoOneServingAmount ] = useState("")
  const [ nutritionInfoOneServingUnit, setNutritionInfoOneServingUnit ] = useState(0)
  const [ nutritionInfoOneServingGram, setNutritionInfoOneServingGram ] = useState("")
  //จำนวนหน่วยบริโภคต่อ
  const [ nutritionInfoNumberOfServingsPer, setNutritionInfoNumberOfServingsPer ] = useState(-1)
  const [ nutritionInfoNumberOfServingsPerAmount, setNutritionInfoNumberOfServingsPerAmount ] = useState("")


  //คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค
  //พลังงานทั้งหมด
  const [ totalPower, setTotalPower ] = useState("")
  //พลังงานจากไขมัน
  const [ energyFromFat, setEnergyFromFat ] = useState("")
  //ไขมันทั้งหมด
  const [ totalFatAmount, setTotalFatAmount ] = useState("")
  const [ totalFatPercentage, setTotalFatPercentage ] = useState("")
  //ไขมันอิ่มตัว
  const [ saturatedFatAmount, setSaturatedFatAmount ] = useState("")
  const [ saturatedFatPercentage, setSaturatedFatPercentage ] = useState("")
  //โคเลสเตอรอล
  const [ cholesterolSymbol, setCholesterolSymbol ] = useState(1)
  const [ cholesterolAmount, setCholesterolAmount ] = useState("")
  const [ cholesterolPercentage, setCholesterolPercentage] = useState("")
  //โปรตีน
  const [ proteinSymbol, setProteinSymbol ] = useState(1)
  const [ proteinAmount, setProteinAmount ] = useState("")
  //คาร์โบไฮเดรต
  const [ carbohydrateSymbol, setCarbohydrateSymbol ] = useState(1)
  const [ carbohydrateAmount, setCarbohydrateAmount ] = useState("")
  const [ carbohydratePercentage, setCarbohydratePercentage] = useState("")
  //ใยอาหาร
  const [ dietaryFiberSymbol, setDietaryFiberSymbol ] = useState(1)
  const [ dietaryFiberAmount, setDietaryFiberAmount ] = useState("")
  const [ dietaryFiberPercentage, setDietaryFiberPercentage] = useState("")
  //น้ำตาล
  const [ sugarSymbol, setSugarSymbol ] = useState(1)
  const [ sugarAmount, setSugarAmount ] = useState("")
  //โซเดียม
  const [ sodiumAmount, setSodiumAmount ] = useState("")
  const [ sodiumPercentage, setSodiumPercentage] = useState("")
  //Vitamin A
  const [ vitaminASymbol, setVitaminASymbol ] = useState(1)
  const [ vitaminAPersentage, setVitaminAPersentage ] = useState("")
  //Vitamin B1
  const [ vitaminB1Symbol, setVitaminB1Symbol ] = useState(1)
  const [ vitaminB1Persentage, setVitaminB1Persentage ] = useState("")
  //Vitamin B2
  const [ vitaminB2Symbol, setVitaminB2Symbol ] = useState(1)
  const [ vitaminB2Persentage, setVitaminB2Persentage ] = useState("")
  //แคลเซียม
  const [ calciumSymbol, setCalciumSymbol ] = useState(1)
  const [ calciumPersentage, setCalciumPersentage ] = useState("") 
  //เหล็ก
  const [ ironSymbol, setIronSymbol ] = useState(1)
  const [ ironPersentage, setIronPersentage ] = useState("") 
  //Vitamin C
  const [ vitaminCSymbol, setVitaminCSymbol ] = useState(1)
  const [ vitaminCPersentage, setVitaminCPersentage ] = useState("")

  //**** สารอาหารอื่น ๆ ****
  const [ otherNutrientsAmount, setOtherNutrientsAmount ] = useState("")
  const [ otherNutrientsSymbol, setOtherNutrientsSymbol ] = useState(1)
  const [ otherNutrientsPercentage, setOtherNutrientsPercentage ] = useState("")
  const [ otherNutrientsList, setOtherNutrientsList ] = useState([]) // { amount, symbol, percentage }

  //ไขมันไม่อิ่มตัวเชิงเดี่ยว
  const [ monounsaturatedFatAmount, setMonounsaturatedFatAmount ] = useState("")
  const [ monounsaturatedFatPercentage, setMonounsaturatedFatPercentage] = useState("")
  //ไขมันไม่อิ่มตัวเชิงซ้อน
  const [ polyunsaturatedFatAmount, setPolyunsaturatedFatAmount ] = useState("")
  const [ polyunsaturatedFatPercentage, setPolyunsaturatedFatPercentage] = useState("")
  //ไขมันทรานส์
  const [ transFatAmount, setTransFatAmount ] = useState("")
  const [ transFatPercentage, setTransFatPercentage] = useState("")


  //More information
  //ส่วนประกอบ
  const [ moreInfoComponent, setMoreInfoComponent ] = useState(-1)
  //รายละเอียดส่วนประกอบ
  //น้ำตาลที่เติมเข้าไปในอาหาร
  const [ sugarAddedToFoodPercentage, setSugarAddedToFoodPercentage ] = useState("")

  //**** ส่วนประกอบโดยประมาณ ****
  const [ approximateComponentsAmount, setApproximateComponentsAmount ] = useState("")
  const [ approximateComponentsSymbol, setApproximateComponentsSymbol ] = useState(1)
  const [ approximateComponentsPercentage, setApproximateComponentsPercentage ] = useState("")
  const [ approximateComponentsList, setApproximateComponentsList ] = useState([]) // { amount, symbol, percentage }


  //ข้อมูลวัตถุเจือปน
  const [ additivesInformation, setAdditivesInformation ] = useState(-1)
  //สี
  const [ colorTag, setColorTag ] = useState("")
  const [ colorTags, setColorTags ] = useState([]) // { id, name }
  //สารกันเสีย หรือวัตถุกันเสีย
  const [ preservativeTag, setPreservativeTag ] = useState("")
  const [ preservativeTags, setPreservativeTags ] = useState([]) // { id, name }
  //วัตถุที่ให้ความหวานแทนน้ำตาล
  const [ sweetenersInsteadOfSugarTag, setSweetenersInsteadOfSugarTag ] = useState("")  
  const [ sweetenersInsteadOfSugarTags, setSweetenersInsteadOfSugarTags ] = useState([]) // { id, name }
  //สารควบคุมความเป็นกรด
  const [ acidityRegulatorTag, setAcidityRegulatorTag ] = useState("")  
  const [ acidityRegulatorTags, setAcidityRegulatorTags ] = useState([]) // { id, name }
  //สารป้องกันการจับเป็นก้อน
  const [ antiCakingAgentTag, setAntiCakingAgentTag ] = useState("")
  const [ antiCakingAgentTags, setAntiCakingAgentTags ] = useState([]) // { id, name }
  //สารป้องกันการเกิดฟอง
  const [ antiFoamingAgentTag, setAntiFoamingAgentTag ] = useState("")
  const [ antiFoamingAgentTags, setAntiFoamingAgentTags ] = useState([]) // { id, name }
  //สารป้องกันการเกิดออกซิเดชั่น
  const [ antiOxidationSubstanceTag, setAntiOxidationSubstanceTag ] = useState("")
  const [ antiOxidationSubstanceTags, setAntiOxidationSubstanceTags ] = useState([]) // { id, name }
  //สารฟอกสี
  const [ bleachTag, setBleachTag ] = useState("")
  const [ bleachTags, setBleachTags ] = useState([]) // { id, name }
  //สารเพิ่มปริมาณ
  const [ excipientTag, setExcipientTag ] = useState("")
  const [ excipientTags, setExcipientTags ] = useState([]) // { id, name }
  //สารให้ก๊าซคาร์บอนไดออกไซด์
  const [ carbonDioxideTag, setCarbonDioxideTag ] = useState("")
  const [ carbonDioxideTags, setCarbonDioxideTags ] = useState([]) // { id, name }
  //สารช่วยทำละลาย หรือช่วยพา
  const [ solventsTag, setSolventsTag ] = useState("")
  const [ solventsTags, setSolventsTags ] = useState([]) // { id, name }
  //สารคงสภาพของสี
  const [ colorStabilizerTag, setColorStabilizerTag ] = useState("")
  const [ colorStabilizerTags, setColorStabilizerTags ] = useState([]) // { id, name }
  //อิมัลซิไฟเออร์
  const [ emulsifierTag, setEmulsifierTag ] = useState("")
  const [ emulsifierTags, setEmulsifierTags ] = useState([]) // { id, name }
  //เกลืออิมัลซิไฟอิ้งค์
  const [ emulsifyingSaltTag, setEmulsifyingSaltTag ] = useState("")
  const [ emulsifyingSaltTags, setEmulsifyingSaltTags ] = useState([]) // { id, name }
  //สารทำให้แน่น
  const [ firmingAgentTag, setFirmingAgentTag ] = useState("")
  const [ firmingAgentTags, setFirmingAgentTags ] = useState([]) // { id, name }
  //วัตถุปรุงแต่งรสอาหาร
  const [ foodSeasoningTag, setFoodSeasoningTag ] = useState("")
  const [ foodSeasoningTags, setFoodSeasoningTags ] = useState([]) // { id, name }
  //สารปรับปรุงคุณภาพแป้ง
  const [ flourImproverTag, setFlourImproverTag ] = useState("")
  const [ flourImproverTags, setFlourImproverTags ] = useState([]) // { id, name }
  //สารทำให้เกิดฟอง
  const [ foamingAgentTag, setFoamingAgentTag ] = useState("")
  const [ foamingAgentTags, setFoamingAgentTags ] = useState([]) // { id, name }
  //สารทำให้เกิดเจล
  const [ gellingAgentTag, setGellingAgentTag ] = useState("")
  const [ gellingAgentTags, setGellingAgentTags ] = useState([]) // { id, name }
  //สารเคลือบผิว
  const [ coatingTag, setCoatingTag ] = useState("")
  const [ coatingTags, setCoatingTags ] = useState([]) // { id, name }
  //สารทำให้เกิดความชุ่มชื้น
  const [ moisturizingAgentTag, setMoisturizingAgentTag ] = useState("")
  const [ moisturizingAgentTags, setMoisturizingAgentTags ] = useState([]) // { id, name }
  //ก๊าซที่ช่วยในการเก็บรักษาอาหาร
  const [ gasPreserveFoodTag, setGasPreserveFoodTag ] = useState("")
  const [ gasPreserveFoodTags, setGasPreserveFoodTags ] = useState([]) // { id, name }
  //ก๊าซที่ใช้ขับดัน
  const [ propellantGasTag, setPropellantGasTag ] = useState("")
  const [ propellantGasTags, setPropellantGasTags ] = useState([]) // { id, name }
  //สารช่วยให้ฟู
  const [ leaveningAgentTag, setLeaveningAgentTag ] = useState("")
  const [ leaveningAgentTags, setLeaveningAgentTags ] = useState([]) // { id, name }
  //สารช่วยจับอนุมูลโลหะ
  const [ metalScavengerTag, setMetalScavengerTag ] = useState("")
  const [ metalScavengerTags, setMetalScavengerTags ] = useState([]) // { id, name }
  //สารทำให้คงตัว
  const [ stabilizerTag, setStabilizerTag ] = useState("") 
  const [ stabilizerTags, setStabilizerTags ] = useState([]) // { id, name }
  //สารให้ความข้นเหนียว
  const [ thickenerTag, setThickenerTag ] = useState("")
  const [ thickenerTags, setThickenerTags ] = useState([]) // { id, name }
  //อื่นๆ (สาร)
  const [ otherSubstancesTag, setOtherSubstanceTag ] = useState("")
  const [ otherSubstancesTags, setOtherSubstanceTags ] = useState([]) // { id, name }


  //ข้อมูลสำหรับผู้แพ้อาหาร
  const [ informationForFoodAllergens, setInformationForFoodAllergens ] = useState(-1)
  //กลูเตน
  const [ glutenTag, setGlutenTag ] = useState("")
  const [ glutenTags, setGlutenTags ] = useState([]) // { id, name }
  //สัตว์น้ำที่มีเปลือกแข็ง
  const [ crustaceanTag, setCrustaceanTag ] = useState("")
  const [ crustaceanTags, setCrustaceanTags ] = useState([]) // { id, name }
  //ไข่ และผลิตภัณฑ์จากไข่
  const [ eggTag, setEggTag ] = useState("")
  const [ eggTags, setEggTags ] = useState([]) // { id, name }
  //ปลา และผลิตภัณฑ์จากปลา
  const [ fishTag, setFishTag ] = useState("")
  const [ fishTags, setFishTags ] = useState([]) // { id, name }
  //ถั่วลิสง ถั่วเหลือง
  const [ soybeansTag, setSoybeansTag ] = useState("")
  const [ soybeansTags, setSoybeansTags ] = useState([]) // { id, name }
  //นม แลคโตส
  const [ milkTag, setMilkTag ] = useState("")
  const [ milkTags, setMilkTags ] = useState([]) // { id, name }
  //ถั่วที่มีเปลือกแข็ง
  const [ beanTag, setBeanTag ] = useState("")
  const [ beanTags, setBeanTags ] = useState([]) // { id, name }
  //ซัลไฟต์
  const [ sulphiteTag, setSulphiteTag ] = useState("")
  const [ sulphiteTags, setSulphiteTags ] = useState([]) // { id, name }
  //อื่น ๆ
  const [ otherLoseTag, setOtherLoseTag ] = useState("")
  const [ otherLoseTags, setOtherLoseTags ] = useState([]) // { id, name }



  //GDA
  const [ codeGDA, setCodeGDA ] = useState(-1)
  //ข้อความแนะนำ
  const [ recommend, setRecommend ] = useState(-1)
  //ข้อความแนะนำ - บริโภคแต่น้อยและออกกำลังกายเพื่อสุขภาพ
  const [ recommendExcercise, setRecommendExcercise ] = useState(-1)
  //ข้อความแนะนำ - ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ
  const [ recommend5Food, setRecommend5Food ] = useState(-1)
  //ข้อความแนะนำ - อื่น ๆ
  const [ recommendOther, setRecommendOther ] = useState("")
  const [ recommendOthers, setRecommendOthers ] = useState([]) // { name }
  
  //ข้อความกล่าวอ้าง
  const [ quote, setQuote ] = useState(-1)
    //ข้อความแนะนำ - อื่น ๆ
    const [ quoteOther, setQuoteOther ] = useState("")
    const [ quoteOthers, setQuoteOthers ] = useState([]) // { name }
    

  //แหล่งผลิต
  //ผลิตจาก
  const [ madeFrom, setMadeFrom ] = useState(-1)
  //ผู้ผลิต
  const [ manufacturer, setManufacturer ] = useState("")
  //ผู้นำเข้า
  const [ importer, setImporter ] = useState("")
  //ผู้จัดจำหน่าย
  const [ distributor, setDistributor ] = useState("")



  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function validateNumber( event ){
    return (!/[.0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }


  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }

  function gotoUserPage(){
    navigate("/user")
  }

  function moveToSection( ref ){
    ref.current.scrollIntoView()  
  }

  function onChangeNumber( event ){
    if ( validateNumber( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }
  

  function handleKeyPressTag(event, tags,  tagSetter, tagsSetter){
    let r = tags
    //Enter
    if (event.charCode === 13) {
      if( event.target.value === ""){
        return;
      }
      r.push({ id:"", name: event.target.value})
      tagsSetter(r)
      tagSetter("")
    }
  }
  function removeTag(index, tags, tagsSetter ){
    let newTags = [...tags]
    newTags.splice( index , 1)
    tagsSetter(newTags)
  }

  function addList( lists, amount, symbol, percentage, listSetter, amountSetter, symbolSetter, percentageSetter ){
    let newList = [...lists]
    if( amount === "" &&  percentage === ""){
      return;
    }
    newList.push({
      name: amount,
      symbol: symbol,
      percentage: percentage,
    })
    listSetter(newList)
    amountSetter("")
    symbolSetter(1)
    percentageSetter("")
  }
  function removeList(index, lists, listSetter ){
    let newList = [...lists]
    newList.splice( index , 1)
    listSetter(newList)
  }


  function addTextList( lists, name, listSetter, nameSetter ){
    let newList = [...lists]
    if( name === "" &&  name === ""){
      return;
    }
    newList.push({
      description: name
    })

    listSetter(newList)
    nameSetter("")
  }

  function removeTextList(index, lists, listSetter ){
    let newList = [...lists]
    newList.splice( index , 1)
    listSetter(newList)
  }

  function onChangeProductRevision( revision ){
    setProductRevision(revision)
    callGetProductsApi( barcode, revision )
  }

  

  function submit( is_update ){

    let json = {
      token : localStorage.getItem("foodchoice_token"),
      products_id : productsId,
      overview : {
        barcode: barcode,
        fda_no : productCodeFDA,
        brand : productBrand,
        name : productName,
        group_id : productGroup,
        package_unit_id : productPackingUnit,
        total_weight : productNetWeight
      },
      nutrition_information : {
        label_id : nutritionInfoLabel,
        one_serving_unit : {
          amount : nutritionInfoOneServingAmount,
          package_unit_id : nutritionInfoOneServingUnit,
          gram : nutritionInfoOneServingGram
        },
        number_of_servings_per : {
          id : nutritionInfoNumberOfServingsPer,
          amount : nutritionInfoNumberOfServingsPerAmount,
        },
        nutritional_value : {
          cal : {
            amount : totalPower
          },
          cal_fat : {
            amount : energyFromFat
          },
          total_fat : {
            amount : totalFatAmount,
            percentage : totalFatPercentage
          },
          saturated_fat : {
            amount : saturatedFatAmount,
            percentage : saturatedFatPercentage
          },
          cholesterol : {
            symbol_id : cholesterolSymbol,
            amount : cholesterolAmount,
            percentage : cholesterolPercentage
          },
          protein : {
            symbol_id : proteinSymbol,
            amount : proteinAmount
          },
          carbohydrate : {
            symbol_id : carbohydrateSymbol,
            amount : carbohydrateAmount,
            percentage : carbohydratePercentage
          },
          dietary_fiber : {
            symbol_id : dietaryFiberSymbol,
            amount : dietaryFiberAmount,
            percentage : dietaryFiberPercentage
          },
          sugar : {
            symbol_id : sugarSymbol,
            amount : sugarAmount
          },
          sodium : {
            amount : sodiumAmount,
            percentage : sodiumPercentage
          },
          vitamin_a : {
            symbol_id : vitaminASymbol,
            percentage : vitaminAPersentage
          },
          vitamin_b1 : {
            symbol_id : vitaminB1Symbol,
            percentage : vitaminB1Persentage
          },
          vitamin_b2 : {
            symbol_id : vitaminB2Symbol,
            percentage : vitaminB2Persentage
          },
          calcium : {
            symbol_id : calciumSymbol,
            percentage : calciumPersentage
          },
          iron : {
            symbol_id : ironSymbol,
            percentage : ironPersentage
          },
          vitamin_c : {
            symbol_id : vitaminCSymbol,
            percentage : vitaminCPersentage
          },
          monounsaturated_fat : {
            amount : monounsaturatedFatAmount,
            percentage : monounsaturatedFatPercentage
          },
          polyunsaturated_fat : {
            amount : polyunsaturatedFatAmount,
            percentage : polyunsaturatedFatPercentage
          },
          trans_fat : {
            amount : transFatAmount,
            percentage : transFatPercentage
          },
          other : [...otherNutrientsList]
        }
      },
      more_information : {
        component_id : moreInfoComponent,
        sugar_added_to_food_percentage : sugarAddedToFoodPercentage,
        approximate_components : [...approximateComponentsList],
        additives_information : {
          id : additivesInformation,
          colors : [...colorTags],
          preservatives : [...preservativeTags],
          sweeteners_instead_of_sugars : [...sweetenersInsteadOfSugarTags],
          acidity_regulators : [...acidityRegulatorTags],
          anti_caking_agents : [...antiCakingAgentTags],
          anti_foaming_agents : [...antiFoamingAgentTags],
          anti_oxidation_substances : [...antiOxidationSubstanceTags],
          bleachs : [...bleachTags],
          excipients : [...excipientTags],
          carbon_dioxides : [...carbonDioxideTags],
          solvents : [...solventsTags],
          color_stabilizers : [...colorStabilizerTags],
          emulsifiers : [...emulsifierTags],
          emulsifying_salts : [...emulsifyingSaltTags],
          firming_agents : [...firmingAgentTags],
          food_seasonings : [...foodSeasoningTags],
          flour_improvers : [...flourImproverTags],
          foaming_agents : [...foamingAgentTags],
          gelling_agents : [...gellingAgentTags],
          coatings : [...coatingTags],
          moisturizing_agents : [...moisturizingAgentTags],
          gas_preserve_foods : [...gasPreserveFoodTags],
          propellant_gas : [...propellantGasTags],
          leavening_agents : [...leaveningAgentTags],
          metal_scavengers : [...metalScavengerTags],
          stabilizers : [...stabilizerTags],
          thickeners : [...thickenerTags],
          other : [...otherSubstancesTags],
        },
        for_allergens : {
          id : informationForFoodAllergens,
          glutens : [...glutenTags],
          crustaceans : [...crustaceanTags],
          eggs : [...eggTags],
          fish : [...fishTags],
          soybeans : [...soybeansTags],
          milk : [...milkTags],
          beans : [...beanTags],
          sulphites : [...sulphiteTags],
          other : [...otherLoseTags],
        },
        code_gda_id : codeGDA,
        recommend : {
          id: recommend,
          is_excercise : recommendExcercise,
          is_5food : recommend5Food,
          other : [...recommendOthers],
        },
        quote : {
          id: quote,
          other : [...quoteOthers],
        }
      },
      production_infomation : {
        made_from_id : madeFrom,
        manufacturer : manufacturer,
        importer : importer,
        distributor : distributor
      }
    }
    //console.log(json)

    if(is_update){
      callUpdateProductsApi(json)
    }
    else{
      callInsertProductsApi(json)
    }
  }

  function showConfirmDelete( ){

    Swal.fire({
      title: 'นำผลิตภัณฑ์ออก',
      html: '<div class="swal-content">คุณต้องการนำผลิตภัณฑ์นี้ออกหรือไม่</div>',
      icon: 'warning', 
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: '<span class="">นำออก</span>',
      cancelButtonText: '<span class="">ยกเลิก</span>',
      confirmButtonColor: '#FF0000'

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteProductsApi(0)
      }
    })
  }

  function showConfirmShow(){
    Swal.fire({
      title: 'แสดงผลิตภัณฑ์',
      html: '<div class="swal-content">คุณต้องการแสดงผลิตภัณฑ์นี้หรือไม่</div>',
      icon: 'warning', 
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: '<span class="">ยกเลิก</span>',
      confirmButtonColor: '#009640'

    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteProductsApi(1)
      }
    })
  }


  function showConfirmUpdate(){

    Swal.fire({
      title: 'แก้ไขข้อมูล',
      html: '<div class="swal-content">คุณต้องการบันทึกการแก้ไขข้อมูลหรือไม่</div>',
      icon: 'warning', 
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: '<span class="">ยกเลิก</span>',
      confirmButtonColor: '#009640'

    }).then((result) => {
      if (result.isConfirmed) {
        submit( true )
      }
    })
  }

  function  showConfirmNew(){

    Swal.fire({
      title: 'เพิ่มผลิตภัณฑ์ใหม่',
      html: '<div class="swal-content">คุณต้องการ  เพิ่มผลิตภัณฑ์ใหม่ หรือไม่</div>',
      icon: 'warning', 
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: '<span class="">ตกลง</span>',
      cancelButtonText: '<span class="">ยกเลิก</span>',
      confirmButtonColor: '#009640'

    }).then((result) => {
      if (result.isConfirmed) {
        submit( false )
      }
    })
  }

  function showConfirmAddToNewRevision(){
    Swal.fire({
      title: 'สร้าง version ผลิตภัณฑ์ใหม่',
      html: '<div class="swal-content">คุณต้องการสร้าง version ของผลิตภัณฑ์ใหม่หรือไม่</div>',
      icon: 'warning', 
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: '<span class="">สร้างใหม่</span>',
      cancelButtonText: '<span class="">ยกเลิก</span>',
      confirmButtonColor: '#009640'

    }).then((result) => {
      if (result.isConfirmed) {
        submit(false  )
      }
    })
  }
  

  return (
    <div class="w-full flex-wrap">

      { /* Detail */}
      <div className='w-full h-screen mx-auto overflow-auto bg-white '>
          <div className='w-full flex flex-wrap mt-3 text-sm bg-gray-100 px-0 lg:px-6 py-6'>
              <div className='w-full flex flex-wrap justify-end '>
                <span className='w-full text-xl font-bold'>เลขที่ Barcode : {barcode}</span>
              </div>
              {
                (revisionList !== null && revisionList.length > 0)?
                  <div className='w-full flex flex-wrap justify-end '>
                    {
                      ( productShow === 1 )?
                        <button onClick={showConfirmDelete} className=' px-4 bg-red-600 rounded-lg text-white my-2 py-2 '>นำผลิตภัณฑ์ออก</button>
                      :
                        <button onClick={showConfirmShow} className=' px-4 bg-green-600 rounded-lg text-white my-2 py-2 '>แสดงผลิตภัณฑ์</button>
            
                    }
                    
                  </div>
                :
                null
              }
              {
                (revisionList != null && revisionList.length > 0)?

                <div className='w-full flex flex-wrap '>
                  <span className='text-sm lg:text-md'>เลือกข้อมูลจาก Version</span>
                  <div className='w-full '>
                    <select value={productRevision} defaultValue={1} onChange={(e)=>{ onChangeProductRevision(parseInt(e.target.value))}}  className="flex border-grey-500 border text-sm py-2 px-3 rounded-md">
                      {
                        revisionList.map(function(item, index){
                          return <option value={item.revision_of_product}>Version {item.revision_of_product} : {item.revision_of_product_date}</option>
                        })
                      }
                      
                    </select>
                  </div>
                </div>
                :
                null
              }
          </div>
          <div className='w-full flex flex-wrap mt-3 text-sm  px-0 lg:px-6 py-6 bg-gray-100 '>

                <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='flex flex-wrap  '>
                      <button onClick={ (e)=>{moveToSection( refOverview )} } className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>ข้อมูลภาพรวม</button>
                    </div>    
                    <div className='flex flex-wrap'>
                      <button onClick={ (e)=>{moveToSection( refNutrient )} }className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>ข้อมูลโภชนาการ</button>
                    </div>      
                    <div className='flex flex-wrap'>
                      <button onClick={ (e)=>{moveToSection( refMoreInfo )} }className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>ข้อมูลเพิ่มเติม</button>
                    </div>        
                    <div className='flex flex-wrap'>
                      <button onClick={(e)=>{ moveToSection( refMade )} }className='w-full bg-gray-200 hover:bg-gray-300 py-2 px-3'>แหล่งผลิต</button>
                    </div>      
                </div>


                {/* Section#5 */}
                {
                  (foodchoiceData !== null)?

                    <div  className='flex flex-wrap w-full px-3 lg:px-6'>
                      <div className='w-full flex flex-wrap mt-3 mb-6'>
                        <span className='text-lg font-bold'>  Foodchoice</span>
                      </div>
                      <div className='w-full flex flex-wrap text-sm'>
                        <div className='w-full'>
                          <span className=' text-lg font-bold'>{ foodchoiceData.overview.name }</span>
                        </div>
                        <div className='w-full'>
                          <span className=' text-lg'>ต่อ { foodchoiceData.overview.per_portion } { foodchoiceData.overview.per_portion_packaging } ( { foodchoiceData.overview.per_portion_gram } )</span>
                        </div>
                        <div className='w-full mt-2'>
                          <span className=' text-sm'>วันที่แก้ไขล่าสุด : { foodchoiceData.update_at }</span>
                        </div>
                      </div>

                      <div className='w-full  mt-6 flex-wrap flex justify-center'>
                      {
                      foodchoiceData.nutrients.map(function(item, index){
                        return (
                          <div className="flex w-[80px] h-[120px] mx-2">
                            <div className="flex-wrap w-[80px]  h-[120px] rounded-t-full " style={{ backgroundColor : item.score.color }}>
                              <div className="flex-wrap mt-[8px] mx-[5px] rounded-full bg-white w-[70px] h-[70px] flex align-middle ">
                                <div className="w-full flex-wrap my-auto">
                                  <span className="flex w-full justify-center text-md">{ item.value }</span>
                                  <span className="flex w-full justify-center text-sm">{ item.unit }</span>
                                </div>
                              </div>

                              <div className="w-full flex-wrap my-auto mt-1">
                                  <span className="flex w-full justify-center text-sm text-white">{ item.description }</span>
                                  <span className="flex w-full justify-center text-xs text-white">{ item.percentage }</span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                      </div>


                      <div className='w-full flex flex-wrap text-sm mt-6'>
                        <div className='w-full'>
                          <span className=' text-lg'>กลุ่ม {((foodchoiceData.overview.criteria_depart_of_health) !== null )? foodchoiceData.overview.criteria_depart_of_health.description : ""}</span>
                        </div>
                        <div className='w-full mt-2'>
                          <span className=' text-md'>น้ำตาล { (foodchoiceData.sugar_add_on > 0)? foodchoiceData.sugar_add_on : " - "} ช้อนชา</span>
                        </div>
                        <div className='w-full mt-2'>
                          <span className=' text-md text-red-500'>ข้อมูลสำหรับผู้แพ้อาหาร: {foodchoiceData.allergens.summary}</span>
                        </div>
                      </div>

                    </div>
                    :
                    null
                }

                <div ref={refOverview} className='flex flex-wrap w-full border-b-1 border-gray-200  py-3 px-3 lg:px-6 mt-4'>
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  ข้อมูลภาพรวม</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>รหัส อย.</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ productCodeFDA } onChange={ (e)=> { setProductCodeFDA(onChangeText(e)); } } type={"search"} className={" border w-full text-sm py-2 px-3 rounded-md "+((productCodeFDA === "")? " border-grey-500 " : " border-green-600  border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ตราสินค้า</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ productBrand } onChange={ (e)=> { setProductBrand(onChangeText(e)) } } type={"search"} className={" border w-full text-sm py-2 px-3 rounded-md  "+((productBrand === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ชื่อสินค้า</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ productName } onChange={ (e)=> { setProductName(onChangeText(e)) } } type={"search"}  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md   "+((productName === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>กลุ่มอาหาร</span>
                    </div>
                    <div className={"w-full  md:w-3/4 "+ ((productGroup === "")? "" : "border-green-600 border-2 rounded-lg p-1")}>
                      <AutoCompleteGroupText is_disabled={false} sources={ groupFoodList } selectId={ productGroup } handlerChangeDropdown={setProductGroup} placeholder={"ค้นหากลุ่มอาหาร"}></AutoCompleteGroupText>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>หน่วยบรรจุภัณฑ์</span>
                    </div>
                    <div className={"w-full  md:w-3/4 "+ ((productPackingUnit === "")? "" : "border-green-600 border-2 rounded-lg p-1")}>
                      <AutoCompleteText is_disabled={false} sources={ unitList } selectId={ productPackingUnit } handlerChangeDropdown={setProductPackingUnit} placeholder={"ค้นหาหน่วยบรรจุภัณฑ์"}></AutoCompleteText>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>น้ำหนักสุทธิ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ productNetWeight } onChange={ (e)=> { setProductNetWeight(onChangeNumber(e)) } }  type={"search"}  className={" border w-full text-sm py-2 px-3 rounded-md  "+((productNetWeight === "")? " border-grey-500 " : " border-green-600 border-2 ")} />

                    </div>
                  </div>


                </div>

                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>

                {/* Section#2 */}
                <div ref={refNutrient} className='flex flex-wrap w-full py-3 px-3 lg:px-6'>
              
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  ข้อมูลโภชนาการ</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3'>

                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ฉลากโภชนาการ</span>
                    </div>
                    <div className='w-full flex flex-wrap  text-sm md:w-3/4'>
                      <div className='w-full lg:w-1/4'>
                        <input type={"radio"}  className="mr-2"   onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={0}  checked={ (nutritionInfoLabel === 0 )? ' checked ' : ''}  defaultChecked />ไม่แสดง
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'>
                        <input type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={1}  checked={ (nutritionInfoLabel === 1 )? ' checked ' : ''} />แบบอื่น ๆ
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'    >
                        <input type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={2}  checked={ (nutritionInfoLabel === 2 )? ' checked ' : ''} />แบบเต็ม
                      </div>
                      <div className='w-full lg:w-1/4 mt-2 lg:mt-0'    >
                        <input type={"radio"} className="mr-2"    onChange={(e)=>{ setNutritionInfoLabel( parseInt(e.currentTarget.value)) }} name="radio-nutrient-label" value={3}  checked={ (nutritionInfoLabel === 3 )? ' checked ' : ''} />แบบย่อ
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>หนึ่งหน่วยบริโภค</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>
                
                      <div className='w-full lg:w-1/3 px-0 lg:px-3'>
                        <input value={ nutritionInfoOneServingAmount } onChange={ (e)=> { setNutritionInfoOneServingAmount(onChangeNumber(e)) } } type="search" className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md    "+((nutritionInfoOneServingAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                      </div>
                      <div className={'w-full lg:w-1/3 px-0 lg:px-3 mt-2 lg:mt-0 '+ ((nutritionInfoOneServingUnit === "")? "" : "border-green-600 border-2 rounded-lg")}>
                        <AutoCompleteText  is_disabled={false} sources={ unitList } selectId={ nutritionInfoOneServingUnit } handlerChangeDropdown={setNutritionInfoOneServingUnit} placeholder={"ค้นหาหน่วยบรรจุภัณฑ์"}></AutoCompleteText>
                      </div>
                      <div className='w-full lg:w-1/3 px-0 lg:px-3 
                      flex  text-sm  mt-2 lg:mt-0'>
                        <div className='flex-none mt-2 pr-2'>(</div>
                        <div className='flex-grow '><input type="search" value={ nutritionInfoOneServingGram } onChange={ (e)=> { setNutritionInfoOneServingGram(onChangeNumber(e)) } } className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((nutritionInfoOneServingGram === "")? " border-grey-500 " : " border-green-600 border-2 ")} /></div>
                        <div className='flex-none mt-2 pl-2'>กรัม )</div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>จำนวนหน่วยบริโภคต่อ</span>
                    </div>
                    <div className='w-full flex flex-wrap  md:w-3/4'>
                      <div className='w-full flex  text-sm md:w-3/4'>
                        <div className='w-full lg:w-1/4'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setNutritionInfoNumberOfServingsPer( parseInt(e.currentTarget.value)) }} name="radio-nutrient-no-serving" value={0}  checked={ (nutritionInfoNumberOfServingsPer === 0 )? ' checked ' : ''} defaultChecked />-
                        </div>
                        <div className='w-full lg:w-1/4'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setNutritionInfoNumberOfServingsPer( parseInt(e.currentTarget.value)) }} name="radio-nutrient-no-serving" value={1}  checked={ (nutritionInfoNumberOfServingsPer === 1 )? ' checked ' : ''}  />ประมาณ
                        </div>
                      </div>
                      <div className='w-full mt-2'>
                        <input type={"search"} value={ nutritionInfoNumberOfServingsPerAmount } onChange={ (e)=> { setNutritionInfoNumberOfServingsPerAmount(onChangeNumber(e)) } } className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((nutritionInfoNumberOfServingsPerAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-6'>
                    <div className='w-full'>
                      <span className='font-bold text-sm'>คุณค่าทางโภชนาการต่อหนึ่งหน่วยบริโภค</span>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm mr-1'>พลังงานทั้งหมด</span>
                    </div>
                    <div className='w-full flex md:w-3/4'>
                      <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ totalPower } onChange={ (e)=> { setTotalPower(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-3 "+((totalPower === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300  text-sm px-4 py-3  rounded-r-md'>
                            <span className=' text-sm'>กิโลแคลลอรี</span>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4 pr-1'>
                      <span className='font-bold text-sm '>พลังงานจากไขมัน</span>
                    </div>
                    <div className='w-full flex md:w-3/4  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ energyFromFat } onChange={ (e)=> { setEnergyFromFat(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-3 "+((energyFromFat === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none  text-sm bg-gray-300  px-4 py-3  rounded-r-md'>
                            <span>กิโลแคลลอรี</span>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันทั้งหมด</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>

                      <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ totalFatAmount } onChange={ (e)=> { setTotalFatAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((totalFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 lg:pl-2'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ totalFatPercentage } onChange={ (e)=> { setTotalFatPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((totalFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันอิ่มตัว</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>

                      <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ saturatedFatAmount } onChange={ (e)=> { setSaturatedFatAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((saturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 lg:pl-2'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='flex-grow w-full border-grey-500 border '>
                            <input value={ saturatedFatPercentage } onChange={ (e)=> { setSaturatedFatPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((saturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โคเลสเตอรอล</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select  defaultValue={1}  value={cholesterolSymbol} onChange={(e)=> { setCholesterolSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ cholesterolAmount } onChange={ (e)=> { setCholesterolAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((cholesterolAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>มก.</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ cholesterolPercentage } onChange={ (e)=> { setCholesterolPercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((cholesterolPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โปรตีน</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={proteinSymbol} onChange={(e)=> { setProteinSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input value={ proteinAmount } onChange={ (e)=> { setProteinAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((proteinAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>กรัม</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>คาร์โบไฮเดรต</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select efaultValue={1}  value={carbohydrateSymbol} onChange={(e)=> { setCarbohydrateSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ carbohydrateAmount } onChange={ (e)=> { setCarbohydrateAmount(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((carbohydrateAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ carbohydratePercentage } onChange={ (e)=> { setCarbohydratePercentage(onChangeNumber(e)) } } type="search" className={"h-full w-full text-sm py-2 px-2 "+((carbohydratePercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ใยอาหาร</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>

                          <div className='flex-none bg-gray-300 px-1 py-2   rounded-l-md'>
                            <select defaultValue={1}  value={dietaryFiberSymbol} onChange={(e)=> { setDietaryFiberSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                              <option  value={1}>=</option>
                              <option  value={2}>&#10094;</option>
                            </select>
                          </div>
                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ dietaryFiberAmount } onChange={ (e)=> { setDietaryFiberAmount(onChangeNumber(e)) } } type="search"  className={"h-full w-full text-sm py-2 px-2 "+((dietaryFiberAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ dietaryFiberPercentage } onChange={ (e)=> { setDietaryFiberPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((dietaryFiberPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>น้ำตาล</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={sugarSymbol} onChange={(e)=> { setSugarSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ sugarAmount } onChange={ (e)=> { setSugarAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((sugarAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>กรัม</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>โซเดียม</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                        <div className='w-full flex text-sm rounded-md '>


                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ sodiumAmount } onChange={ (e)=> { setSodiumAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((sodiumAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>มก.</span>
                          </div>
                        </div>
                      </div>

                      <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2' >

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ sodiumPercentage } onChange={ (e)=> { setSodiumPercentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((sodiumPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินเอ</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={vitaminASymbol} onChange={(e)=> { setVitaminASymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input value={ vitaminAPersentage } onChange={ (e)=> { setVitaminAPersentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((vitaminAPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินบี 1</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={vitaminB1Symbol} onChange={(e)=> { setVitaminB1Symbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ vitaminB1Persentage } onChange={ (e)=> { setVitaminB1Persentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((vitaminB1Persentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินบี 2</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={vitaminB2Symbol} onChange={(e)=> { setVitaminB2Symbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ vitaminB2Persentage } onChange={ (e)=> { setVitaminB2Persentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((vitaminB2Persentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>แคลเซียม</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={calciumSymbol} onChange={(e)=> { setCalciumSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ calciumPersentage } onChange={ (e)=> { setCalciumPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((calciumPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")}  />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>เหล็ก</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={ironSymbol} onChange={(e)=> { setIronSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input   value={ ironPersentage } onChange={ (e)=> { setIronPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((ironPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>วิตามินซี</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>


                      <div className='w-full lg:w-1/2 flex pr-0 lg:pr-0'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={vitaminCSymbol} onChange={(e)=> { setVitaminCSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ vitaminCPersentage } onChange={ (e)=> { setVitaminCPersentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((vitaminCPersentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                      </div>


                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full'>
                      <span className='font-bold text-sm'>สารอาหารอื่น ๆ</span>
                    </div>
                    <div className='w-full flex flex-wrap mt-2'>
                        <div className='w-1/6 flex'>
                          <button onClick={ (e)=>{  addList( otherNutrientsList, otherNutrientsAmount, otherNutrientsSymbol, otherNutrientsPercentage, setOtherNutrientsList, setOtherNutrientsAmount, setOtherNutrientsSymbol, setOtherNutrientsPercentage ) }} className='bg-blue-500 py-2 px-4 text-white rounded-md'> + </button>
                        </div>
                        <div className='w-full md:w-2/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                          <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                            <input value={ otherNutrientsAmount } onChange={ (e)=> { setOtherNutrientsAmount(onChangeText(e)) } }  type="search"  className="h-full w-full text-sm py-2 px-3" />
                          </div>
                        </div>
                        <div className='w-full md:w-1/2 mt-2 md:mt-0  flex pr-1'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={otherNutrientsSymbol} onChange={(e)=> { setOtherNutrientsSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input value={ otherNutrientsPercentage } onChange={ (e)=> { setOtherNutrientsPercentage(onChangeNumber(e)) } }  type="search"  className="h-full w-full text-sm py-2 px-2" />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                    </div>
                    {

                      otherNutrientsList.map(function(item, index){
                        return (
                          <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/6 flex'>
                                <button onClick={ (e)=>{ removeList(index, otherNutrientsList, setOtherNutrientsList) }} className='bg-red-500 py-2 px-4 w-9 h-9 text-white rounded-md'> - </button>
                              </div>
                              <div className='w-full md:w-2/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                  <input value={item.name} type={"search"} className="h-full w-full text-sm py-2 px-3" disabled />
                                </div>
                              </div>
                              <div className='w-full md:w-1/2 mt-2 md:mt-0  flex pr-1'>
                                <div className='w-full flex text-sm rounded-md '>

                                  <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                                    <select value={item.symbol} className='bg-gray-300 mt-1' disabled>
                                      <option  value={1}>=</option>
                                      <option  value={2}>&#10094;</option>
                                    </select>
                                  </div>
                                  <div className='grow w-full border-grey-500 border '>
                                    <input value={item.percentage} type={"search"} className="h-full w-full text-sm py-2 px-2" disabled />
                                  </div>
                                  <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                                    <span>%</span>
                                  </div>
                                </div>
                              </div>
                          </div>
                        )
                      })

                    }
                  </div>

                  <div className='w-full flex flex-wrap mt-12  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันไม่อิ่มตัวเชิงเดี่ยว</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ monounsaturatedFatAmount } onChange={ (e)=> { setMonounsaturatedFatAmount(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((monounsaturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ monounsaturatedFatPercentage } onChange={ (e)=> { setMonounsaturatedFatPercentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((monounsaturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันไม่อิ่มตัวเชิงซ้อน</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ polyunsaturatedFatAmount } onChange={ (e)=> { setPolyunsaturatedFatAmount(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((polyunsaturatedFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input  value={ polyunsaturatedFatPercentage } onChange={ (e)=> { setPolyunsaturatedFatPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((polyunsaturatedFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>ไขมันทรานส์</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                    <div className='w-full lg:w-1/2 flex  text-sm'>

                        <div className='w-full flex text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input   value={ transFatAmount } onChange={ (e)=> { setTransFatAmount(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((transFatAmount === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>กรัม</span>
                          </div>
                        </div>

                    </div>

                    <div className='w-full lg:w-1/2 flex  text-sm mt-2 lg:mt-0 pl-0 lg:pl-2'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input   value={ transFatPercentage } onChange={ (e)=> { setTransFatPercentage(onChangeNumber(e)) } }  type="search"  className={"h-full w-full text-sm py-2 px-2 "+((transFatPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                    </div>

                    </div>
                  </div>


                </div>

                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>

                {/* Section#3 */}
                <div ref={refMoreInfo} className='flex flex-wrap w-full px-3 lg:px-6 '>
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  ข้อมูลเพิ่มเติม</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ส่วนประกอบ</span>
                    </div>
                    <div className='w-full  md:w-3/4 text-sm'>
                      <div className='w-full'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setMoreInfoComponent( parseInt(e.currentTarget.value)) }} name="radio-more-info-component" value={0}  checked={ (moreInfoComponent === 0 )? ' checked ' : ''} defaultChecked />ไม่มี สมุนไพร / ผัก / ผลไม้
                      </div>
                      <div className='w-full mt-2'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setMoreInfoComponent( parseInt(e.currentTarget.value)) }} name="radio-more-info-component" value={1}  checked={ (moreInfoComponent === 1 )? ' checked ' : ''} />มี สมุนไพร / ผัก / ผลไม้ 100 %
                      </div>
                      <div className='w-full mt-2'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setMoreInfoComponent( parseInt(e.currentTarget.value)) }} name="radio-more-info-component" value={2}  checked={ (moreInfoComponent === 2 )? ' checked ' : ''} />มี สมุนไพร / ผัก / ผลไม้ ผสม
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3'>
                    <div className='w-full'>
                      <span className='font-bold text-sm'>รายละเอียดส่วนประกอบ</span>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold text-sm'>น้ำตาลที่เติมเข้าไปในอาหาร</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <div className='w-full lg:w-1/2 flex flex-wrap text-sm'>

                        <div className='w-full flex  text-sm rounded-md '>
                          <div className='grow w-full border-grey-500 border '>
                            <input value={ sugarAddedToFoodPercentage } onChange={ (e)=> { setSugarAddedToFoodPercentage(onChangeNumber(e)) } }  type="search" className={"h-full w-full text-sm py-2 px-2 "+((sugarAddedToFoodPercentage === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                          </div>
                          <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                            <span>%</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full '>
                      <span className='font-bold text-sm'>ส่วนประกอบโดยประมาณ</span>
                    </div>

                    <div className='w-full flex flex-wrap mt-2'>
                        <div className='w-1/6 flex'>
                          <button onClick={ (e)=>{  addList( approximateComponentsList, approximateComponentsAmount, approximateComponentsSymbol, approximateComponentsPercentage, setApproximateComponentsList, setApproximateComponentsAmount, setApproximateComponentsSymbol, setApproximateComponentsPercentage ) }} className='bg-blue-500 py-2 px-4 text-white rounded-md'> + </button>
                        </div>
                        <div className='w-full md:w-2/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                          <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                            <input value={ approximateComponentsAmount } onChange={ (e)=> { setApproximateComponentsAmount(onChangeText(e)) } }  type="search" className="h-full w-full text-sm py-2 px-3" />
                          </div>
                        </div>
                        <div className='w-full md:w-1/2 mt-2 md:mt-0  flex pr-1'>
                          <div className='w-full flex text-sm rounded-md '>

                            <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                              <select defaultValue={1}  value={approximateComponentsSymbol} onChange={(e)=> { setApproximateComponentsSymbol(parseInt(e.target.value)) }} className='bg-gray-300 mt-1'>
                                <option  value={1}>=</option>
                                <option  value={2}>&#10094;</option>
                              </select>
                            </div>
                            <div className='grow w-full border-grey-500 border '>
                              <input  value={ approximateComponentsPercentage } onChange={ (e)=> { setApproximateComponentsPercentage(onChangeNumber(e)) } }  type="search"  className="h-full w-full text-sm py-2 px-2" />
                            </div>
                            <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                    </div>
                    {

                      approximateComponentsList.map(function(item, index){
                        return (
                          <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/6 flex'>
                                <button onClick={ (e)=>{ removeList(index, approximateComponentsList, setApproximateComponentsList) }} className='bg-red-500 py-2 px-4 w-9 h-9 text-white rounded-md'> - </button>
                              </div>
                              <div className='w-full md:w-2/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                  <input value={item.name} type={"search"} className="h-full w-full text-sm py-2 px-3" disabled />
                                </div>
                              </div>
                              <div className='w-full md:w-1/2 mt-2 md:mt-0  flex pr-1'>
                                <div className='w-full flex text-sm rounded-md '>

                                  <div className='flex-none bg-gray-300 px-1 py-2  rounded-l-md'>
                                    <select value={item.symbol} className='bg-gray-300 mt-1' disabled>
                                      <option  value={1}>=</option>
                                      <option  value={2}>&#10094;</option>
                                    </select>
                                  </div>
                                  <div className='grow w-full border-grey-500 border '>
                                    <input value={item.percentage} type={"search"} className="h-full w-full text-sm py-2 px-2" disabled />
                                  </div>
                                  <div className='flex-none bg-gray-300 text-xs px-2 py-3  rounded-r-md'>
                                    <span>%</span>
                                  </div>
                                </div>
                              </div>
                          </div>
                        )
                      })

                    }
                  </div>

                  <div className='w-full flex flex-wrap mt-12 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ข้อมูลวัตถุเจือปน</span>
                    </div>
                    <div className='w-full flex md:w-3/4'>
                    
                      
                      <div className='w-1/2'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setAdditivesInformation( parseInt(e.currentTarget.value)) }} name="radio-additives-information" value={0}  checked={ (additivesInformation === 0 )? ' checked ' : ''} defaultChecked />ไม่แสดง
                      </div>
                      <div className='w-1/2 mt-2 lg:mt-0'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setAdditivesInformation( parseInt(e.currentTarget.value)) }} name="radio-additives-information" value={1}  checked={ (additivesInformation === 1 )? ' checked ' : ''} />แสดง
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>สี</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={colorTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, colorTags, setColorTag, setColorTags)) }} onChange={ (e)=> { setColorTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          colorTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, colorTags , setColorTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
                      
                          
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารกันเสีย หรือวัตถุกันเสีย</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={preservativeTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, preservativeTags, setPreservativeTag, setPreservativeTags)) }} onChange={ (e)=> { setPreservativeTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          preservativeTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, preservativeTags , setPreservativeTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>
                

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>วัตถุที่ให้ความหวานแทนน้ำตาล</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={sweetenersInsteadOfSugarTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, sweetenersInsteadOfSugarTags, setSweetenersInsteadOfSugarTag, setSweetenersInsteadOfSugarTags)) }} onChange={ (e)=> { setSweetenersInsteadOfSugarTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          sweetenersInsteadOfSugarTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, sweetenersInsteadOfSugarTags , setSweetenersInsteadOfSugarTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>
                
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารควบคุมความเป็นกรด</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={acidityRegulatorTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, acidityRegulatorTags, setAcidityRegulatorTag, setAcidityRegulatorTags)) }} onChange={ (e)=> { setAcidityRegulatorTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          acidityRegulatorTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, acidityRegulatorTags , setAcidityRegulatorTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>        


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารป้องกันการจับเป็นก้อน</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={antiCakingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, antiCakingAgentTags, setAntiCakingAgentTag, setAntiCakingAgentTags)) }} onChange={ (e)=> { setAntiCakingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          antiCakingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, antiCakingAgentTags , setAntiCakingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>    

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารป้องกันการเกิดฟอง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={antiFoamingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, antiFoamingAgentTags, setAntiFoamingAgentTag, setAntiFoamingAgentTags)) }} onChange={ (e)=> { setAntiFoamingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          antiFoamingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, antiFoamingAgentTags , setAntiFoamingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>              


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารป้องกันการเกิดออกซิเดชั่น</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={antiOxidationSubstanceTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, antiOxidationSubstanceTags, setAntiOxidationSubstanceTag, setAntiOxidationSubstanceTags)) }} onChange={ (e)=> { setAntiOxidationSubstanceTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          antiOxidationSubstanceTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, antiOxidationSubstanceTags , setAntiOxidationSubstanceTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารฟอกสี</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={bleachTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, bleachTags, setBleachTag, setBleachTags)) }} onChange={ (e)=> { setBleachTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          bleachTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, bleachTags , setBleachTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   
      
      
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารเพิ่มปริมาณ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={excipientTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, excipientTags, setExcipientTag, setExcipientTags)) }} onChange={ (e)=> { setExcipientTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          excipientTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, excipientTags , setExcipientTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   

      
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารให้ก๊าซคาร์บอนไดออกไซด์</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={carbonDioxideTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, carbonDioxideTags, setCarbonDioxideTag, setCarbonDioxideTags)) }} onChange={ (e)=> { setCarbonDioxideTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          carbonDioxideTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, carbonDioxideTags , setCarbonDioxideTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   



                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารช่วยทำละลาย หรือช่วยพา</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={solventsTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, solventsTags, setSolventsTag, setSolventsTags)) }} onChange={ (e)=> { setSolventsTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          solventsTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, solventsTags , setSolventsTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   



                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารคงสภาพของสี</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={colorStabilizerTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, colorStabilizerTags, setColorStabilizerTag, setColorStabilizerTags)) }} onChange={ (e)=> { setColorStabilizerTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          colorStabilizerTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, colorStabilizerTags , setColorStabilizerTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>อิมัลซิไฟเออร์</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={emulsifierTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, emulsifierTags, setEmulsifierTag, setEmulsifierTags)) }} onChange={ (e)=> { setEmulsifierTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          emulsifierTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, emulsifierTags , setEmulsifierTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>เกลืออิมัลซิไฟอิ้งค์</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={emulsifyingSaltTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, emulsifyingSaltTags, setEmulsifyingSaltTag, setEmulsifyingSaltTags)) }} onChange={ (e)=> { setEmulsifyingSaltTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          emulsifyingSaltTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, emulsifyingSaltTags , setEmulsifyingSaltTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารทำให้แน่น</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={firmingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, firmingAgentTags, setFirmingAgentTag, setFirmingAgentTags)) }} onChange={ (e)=> { setFirmingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          firmingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, firmingAgentTags , setFirmingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>วัตถุปรุงแต่งรสอาหาร</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={foodSeasoningTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, foodSeasoningTags, setFoodSeasoningTag, setFoodSeasoningTags)) }} onChange={ (e)=> { setFoodSeasoningTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          foodSeasoningTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, foodSeasoningTags , setFoodSeasoningTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารปรับปรุงคุณภาพแป้ง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={flourImproverTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, flourImproverTags, setFlourImproverTag, setFlourImproverTags)) }} onChange={ (e)=> { setFlourImproverTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          flourImproverTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, flourImproverTags , setFlourImproverTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารทำให้เกิดฟอง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={foamingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, foamingAgentTags, setFoamingAgentTag, setFoamingAgentTags)) }} onChange={ (e)=> { setFoamingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          foamingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, foamingAgentTags , setFoamingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารทำให้เกิดเจล</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={gellingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, gellingAgentTags, setGellingAgentTag, setGellingAgentTags)) }} onChange={ (e)=> { setGellingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          gellingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, gellingAgentTags , setGellingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารเคลือบผิว</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={coatingTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, coatingTags, setCoatingTag, setCoatingTags)) }} onChange={ (e)=> { setCoatingTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          coatingTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, coatingTags , setCoatingTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารทำให้เกิดความชุ่มชื้น</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={moisturizingAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, moisturizingAgentTags, setMoisturizingAgentTag, setMoisturizingAgentTags)) }} onChange={ (e)=> { setMoisturizingAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          moisturizingAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, moisturizingAgentTags , setMoisturizingAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ก๊าซที่ช่วยในการเก็บรักษาอาหาร</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={gasPreserveFoodTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, gasPreserveFoodTags, setGasPreserveFoodTag, setGasPreserveFoodTags)) }} onChange={ (e)=> { setGasPreserveFoodTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          gasPreserveFoodTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, gasPreserveFoodTags , setGasPreserveFoodTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   
            
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ก๊าซที่ใช้ขับดัน</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={propellantGasTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, propellantGasTags, setPropellantGasTag, setPropellantGasTags)) }} onChange={ (e)=> { setPropellantGasTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          propellantGasTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, propellantGasTags , setPropellantGasTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารช่วยให้ฟู</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={leaveningAgentTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, leaveningAgentTags, setLeaveningAgentTag, setLeaveningAgentTags)) }} onChange={ (e)=> { setLeaveningAgentTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          leaveningAgentTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, leaveningAgentTags , setLeaveningAgentTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารช่วยจับอนุมูลโลหะ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={metalScavengerTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, metalScavengerTags, setMetalScavengerTag, setMetalScavengerTags)) }} onChange={ (e)=> { setMetalScavengerTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          metalScavengerTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, metalScavengerTags , setMetalScavengerTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   
            
            
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารทำให้คงตัว</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={stabilizerTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, stabilizerTags, setStabilizerTag, setStabilizerTags)) }} onChange={ (e)=> { setStabilizerTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          stabilizerTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, stabilizerTags , setStabilizerTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   


            
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สารให้ความข้นเหนียว</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={thickenerTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, thickenerTags, setThickenerTag, setThickenerTags)) }} onChange={ (e)=> { setThickenerTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          thickenerTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, thickenerTags , setThickenerTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>   
              
      
                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>อื่นๆ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={otherSubstancesTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, otherSubstancesTags, setOtherSubstanceTag, setOtherSubstanceTags)) }} onChange={ (e)=> { setOtherSubstanceTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          otherSubstancesTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, otherSubstancesTags , setOtherSubstanceTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>           



                </div>

                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>

                {/* Section#4 */}
                <div className='flex flex-wrap w-full px-3 lg:px-6'>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ข้อมูลสำหรับผู้แพ้อาหาร</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4'>


                      <div className='w-full lg:w-1/3 '>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setInformationForFoodAllergens( parseInt(e.currentTarget.value)) }} name="radio-information-for-food-allergens" value={0}  checked={ (informationForFoodAllergens === 0 )? ' checked ' : ''} defaultChecked />ไม่แสดง
                      </div>
                      <div className='w-full lg:w-1/3 mt-2 lg:mt-0'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setInformationForFoodAllergens( parseInt(e.currentTarget.value)) }} name="radio-information-for-food-allergens" value={1}  checked={ (informationForFoodAllergens === 1 )? ' checked ' : ''} />แสดง
                      </div>
                      <div className='w-full lg:w-1/3 mt-2 lg:mt-0 '>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setInformationForFoodAllergens( parseInt(e.currentTarget.value)) }} name="radio-information-for-food-allergens" value={2}  checked={ (informationForFoodAllergens === 2 )? ' checked ' : ''} />ชื่ออาหารบ่งบอกสารก่อภูมิแพ้
                      </div>
                    </div>
                  </div>

          
                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>กลูเตน</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={glutenTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, glutenTags, setGlutenTag, setGlutenTags)) }} onChange={ (e)=> { setGlutenTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          glutenTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, glutenTags , setGlutenTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>สัตว์น้ำที่มีเปลือกแข็ง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={crustaceanTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, crustaceanTags, setCrustaceanTag, setCrustaceanTags)) }} onChange={ (e)=> { setCrustaceanTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          crustaceanTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, crustaceanTags , setCrustaceanTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ไข่ และผลิตภัณฑ์จากไข่</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={eggTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, eggTags, setEggTag, setEggTags)) }} onChange={ (e)=> { setEggTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          eggTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, eggTags , setEggTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ปลา และผลิตภัณฑ์จากปลา</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={fishTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, fishTags, setFishTag, setFishTags)) }} onChange={ (e)=> { setFishTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          fishTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, fishTags , setFishTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ถั่วลิสง ถั่วเหลือง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={soybeansTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, soybeansTags, setSoybeansTag, setSoybeansTags)) }} onChange={ (e)=> { setSoybeansTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          soybeansTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, soybeansTags , setSoybeansTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>นม แลคโตส</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={milkTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, milkTags, setMilkTag, setMilkTags)) }} onChange={ (e)=> { setMilkTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          milkTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, milkTags , setMilkTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex text-right'>ถั่วที่มีเปลือกแข็ง</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={beanTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, beanTags, setBeanTag, setBeanTags)) }} onChange={ (e)=> { setBeanTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          beanTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, beanTags , setBeanTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  

                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>ซัลไฟต์</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={sulphiteTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, sulphiteTags, setSulphiteTag, setSulphiteTags)) }} onChange={ (e)=> { setSulphiteTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          sulphiteTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, sulphiteTags , setSulphiteTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  


                  <div className='w-full flex flex-wrap mt-6 text-sm'>
                    <div className='w-full flex md:w-1/4 justify-end pr-3'>
                      <span className='mt-2 flex'>อื่น ๆ</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={otherLoseTag} onKeyPress={(e)=>{ (handleKeyPressTag(e, otherLoseTags, setOtherLoseTag, setOtherLoseTags)) }} onChange={ (e)=> { setOtherLoseTag(onChangeText(e)) } }  type="search"  className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      <div className='w-full flex flex-wrap mt-1'>
                        
                        {
                          otherLoseTags.map(function(item, index){
                            return(
                              <div className='bg-green-600 rounded-full text-xs px-2 mt-1 py-1 flex flex-wrap mr-2'>
                                <span className='mr-2'>{item.name}</span>
                                <button onClick={ (e)=> { removeTag( index, otherLoseTags , setOtherLoseTags ) }} className='my-auto text-white '>x</button>
                              </div>
                            )
                          })
                        }
              
                      </div>
                    </div>
                  </div>  


                  <div className='w-full flex flex-wrap mt-6  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>GDA</span>
                    </div>
                    <div className='w-full flex flex-wrap md:w-3/4 '>
                      <div className='w-full lg:w-1/3'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setCodeGDA( parseInt(e.currentTarget.value)) }} name="radio-code-gda" value={0}  checked={ (codeGDA === 0 )? ' checked ' : ''} defaultChecked />ไม่แสดง
                      </div>
                      <div className='w-full lg:w-1/3 pr-2 mt-2 lg:mt-0'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setCodeGDA( parseInt(e.currentTarget.value)) }} name="radio-code-gda" value={1}  checked={ (codeGDA === 1 )? ' checked ' : ''} />แสดงแบบ อย.
                      </div>
                      <div className='w-full lg:w-1/3 pr-2 mt-2 lg:mt-0'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setCodeGDA( parseInt(e.currentTarget.value)) }} name="radio-code-gda" value={2}  checked={ (codeGDA === 2 )? ' checked ' : ''} />แสดงแบบอื่น ๆ
                      </div>
                    </div>
                  </div>


                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ข้อความแนะนำ</span>
                    </div>
                    <div className='w-full flex flex-wrap   md:w-3/4'>
                      <div className='flex w-full flex-wrap '>
                        <div className='w-full md:w-1/3'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setRecommend( parseInt(e.currentTarget.value)) }} name="radio-recommend" value={0}  checked={ (recommend === 0 )? ' checked ' : ''} defaultChecked />ไม่แสดง
                        </div>
                        <div className='w-full md:w-1/3 mr-2'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setRecommend( parseInt(e.currentTarget.value)) }} name="radio-recommend" value={1}  checked={ (recommend === 1 )? ' checked ' : ''}  />แสดง
                        </div>
                      </div>

                      
                      <div className={'w-full  flex-wrap '+(((recommend === 1 )? ' flex ' : ' hidden '))}>
                        <div className='w-full mt-2'>
                          <input type={"checkbox"} onChange={ (e)=> {  setRecommendExcercise((e.target.checked)? 1 : 0); }} checked={( recommendExcercise === 1 )? ' checked ' : ''}  />
                          <span className="ml-2">บริโภคแต่น้อยและออกกำลังกายเพื่อสุขภาพ</span>
                        </div>
                        <div className='w-full mt-2'>
                          <input type={"checkbox"} onChange={ (e)=> {  setRecommend5Food((e.target.checked)? 1 : 0); }}  checked={( recommend5Food === 1 )? ' checked ' : ''}    />
                          <span className="ml-2">ควรกินอาหารหลากหลาย ครบ 5 หมู่ ในสัดส่วนที่เหมาะสมเป็นประจำ</span>
                        </div>
                    
                          
                        <div className='w-full flex flex-wrap mt-3 text-sm'>
  

                          <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/6 flex'>
                                <button onClick={ (e)=>{  addTextList( recommendOthers, recommendOther, setRecommendOthers, setRecommendOther ) }} className='bg-blue-500 py-2 px-4 text-white rounded-md'> + </button>
                              </div>
                              <div className='w-full md:w-5/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                  <input value={ recommendOther } onChange={ (e)=> { setRecommendOther(onChangeText(e)) } }  type="search" className="h-full w-full text-sm py-2 px-3" />
                                </div>
                              </div>
                          </div>
                          {

                            recommendOthers.map(function(item, index){
                              return (
                                <div className='w-full flex flex-wrap mt-2'>
                                    <div className='w-1/6 flex'>
                                      <button onClick={ (e)=>{ removeTextList(index, recommendOthers, setRecommendOthers) }} className='bg-red-500 py-2 px-4 w-9 h-9 text-white rounded-md'> - </button>
                                    </div>
                                    <div className='w-full md:w-5/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                      <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                        <input value={item.description} type={"search"} className="h-full w-full text-sm py-2 px-3" disabled />
                                      </div>
                                    </div>
                                </div>
                              )
                            })

                          }
                        </div>

                      </div>
                      
                    </div>
                  </div>

                

                  <div className='w-full flex flex-wrap mt-3  text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ข้อความกล่าวอ้าง</span>
                    </div>
                    <div className='w-full flex flex-wrap   md:w-3/4'>
                      <div className='flex w-full flex-wrap '>
                        <div className='w-full md:w-1/3'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setQuote( parseInt(e.currentTarget.value)) }} name="radio-quote" value={0}  checked={ (quote === 0 )? ' checked ' : ''} defaultChecked />ไม่แสดง
                        </div>
                        <div className='w-full md:w-1/3 mr-2'>
                          <input type={"radio"} className="mr-2" onChange={(e)=>{ setQuote( parseInt(e.currentTarget.value)) }} name="radio-quote" value={1}  checked={ (quote === 1 )? ' checked ' : ''} />แสดง
                        </div>
                      </div>



                      <div className={'w-full  flex-wrap '+(((quote === 1 )? ' flex ' : ' hidden '))}>
      
                          
                        <div className='w-full flex flex-wrap mt-3 text-sm'>
  

                          <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/6 flex'>
                                <button onClick={ (e)=>{  addTextList( quoteOthers, quoteOther, setQuoteOthers, setQuoteOther ) }} className='bg-blue-500 py-2 px-4 text-white rounded-md'> + </button>
                              </div>
                              <div className='w-full md:w-5/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                  <input value={ quoteOther } onChange={ (e)=> { setQuoteOther(onChangeText(e)) } }  type="search" className="h-full w-full text-sm py-2 px-3" />
                                </div>
                              </div>
                          </div>
                          {

                            quoteOthers.map(function(item, index){
                              return (
                                <div className='w-full flex flex-wrap mt-2'>
                                    <div className='w-1/6 flex'>
                                      <button onClick={ (e)=>{ removeTextList(index, quoteOthers, setQuoteOthers) }} className='bg-red-500 py-2 px-4 w-9 h-9 text-white rounded-md'> - </button>
                                    </div>
                                    <div className='w-full md:w-5/6 mt-2 md:mt-0 flex px-0 md:px-1'>
                                      <div className='w-full flex  text-sm rounded-md border  border-grey-500 '>
                                        <input value={item.description} type={"search"} className="h-full w-full text-sm py-2 px-3" disabled />
                                      </div>
                                    </div>
                                </div>
                              )
                            })

                          }
                        </div>

                      </div>
                    </div>
                    
                  </div>

                </div>


                <div className='flex flex-wrap w-full border-b border-gray-200 my-2'>
                  <hr/>
                </div>


                {/* Section#5 */}
                <div ref={refMade} className='flex flex-wrap w-full border-b border-gray-200 my-2 py-2'>
                  <div className='w-full flex flex-wrap mt-3 mb-6'>
                    <span className='text-lg font-bold'>  แหล่งผลิต</span>
                  </div>
                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ผลิตจาก</span>
                    </div>
                    <div className='w-full flex  md:w-3/4'>
                      <div className='w-1/3'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setMadeFrom( parseInt(e.currentTarget.value)) }} name="radio-made-from" value={0}  checked={ (madeFrom === 0 )? ' checked ' : ''} defaultChecked />ในประเทศ
                      </div>
                      <div className='w-1/3 mr-2'>
                        <input type={"radio"} className="mr-2" onChange={(e)=>{ setMadeFrom( parseInt(e.currentTarget.value)) }} name="radio-made-from" value={1}  checked={ (madeFrom === 1 )? ' checked ' : ''} />ต่างประเทศ
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ผู้ผลิต</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ manufacturer } onChange={ (e)=> { setManufacturer(onChangeText(e)) } }  type="search" className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((manufacturer === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ผู้นำเข้า</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ importer } onChange={ (e)=> { setImporter(onChangeText(e)) } }  type="search"  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((importer === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>

                  <div className='w-full flex flex-wrap mt-3 text-sm'>
                    <div className='w-full md:w-1/4'>
                      <span className='font-bold'>ผู้จัดจำหน่าย</span>
                    </div>
                    <div className='w-full  md:w-3/4'>
                      <input value={ distributor } onChange={ (e)=> { setDistributor(onChangeText(e)) } }  type="search"  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md "+((distributor === "")? " border-grey-500 " : " border-green-600 border-2 ")} />
                    </div>
                  </div>


                </div>
                



                {/* Section#6 */}
                {
                  (revisionList !== null && revisionList.length > 0)?
                      <div ref={refHistory} className='flex flex-wrap w-full bg-white px-3 lg:px-6 mt-6'>
                        <div className='w-full flex flex-wrap mt-3 mb-6'>
                          <span className='text-lg font-bold'>  ประวัติการปรับปรุงข้อมูล version {productRevision}</span>
                        </div>
                        <div className='w-full flex flex-wrap mt-1 mb-6'>
                          <DataTable
                            columns={columns}
                            data={ productHistory }
                          />
                        </div>
                      </div>
                  :
                  null


                }
                


              
                <div className='flex flex-wrap w-full px-2'>

                  {
                    (props.is_new === false)?

                    <div className='w-full flex flex-wrap mt-3'>
                      <div className="w-full lg:w-1/2 flex">
                        <button onClick={showConfirmAddToNewRevision} className=' w-full lg:w-fit bg-orange-600 rounded-lg text-white my-4 py-2 px-0 lg:px-4'>สร้าง version ผลิตภัณฑ์ใหม่</button>
                      </div>
                      <div className="w-full lg:w-1/2 flex justify-end">
                        <button onClick={showConfirmUpdate} className='w-full  lg:w-fit bg-green-600 rounded-lg text-white my-4 py-2 px-0 lg:px-4'>บันทึกข้อมูล</button>
                      </div>
                    
                    </div>
                    :

                    <div className='w-full flex flex-wrap mt-3'>
                        <div className="w-full lg:w-1/2 flex">
                        </div>
                        <div className="w-full lg:w-1/2 flex justify-end">
                          <button onClick={showConfirmNew} className='w-full  lg:w-fit bg-green-600 rounded-lg text-white my-4 py-2 px-0 lg:px-4'>บันทึกข้อมูลใหม่</button>
                        </div>
                      

                    </div>
                  }



                </div>
          </div>                  
      </div>

    </div>
  )

}

export default EditProductPage;