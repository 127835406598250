import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function SlidingMenu( props ) {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)


  const [ summary, setSummary ] = useState(props.data)

  const onSelectMenu = props.onSelectMenu

  useEffect(() => {
    setSummary(props.data)
    getDefaultMenuId()
  })

  function gotoMenu( id ){
    localStorage.setItem('foodchoice_menu_id', id)
    onSelectMenu(id)
  }


  function getDefaultMenuId(){
    const prevMenu = localStorage.getItem('foodchoice_menu_id')
    console.log("prevMenu: "+prevMenu)
    if (prevMenu !== undefined && prevMenu !== null && prevMenu !== '' ) {
    } else {
      localStorage.setItem('foodchoice_menu_id', 1)
    }
  }

  return (
      <div className="antialiased   flex-wrap  font-sarabun w-full  text-sm" >
        <div className='w-full bg-blue-500 flex p-2 py-4 rounded-t-lg border border-blue-500'>
          <span>เมนู</span>
        </div>
        <div onClick={ ()=> { gotoMenu(1) } } className=' cursor-pointer w-full flex p-2 border-b bg-white border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>จำนวนทั้งหมดในระบบ</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
            <span className='text-xs'>{summary.total_all}</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(2) } } className=' cursor-pointer w-full flex p-2 bg-white border-b border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>ข้อมูลที่แสดงผล</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
          <span className='text-xs'>{summary.total_show}</span>
          </div>
        </div>

        <div onClick={ ()=> { gotoMenu(4) } } className=' cursor-pointer w-full flex p-2  bg-white  border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>ข้อมูลที่ลบแล้ว</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
          <span className='text-xs'>{summary.total_not_show}</span>
          </div>
        </div>

        <div className='w-full flex px-2 py-3 bg-blue-400  border-b border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>ส่วนประกอบ</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(7) } } className=' cursor-pointer w-full flex px-2 py-3  bg-white  border-b border-b-gray-30'>
          <div className='flex-grow w-full my-auto'>
            <span>จัดกลุ่มแล้ว</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
            <span className='text-xs'>{summary.total_component_grouping}</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(8) } } className=' cursor-pointer w-full flex px-2 py-3  bg-white  border-b border-b-gray-30'>
          <div className='flex-grow w-full my-auto'>
            <span>ยังไม่ได้จัดกลุ่ม</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
            <span className='text-xs'>{summary.total_component_not_grouping}</span>
          </div>
        </div>

        <div className='w-full flex px-2 py-3 bg-blue-400  border-b border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>Food choice</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(5) } } className=' cursor-pointer w-full flex px-2 py-3  bg-white  border-b border-b-gray-30'>
          <div className='flex-grow w-full my-auto'>
            <span>รายการผลิตภัณฑ์</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(6) } } className=' cursor-pointer w-full flex px-2 py-3   bg-white border-b  border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>Scan ผลิตภัณฑ์</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(101) } } className=' cursor-pointer w-full flex p-2  bg-white border-b border-b-gray-300'>
          <div className='flex-grow w-full my-auto'>
            <span>รายงานผลิตภัณฑ์ใหม่</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-auto'>
          <span className='text-xs'>{summary.total_new_product}</span>
          </div>
        </div>
        <div onClick={ ()=> { gotoMenu(102) } } className=' cursor-pointer w-full flex p-2  pb-6 bg-white border-b rounded-b-lg'>
          <div className='flex-grow w-full my-auto'>
            <span>รายงานผลิตภัณฑ์พบปัญหา</span>
          </div>
          <div className='flex-none bg-gray-200 rounded-full px-2 py-1 my-autog '>
          <span className='text-xs'>{summary.total_problem_product}</span>
          </div>
        </div>
        
      </div>
  )
}

export default SlidingMenu;