import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'


function ComponentEditPage() {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)

  const [ name, setName ] = useState("")
  const [ componentGroup, setComponentGroup ] = useState([])
  const [ groupFood, setGroupFood ] = useState([])

  const [ itemSelected, setItemSelected ] = useState([])
  


  useEffect(() => {
    window.scrollTo(0, 0)
    callGetDetailApi(state.id)
  }, [])

  useEffect(() => {
    callGetFoodGroupApi()
  }, [ componentGroup ])


  function gotoHome( id ){
    navigate("/")
  }

  

  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function validateTextNoSpace( event ){
    return (!/[.A-Za-z@0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function onChangeTextNoSpace( event ){
    if ( validateTextNoSpace( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }






  function callGetDetailApi( id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "id": id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getComponentById.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetDetailApi(data), (error) => {

    });
  
  }

  function validateGetDetailApi (data ){
    if(data.data.result !== undefined){
      const result = data.data.result
      setName(result.component.description)
      setComponentGroup(result.group_food)

  
    }
  } 



  function callGetFoodGroupApi(  ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getComponentGroup.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetFoodGroupApi(data), (error) => {

    });
  
  }

  function validateGetFoodGroupApi (data ){
    if(data.data.result !== undefined){
      const result = data.data.result
      setGroupFood(result)
      let r = [];
      for(let i = 0; i < result.length; i++){
        console.log("isgroup:")
        console.log((result[i].description))
        console.log(isGroup(result[i].id))
        r.push(isGroup(result[i].id))
      }
      setItemSelected(r)
    }
  } 



  function isGroup( id ){
    if(componentGroup === null){
      return false
    }
    for(let i = 0; i < componentGroup.length; i++){
      if(componentGroup[i].id === id){
        return true
      }
    }
    return false
  }

  function callUpdateFoodGroupApi(  ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          component_id : state.id,
          groups: itemSelected
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateComponentGroup.php', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateApi(data), (error) => {

    });
  
  }

  function validateUpdateApi(data ){
   gotoHome()
  }



  return (
      <div className="antialiased h-full min-h-screen  flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

          <div className=" w-full flex flex-wrap   ">
            <div className=" w-full  flex m-4  justify-center">
            
              <div className='w-fit flex-wrap'>
                <div className='w-full md:w-[600px] max-w-[1440px] bg-white flex flex-wrap mt-3 text-sm  py-8 px-4 rounded-lg'>
                      <div className='w-full flex my-4 justify-center'>
                        <span className='font-bold text-xl'>แก้ไขข้อมูลส่วนประกอบ</span>
                      </div>
                      
                      <div className='w-full flex'>
                        <span className='font-bold'>ชื่อส่วนประกอบ*</span>
                      </div>
                      <div className='w-full flex mt-2'>
                        <input disabled onChange={(e)=>{ setName(onChangeText(e)) }} value={ name }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                      </div>


                      <div className='w-full flex mt-4'>
                        <span className='font-bold'>กลุ่ม</span>
                      </div>
                      <div className='w-full flex mt-2'>
                        <div className='w-full flex-wrap flex'>

                          {
                            groupFood.map(function(item, index){
                              return (
                                <div className='w-1/4  flex-wrap py-4 px-4'>
                                  <input 
                                  onChange={ (e)=>{  
                                    console.log(index+":"+e.target.checked)
                                    let newItem = [...itemSelected];
                                    newItem[index] = e.target.checked
                                    setItemSelected(newItem);
                                    console.log(newItem)
                                  }}
                                  value={itemSelected[index]} checked={ (( itemSelected[index] === true )? ' checked ' : '') } type={'checkbox'} className={'my-auto mr-4'} />
                                  <span  className={'my-auto'}>{item.description}</span>
                                </div>
                              )
                            })
                          }

                        </div>
                      </div>


                      <button
                      onClick={callUpdateFoodGroupApi}
                       className='w-full bg-green-600 rounded-lg text-white my-8 py-2 '>บันทึก</button>
                  

                </div>





              </div>


            </div>
          </div>
        </div>

      </div>
  )
}

export default ComponentEditPage;