import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'


function MessageNewPage() {
  
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const [ name, setName ] = useState("")
  const [ surname, setSurname ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ tel, setTel ] = useState("")
  const [ username, setUsername ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ rePassword, setRePassword ] = useState("")


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function gotoHome( id ){
    navigate("/user")
  }

  

  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function validateTextNoSpace( event ){
    return (!/[.A-Za-z@0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }

  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function onChangeTextNoSpace( event ){
    if ( validateTextNoSpace( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function submit(){

    if( username === "" ||  name === "" ||  password === "" ||  rePassword === "") {
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else if( password !== rePassword ){
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณายืนยันรหัสผ่านให้ตรงกัน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else if( password.length < 6){
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณากำหนดรหัสผ่านตั้งแต่ 6 ตัวอักษรขึ้นไป",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else if( username.length < 4){
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณากำหนด Username ตั้งแต่ 4 ตัวอักษรขึ้นไป",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else {
      callInsertUserApi()
    }

    

  }


  function callInsertUserApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "username": username,
          "password": password,
          "name": name,
          "surname": surname,
          "email": email,
          "tel": tel
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/insertAdmin.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertUserApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

function validateInsertUserApi(data ){
  if( data.code === 200){
    MySwal.fire({
      icon: 'warining',
      title: <p>บันทึก</p>,
      text: "บันทึกสำเร็จ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
    gotoHome()
  }
  else {
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: data.message,
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}

  return (
      <div className="antialiased h-full min-h-screen  flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex bg-gray-200">

          <div className=" w-full flex flex-wrap   ">
            <div className=" w-full flex flex-wrap m-4  justify-center">
            
              
                  <div className='w-full md:w-[600px] max-w-[1440px] bg-white flex flex-wrap mt-3 text-sm  py-8 px-4 rounded-lg'>
                    <div className='w-full flex my-4 justify-center'>
                      <span className='font-bold text-xl'>ลงทะเบียนผู้ใช้งาน</span>
                    </div>
                    
                    <div className='w-full flex'>
                      <span className='font-bold'>ชื่อ*</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setName(onChangeText(e)) }} value={ name }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>นามสกุล</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setSurname(onChangeText(e)) }} value={ surname } type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>E-mail</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setEmail(onChangeTextNoSpace(e)) }} value={ email }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>เบอร์โทรศัพท์</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setTel(onChangeText(e)) }} value={ tel }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <div className='w-full flex flex-wrap mt-4'>
                      <span className='w-full font-bold'>Username*</span>
                      <span className='w-full  text-gray-600 text-xs  mt-1'>( กำหนดตั้งแต่ 4 ตัวอักษรขึ้นไป )</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setUsername(onChangeTextNoSpace(e)) }} value={ username }  type="search" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>



                    <div className='w-full flex flex-wrap mt-4'>
                      <span className='w-full font-bold'>รหัสผ่าน*</span>
                      <span className='w-full  text-gray-600 text-xs  mt-1'>( กำหนดตั้งแต่ 6 ตัวอักษรขึ้นไป )</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setPassword(onChangeTextNoSpace(e)) }} value={ password }  type="password" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>



                    <div className='w-full flex mt-4'>
                      <span className='font-bold'>ยืนยันรหัสผ่าน*</span>
                    </div>
                    <div className='w-full flex mt-2'>
                      <input onChange={(e)=>{ setRePassword(e.target.value) }} value={ rePassword } type="password" className="border-grey-500 border w-full text-sm py-2 px-3 rounded-md" />
                    </div>

                    <button  onClick={submit} className='w-full bg-green-600 rounded-lg text-white my-8 py-2 '>บันทึก</button>
                

                  </div>

            </div>
          </div>
        </div>

      </div>
  )
}

export default MessageNewPage;