import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Header from '../pages/Header.js'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function NewsDetailPage() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const MySwal = withReactContent(Swal)

  const [ messageId, setMessageId ] = useState("")

  const [ topic, setTopic ] = useState("")
  const [ message, setMessage ] = useState("")
  const [ isShow, setIsShow ] = useState("")
  

  useEffect(() => {
    window.scrollTo(0, 0)
    setMessageId(state.id)
    fetchDetail(state.id)
  }, [])



  function fetchDetail( message_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "message_id": message_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getMessageDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
      }
    });
  
  }

  function validateGetUserApi(data) {
 
    if(data.data){
      let result = data.data.result 
      setTopic(result.topic)
      setMessage(result.content)
      setIsShow(result.is_show)
    }
  }


  function validateText( event ){
    return (!/[.A-Za-zก-ฮ-่-้-๊-๋-็ะ-ูเ-ไ-์ 0-9-]/.test(event.nativeEvent.data) && (event.nativeEvent.data !== null) && (event.nativeEvent.data !== undefined) )
  }


  function onChangeText( event ){
    if ( validateText( event )) {
      return (event.target.value).substring(0, (event.target.value).length-1)
    }
    else if( event.nativeEvent.data === undefined){
      return ""
    }
    else{
      return event.target.value
    }
  }


  function gotoHome( id ){
    navigate("/news/main")
  }



  function submit(){

    if( topic === "" ||  message === "" ) {
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else {
      callUpdateUserApi()
    }

    

  }


  function callUpdateUserApi(  ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          "message_id": messageId,
          "topic": topic,
          "content": message,
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateMessage.php', requestOptions)
    .then(response => response.json())
    .then(data => validateUpdateUserApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }

function validateUpdateUserApi(data ){
  if( data.code === 200){
    MySwal.fire({
      icon: 'warining',
      title: <p>บันทึก</p>,
      text: "บันทึกสำเร็จ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
    gotoHome()
  }
  else {
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: data.message,
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}
  
function gotoHome( id ){
  navigate("/news/main")
}
const gotoChallenge = () => {
  navigate("/challenge/main")
}


  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button  onClick={gotoChallenge}  className='button-non-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button className='button-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6'>
            <div className='w-fit flex'>
              <span className=' text-foodchoice-red-500 text-3xl font-bold'>ข่าวประชาสัมพันธ์</span>
            </div>
            <div className='w-fit flex pl-6 items-end'>
              <span onClick={gotoHome} className=' text-black text-lg'>หน้าหลัก / แก้ไขข่าว </span>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6  '>
            <div className='w-full flex flex-wrap'>
              <span className=' w-full text-black text-lg'> แบบฟอร์มการแก้ไข ข่าว</span>
            </div>
            <div className='w-full flex flex-wrap mt-2 '>
                <div className='w-full flex  flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>
                  <span className=' text-foodchoice-gray-500 '>หัวข้อข่าว *</span>
                  <div className='w-full flex flex-wrap'>
                    <input 
                    onChange={(e)=>{ setTopic(onChangeText(e)) }} value={ topic } 
                    type={"search"} 
                    className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                  </div>

                  <span className=' text-foodchoice-gray-500 mt-6 '>เนื้อข่าว *</span>
                  <div className='w-full flex flex-wrap'>
                    <textarea type={"search"} 
                    rows={5}
                    onChange={(e)=>{ setMessage(onChangeText(e)) }} value={ message }
                    className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                  </div>
                </div>
                <div className='w-full flex flex-wrap mt-6 '>
                  <div className='w-full  flex flex-wrap px-2'>
                    <div className='w-fit flex'>
                      <button
                      onClick={submit}
                      className='w-full button-violet flex flex-wrap'>
                        <div className='flex items-center my-auto'>
                          <span className='  text-white text-xl font-medium px-12'>บันทึก</span>
                        </div>
                      </button>
                    </div>
                    <div className='w-fit flex ml-5'>
                      <button
                       onClick={gotoHome} 
                      className='w-full button-orange flex flex-wrap'>
                        <div className='flex items-center my-auto'>
                          <span className='  text-white text-xl font-medium px-12'>ยกเลิก</span>
                        </div>
                      </button>
                    </div>'
                  </div>
                </div>
            </div>

          </div>
         
        </div>

      </div>
  )
}

export default NewsDetailPage;