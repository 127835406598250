import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



import DataTable from 'react-data-table-component';

function FoodChoiceList(props) {


  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)
  const [ isResetTable, setIsResetTable ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ word, setWord ] = useState("")

  const [ menuId, setMenuId ] = useState(props.menu)

  const [ conditionId, setConditionId ] = useState("")
  const [ criteraiId, setCriteraiId ] = useState("")
  const [ scoreId, setScoreId ] = useState("0")

  const [ crustaceans, setCrustaceans ] = useState(false)
  const [ eggs, setEggs ] = useState(false)
  const [ fish, setFish ] = useState(false)
  const [ gluten, setGluten ] = useState(false)
  const [ milk, setMilk ] = useState(false)
  const [ nut, setNut ] = useState(false)
  const [ sulfites, setSulfites ] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setMenuId(props.menu)
    handlerCallGetProductApiByMenu( props.menu, 1, 10 )
  }, [ props.menu ])
  

  

  const handlePageChange = page => {
		setPage(page);
    if(word.length >= 3){
      handlerCallSearchProductApiByMenu(menuId, word, page, perPage )
    }
    else if( word.length === 0 ){
      handlerCallGetProductApiByMenu( menuId, page, perPage )
    }
	};

  const handlePerRowsChange = async (newPerPage, page) => {
		setPage(page)
		setPerPage(newPerPage)
    if(word.length >= 3){
      handlerCallSearchProductApiByMenu(menuId, word, page, newPerPage )
    }
    else if( word.length === 0 ){
      handlerCallGetProductApiByMenu( menuId, page, newPerPage )
    }

	};


  const columns = [
    {
        name: 'Barcode',
        selector: row => row.barcode_id,
        wrap : true
    },
    {
        name: 'ยี่ห้อ',
        selector: row => row.brand,
        wrap : true
    },
    {
        name: 'ชื่อ',
        selector: row => row.product_name,
        wrap : true
    },
    {
        name: 'กลุ่ม',
        selector: row => (row.criteria === null )? "-" : row.criteria.description,
        wrap : true
    },
    {
        name: 'คะแนน',
        selector: row => row.score,
        wrap : true
    },
    {
        name: 'เงื่อนไข',        
        selector: row => ( row.is_valid === "0")? <span className='text-xs px-2 py-1 bg-red-500 text-white'>ไม่ครบ</span> : <span className='text-xs px-4 py-1 bg-green-500 text-white'>ครบ</span>,
        wrap : true
    },
    {
        name: 'แก้ไขล่าสุด',
        selector: row => row.update_at,
        wrap : true
    },
    {
      name: '',
      cell:(row)=> <button onClick={ () => gotoDetail( row.barcode_id) } className="py-2 px-3 my-1 rounded-md bg-blue-700 text-sm text-white">...</button>,
      wrap : true
    },
  ];

  function gotoDetail( barcode ){
    navigate("/detail", {
      state : {
        barcode : barcode
      }
    })
  }



  function callGetProductsApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          page: page,
          per_page: per_page,
          "word": word,
          "is_valid": (conditionId === "")? null : conditionId,
          "criteria" : (criteraiId === "")? null : criteraiId ,
          "sorts": {
              "score": (scoreId === "0")? true : false,
              "cal": (scoreId === "1")? true : false,
              "sugar": (scoreId === "2")? true : false,
              "fat": (scoreId === "3")? true : false,
              "protein": (scoreId === "4")? true : false,
              "calcium": (scoreId === "5")? true : false,
              "sodium": (scoreId === "6")? true : false,
              "saturated_fat": (scoreId === "6")? true : false,
              "vit_b2": (scoreId === "7")? true : false,
          },
          "filters": {
              "crustaceans": crustaceans,
              "eggs": eggs,
              "fish": fish,
              "gluten": gluten,
              "milk": milk,
              "nut": nut,
              "sulfites": sulfites
          }
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getFoodChoices.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }



  function validateGetProductApi(data ){
    if( data.data.result !== undefined){
      setItems(data.data.result )
      setTotalRowAll(data.data.total)
    }
  }




function callSearchProductsApi( word, page, perPage ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        word: word,
        page: page,
        per_page: perPage
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/searchProducts.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetProductApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}



function handleKeyPress(event){
  if(event.key === 'Enter'){
    let word = event.target.value
    setWord(word)
    submit( menuId )
  }
}



function submit( menuId ){
    if(word.length >= 3){
      setIsResetTable(true)
      handlerCallSearchProductApiByMenu(menuId, word, page, perPage )
    }
    else if( word.length === 0 ){
      setIsResetTable(true)
      handlerCallGetProductApiByMenu( menuId, page, perPage )
    }
    else{
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุตั้งแต่ 3 ตัวอักษร",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
  }

  function handlerCallGetProductApiByMenu( menu_id , page, perPage){
    if( menu_id === 5){
      callGetProductsApi( page, perPage)
    }
  }

  

  function handlerCallSearchProductApiByMenu( menu_id , word, page, perPage){
    if( menu_id === 5){
      callGetProductsApi(page, perPage)
    }
  }

  


  function getMenuTitle(){
    if( menuId === 5){
      return "รายการผลิตภัณฑ์"
    }
  }

  return (
    <div className="flex flex-wrap w-full">
      <div className=" w-full  flex-wrap bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4">
          
          <div className='flex-wrap mb-2'>
            <div className='w-full flex'>
              <span className=' w-full text-lg md:text-2xl font-bold  '>{ getMenuTitle() }</span>
            </div>
          </div>
          

          <div className='flex flex-wrap w-full mb-2 mt-4'>



              <div className='flex flex-wrap w-full mt-2'>
      
                <div className="flex-wrap   w-full flex  my-auto mx-2  ">
                  <input value={word} onChange={ (e)=>{ setWord(e.target.value) }} onKeyPress={(e)=>{handleKeyPress(e)}} type={"text"} className="w-[220px] py-3 px-6 border border-gray-300 rounded-md text-sm" placeholder='ค้นหา Barcode, ชื่อ หรือ ยี่ห้อ' />
                </div>

                <div className="flex-wrap  w-1/4  flex  my-auto my-4">
                  <span className=' w-full text-xs md:text-md  '>เงื่อนไข</span>
                  <select value={conditionId} onChange={ (e) => { setConditionId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={""}>ไม่ระบุ</option>
                    <option value={"1"}>ครบ</option>
                    <option value={"0"}>ไม่ครบ</option>
                  </select>
                </div>

                <div className="flex-wrap  w-1/4  flex  my-auto my-4">
                  <span className=' w-full text-xs md:text-md  '>กลุ่มเกณฑ์กรมอนามัย</span>
                  <select value={criteraiId} onChange={ (e) => { setCriteraiId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={""}>ไม่ระบุ</option>
                    <option value={"1"}>ขนม</option>
                    <option value={"2"}>เครื่องดื่ม</option>
                    <option value={"3"}>นม</option>
                  </select>
                </div>

                <div className="flex-wrap  w-1/4  flex  my-auto my-4">
                  <span className=' w-full text-xs md:text-md  '>เรียงตาม</span>
                  <select value={scoreId} onChange={ (e) => { setScoreId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={"0"}>คะแนน</option>
                    <option value={"1"}>พลังงาน</option>
                    <option value={"2"}>น้ำตาล</option>
                    <option value={"3"}>ไขมัน</option>
                    <option value={"4"}>โปรตีน</option>
                    <option value={"5"}>แคลเซียม</option>
                    <option value={"6"}>โซเดียม</option>
                    <option value={"7"}>ไขมันอิ่มตัว</option>
                    <option value={"8"}>วิตามินบี</option>
                  </select>
                </div>

                <div className="flex-wrap  w-full  flex  my-auto my-4">
                  <span className=' w-full text-xs md:text-md  '>ข้อมูลการแพ้ ไม่มีส่วนประกอบ</span>
                  <div className='w-full flex'>
                    <input className='my-auto mr-4' type={"checkbox"} 
                        onChange={ (e)=>{
                          console.log(e.target.checked)
                          setCrustaceans(e.target.checked)
                        } } 
                    /> <span>สัตว์น้ำเปลือกแข็ง</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setEggs(e.target.checked)
                        } } 
                    /> <span>ไข่</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setFish(e.target.checked)
                        } }                     
                    /> <span>ปลา</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setGluten(e.target.checked)
                        } }                     
                    /> <span>กลูเตน</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setMilk(e.target.checked)
                        } }                     
                    /> <span>นม</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setNut(e.target.checked)
                        } }                    
                    /> <span>ถั่ว</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setSulfites(e.target.checked)
                        } }                    
                    /> <span>ซัลไฟต์</span>
                  </div>
                </div>

                <div className="flex-wrap   w-full flex  my-auto mx-2  ">
                  <button onClick={ ()=> { submit( menuId ) } }  className='bg-green-600 px-4 py-2 rounded-lg text-white'>ค้นหา</button>
                </div>



              <div className=' flex-wrap w-full mt-12'>
                <div className='flex'>
                  <span className=' w-full text-xs md:text-md  '>จำนวนผลลัพธ์</span>
                </div>
                <div className='flex'>
                  <span className=' text-sm md: text-md bg-gray-200 py-2 px-3 md:py-4 md:px-6 rounded-lg font-bold text-center'>{totalRowAll} รายการ</span>
                </div>
              </div>
              
              </div>

    
            

          </div>

          <div className=" w-full flex flex-wrap   ">
            <DataTable
              columns={columns}
              data={ items }
              pagination
              paginationServer
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationTotalRows={totalRowAll}
            />
          </div>
      </div>
    </div>
  );

}

export default FoodChoiceList;