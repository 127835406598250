import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'
import moment from 'moment';

import Header from '../pages/Header.js'
import { formatDateThaiShort, formatMonthYearThaiShort } from '../utils/thai-date-time-format.ts'

import iconBtnMember from '../images/ic_btn_member.svg';
import iconBtnEdit from '../images/ic_btn_edit.svg';
import iconBtnDel from '../images/ic_btn_delete.svg';
import iconBtnCalendar from '../images/ic_btn_calendar.svg';

function ChallengeMainPage() {
  const navigate = useNavigate();
  const [ items, setItems ] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    callGetUserApi()
  }, [])

  const gotoNews = () => {
    navigate("/news/main")
  }


  const gotoDetail = (challenge_id) => {
    navigate("/challenge/detail", {
      state : {
        challenge_id : challenge_id
      }
    })
  }

  const gotoTeamJoin = () => {
    navigate("/challenge/team/ranking")
  }

  const gotoMemberJoin = () => {
    navigate("/challenge/member")
  }


  const gotoLeaderList = (item) => {
    
    if (item.challenge_type.id === '2') {
      gotoLeaderListTeam(item.challenge_id)
    } else {
      gotoLeaderListMember(item.challenge_id)
    }
    
  }

  const gotoLeaderListMember = (challenge_id) => {
    navigate("/challenge/member", {
      state : {
        challenge_id : challenge_id
      }
    })
    
  }



  const gotoLeaderListTeam = (challenge_id) => {
    navigate("/challenge/team/ranking", {
      state : {
        challenge_id : challenge_id
      }
    })
  }




  const gotoLeaderBoard = (item) => {
    
    if (item.challenge_type.id === '2') {
      gotoLeaderBoardTeam(item.challenge_id)
    } else {
      gotoLeaderBoardMember(item.challenge_id)
    }
    
  }

  const gotoLeaderBoardMember = (challenge_id) => {
    navigate("/leaderboard/member", {
      state : {
        challenge_id : challenge_id
      }
    })
    
  }



  const gotoLeaderBoardTeam = (challenge_id) => {
    navigate("/leaderboard/team", {
      state : {
        challenge_id : challenge_id
      }
    })
  }



  const gotoCreate = () => {
    navigate("/challenge/create")
  }

  
  function callGetUserApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallenge.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    setItems(data.data.result )
  }
}


function callDeleteChallengeApi( challenge_id ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        challenge_id : challenge_id
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/deleteChallenge.php', requestOptions)
  .then(response => response.json())
  .then(data => validateDeleteChallengeApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

  
}

function validateDeleteChallengeApi(data ){
  callGetUserApi()
}


  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button className='button-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button onClick={gotoNews} className='button-non-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>


          <div className='w-full flex mt-6'>
            <div className='w-full grow flex flex-wrap'>
              <div className='w-fit flex items-end'>
                <span className=' text-foodchoice-red-500 text-3xl font-bold'>Challenge</span>
              </div>
              <div className='w-fit flex pl-6 items-end'>
                <span className=' text-black text-lg'>หน้าหลัก</span>
              </div>
            </div>
            <div className=' flex-none flex flex-wrap'>
              <div className='w-fit flex'>
                <button onClick={gotoCreate} className='w-full button-red flex flex-wrap'>
                  <div className='flex items-center my-auto'>
                    <span className='  text-white text-xl font-medium px-12'>+ สร้าง Challenge</span>
                  </div>
                </button>
              </div>
            </div>
          </div>


          <div className='w-full flex flex-wrap mt-6 py-12 '>
            {
              items.map(function(item, index) {
                return (

                  <div className='w-1/2 flex flex-wrap p-4'>
                    <div className='w-full border shadow-md border-foodchoice-yellow-500 flex flex-wrap p-4'>
                      <div className='w-full flex flex-wrap'>
                        <div className='w-fit'>
                
                            <div className='w-fit relative'>
                              <button onClick={(e) => { gotoLeaderBoard(item) }} className='button-violet'>ดู Leaderboard</button>
                            </div>
                        
                        
                        </div>
                      </div>
      
                      <div className='w-full flex'>
                          <div className='grow flex flex-wrap w-full'>
      
                            <div className='w-full flex flex-wrap mt-4'>
                              <div className='w-1/3 flex'>
                                <span className=' text-foodchoice-gray-500 '>กิจกรรม</span>
                              </div>
                              <div className='w-2/3 flex'>
                                <span className=' text-black'>{ item.name }</span>
                              </div>
                            </div>
                            <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/3 flex'>
                                <span className=' text-foodchoice-gray-500 '>ระยะเวลา</span>
                              </div>
                              <div className='w-2/3 flex'>
                                <span className=' text-black'>{ formatDateThaiShort(item.start_date) } - { formatDateThaiShort(item.end_date)  }</span>
                              </div>
                            </div>
                            <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/3 flex'>
                                <span className=' text-foodchoice-gray-500 '>ผู้จัดกิจกรรม</span>
                              </div>
                              <div className='w-2/3 flex'>
                                <span className=' text-black'>{ item.owner }</span>
                              </div>
                            </div>
                            <div className='w-full flex flex-wrap mt-2'>
                              <div className='w-1/3 flex'>
                                <span className=' text-foodchoice-gray-500 '>ประเภทการแข่งขัน</span>
                              </div>
                              <div className='w-2/3 flex'>
                                <span className=' text-black'>{ item.challenge_type.description }</span>
                              </div>
                            </div>
                          </div>
                          <div className='w-[150px]  flex-none flex-wrap '>
                            <div className='w-full flex flex-wrap'>
                              <div className='w-full flex flex-wrap rounded-lg bg-foodchoice-gray-400 p-4'>
      
                                  <div className='w-[60px] flex flex-wrap'>
                                    <div className='w-[32px] flex'>
                                      <img
                                        src={iconBtnCalendar}
                                        className="w-8 h-8 mx-2"
                                        alt=""
                                        />
                                    </div>
                                  <span className=' text-xs flex flex-wrap text-foodchoice-gray-500'>วันคงเหลือ</span>
                                </div>
      
                                <div className='flex items-center my-auto'>
                                  <span className='  text-foodchoice-red-500 text-2xl font-medium'>
                                    { 
                                      moment(item.end_date).diff(moment(), 'days') < 0? "0" : moment(item.end_date).diff(moment(), 'days')
                                    
                                    }
                                  </span>
                                </div>
                              </div>
      
                            </div>
                          </div>
                      </div>
      
      
                      <div className='w-full flex flex-wrap mt-2'>
                        <div className='w-full flex'>
                          <span className=' text-foodchoice-gray-500 '>รายละเอียดกิจกรรม</span>
                        </div>
                        <div className='w-full flex flex-wrap'>
                          <span className=' w-full flex text-black text-clip'>{ item.description }</span>
                        </div>
                      </div>
                      <div className='w-full flex flex-wrap mt-6'>
                          <div className='w-1/3 flex px-2'>
                            <div className='w-full flex'>
                              <button 
                              onClick={ (e) => { gotoLeaderList(item) }}
                              className='w-full button-yellow flex flex-wrap'>
                                <div className='w-[60px] flex flex-wrap'>
                                  <div className='w-[32px] flex'>
                                    <img
                                      src={iconBtnMember}
                                      className="w-8 h-8 mx-2"
                                      alt=""
                                      />
                                  </div>
                                  <span className=' text-xs flex flex-wrap text-foodchoice-violet-500'>ผู้เข้าร่วม</span>
                                </div>
                                <div className='flex items-center my-auto'>
                                  <span className='  text-foodchoice-red-500 text-2xl font-medium'>{ item.total_member }</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className='w-1/3 flex px-2'>
                            <div className='w-full flex'>
                              <button 
                              onClick={ (e) => { gotoDetail(item.challenge_id) }}
                              className='w-full button-orange flex flex-wrap'>
                                  <div className='w-[60px] flex  my-auto '>
                                    <div className='w-[32px] flex'>
                                      <img
                                        src={iconBtnEdit}
                                        className="w-8 h-8 mx-2"
                                        alt=""
                                        />
                                    </div>
                                  </div>
      
                                <div className='flex items-center my-auto'>
                                  <span className='   text-md font-medium'>แก้ไข</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className='w-1/3 flex px-2'>
                            <div className='w-full flex'>
                              <button 
                              onClick={ (e) => { callDeleteChallengeApi(item.challenge_id) }}
                              className='w-full button-pink flex flex-wrap'>
                                <div className='w-[60px] flex  my-auto '>
                                    <div className='w-[32px] flex'>
                                      <img
                                        src={iconBtnDel}
                                        className="w-8 h-8 mx-2"
                                        alt=""
                                        />
                                    </div>
                                  </div>
      
                                <div className='flex items-center my-auto'>
                                  <span className='   text-md font-medium'>ลบ</span>
                                </div>
                              </button>
                            </div>
                          </div>
                      </div>
                      <div className='w-full flex flex-wrap mt-6 justify-end'>
                        <span className={ ( item.is_public === '1' ? ' bg-foodchoice-green-500 ' : ' bg-foodchoice-red-500 ')+' w-6 h-6 rounded-full '}></span>
                        <span className=' text-foodchoice-gray-500 ml-4 '>{ item.is_public === '1' ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}</span>
                      </div>
                    </div>
                  </div>
                )
              })
            }

           
          </div>
         
        </div>

      </div>
  )
}

export default ChallengeMainPage;