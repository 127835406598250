import React, { useState, useEffect } from 'react';
import  { useNavigate, useSearchParams } from 'react-router-dom'


import Header from '../pages/Header.js'


import imageLeaderboard from '../images/img_leaderboard.svg';


function LeaderBoardMemberPublishPage() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();


  const [ challengeId, setChallengeId ] = useState('')
  const [ challengeToken, setChallengeToken ] = useState('')
  
  const [ topic, setTopic ] = useState('')
  const [ leaderBoardMonth, setLeaderBoardMonth ] = useState(0)

  const [ leaderBoards, setLeaderBoards ] = useState([])
  const [ myRanks, setMyRanks ] = useState(null)
  const [ isMyRankInLeader, setIsMyRankInLeader ] = useState(false)

  const [ leaderBoardsTop1, setLeaderBoardsTop1 ] = useState(null)
  const [ leaderBoardsTop2, setLeaderBoardsTop2 ] = useState(null)
  const [ leaderBoardsTop3, setLeaderBoardsTop3 ] = useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    setChallengeId(searchParams.get("code"))
    setChallengeToken(searchParams.get("token"))
    callGetUserApi(searchParams.get("code"))
    callGetLeaderApi(searchParams.get("code"), searchParams.get("token"), leaderBoardMonth)
  }, [])


  function onChangeMonthTab( is_month ) {
    setLeaderBoardMonth(is_month)
    callGetLeaderApi( challengeId, challengeToken, is_month)
  }


  function callGetUserApi( challenge_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: challenge_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallengeDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    const result = (data.data.result )
    if( result !== undefined && result !== null){
      setTopic(result.name)
     
    }
  
  }
}


  function callGetLeaderApi( challenge_id , token,  is_month){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: challenge_id,
          is_month: is_month,
          token: token,
          is_all: 0
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/app/get_challenge_rank_by_person_publish.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetLeaderApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }


  function validateGetLeaderApi(data ){
    setLeaderBoardsTop1(null)
    setLeaderBoardsTop2(null)
    setLeaderBoardsTop3(null)
    if( data.data !== undefined){
      const result = (data.data )
      if( result !== undefined && result !== null){

        let list = []
        let meinwinnder = false
        let me = null
        if(result.my_rank.length > 0){
          me = result.my_rank[0]
        }
        for(let i = 0; i < (result.top).length; i++) {
          let r  = result.top[i]

          if(me !== null) {
            if(me.users_id === r.users_id) {
              meinwinnder = true
            }
          }

            if(i >= 3){
              list.push(r)
            } else {
              if ( i === 0) {
                setLeaderBoardsTop1(r)
              }
              else if ( i === 1) {
                setLeaderBoardsTop2(r)
              }
              else if ( i === 2) {
                setLeaderBoardsTop3(r)
              }
            }
          
        }
        setMyRanks(me)
        setIsMyRankInLeader(meinwinnder)
        setLeaderBoards(list)
      }
    
    }
  }
  

  const getUIStateRanking = (item) => {
    if(   isMyRankInLeader === true  && myRanks !== null) {

    } else if ( isMyRankInLeader === false && myRanks !== null) {

    }
  }

  return (
      <div className="antialiased h-full min-h-screen    bg-white font-sarabun w-full" >

       <div className=' w-full justify-center'>
          <div className=' w-[660px] flex flex-wrap justify-center '>
            <span className=' w-full text-center text-black text-md mt-10'>{ topic }</span>
            <div className=' mt-4 w-[500px] flex flex-wrap py-2 px-4 rounded-3xl bg-foodchoice-violet-500'>
              <div 
              onClick={ (e) => { onChangeMonthTab(0) }}
              className={ (leaderBoardMonth === 0 ? '  bg-foodchoice-violet-100 ' : '  bg-foodchoice-violet-500 cursor-pointer ')+' w-1/2  flex flex-wrap rounded-2xl py-2 px-4'}>

                <span className=' w-full text-center text-white text-md '>วันนี้</span>
              </div>
              <div 
              onClick={ (e) => { onChangeMonthTab(1) }}
              
              className={(leaderBoardMonth === 1 ? '  bg-foodchoice-violet-100 ' : '  bg-foodchoice-violet-500 cursor-pointer ')+' w-1/2 flex flex-wrap rounded-2xl py-2 px-4'}>
                <span className=' w-full text-center text-white text-md '>เดือนนี้</span>
              </div>
            </div>
          </div>
          <div className=' w-[660px] relative flex-wrap mt-6 '>
            <div className=' relative flex'>
              <img
                src={imageLeaderboard}
                className=""
                alt=""
                />
            </div>
            {
              leaderBoardsTop1 !== null ?
                <span className=' w-[140px] text-center absolute top-[75px] text-white left-[260px]'>{ leaderBoardsTop1.display_name }</span>
              :                 <span className=' w-[140px] text-center absolute top-[75px] text-white left-[260px]'></span>
            }
            {
              leaderBoardsTop1 !== null ?
              <span className=' w-[60px] text-center absolute top-[104px] text-foodchoice-violet-600 left-[300px]'>{ leaderBoardsTop1.total }</span>
              :
              <span className=' w-[60px] text-center absolute top-[104px] text-foodchoice-violet-600 left-[300px]'></span>
            }

            {    
              leaderBoardsTop2 !== null ?
              <span className=' w-[120px]  text-center absolute top-[107px] text-white left-[160px]'>{ leaderBoardsTop2.display_name }</span>
              :               <span className=' w-[120px]  text-center absolute top-[107px] text-white left-[160px]'></span>
              
            }
            {    
              leaderBoardsTop2 !== null ?
                <span className=' w-[60px] text-center absolute top-[135px] text-foodchoice-violet-600 left-[200px]'>{ leaderBoardsTop2.total }</span>
              : <span className=' w-[60px] text-center absolute top-[135px] text-foodchoice-violet-600 left-[200px]'></span>
            }


            {    
              leaderBoardsTop3 !== null ?
              <span className=' w-[100px] text-center absolute top-[125px] text-white left-[380px]'>{ leaderBoardsTop3.display_name }</span>
              :               <span className=' w-[100px] text-center absolute top-[125px] text-white left-[380px]'></span>
              
            }
            {    
              leaderBoardsTop3 !== null ?
              <span className=' w-[60px] text-center absolute top-[155px] text-foodchoice-violet-600 left-[400px]'>{ leaderBoardsTop3.total }</span>
              :               <span className=' w-[60px] text-center absolute top-[155px] text-foodchoice-violet-600 left-[400px]'></span>
            }



            

          </div>
          <div className=' w-[660px] flex bg-foodchoice-violet-200 p-4 flex-wrap '>
            {
              isMyRankInLeader === false && myRanks !== null ?
              <div className=' w-full flex bg-orange-400  rounded-lg mb-4'>
                <div className=' grow w-full flex flex-wrap'>
                  <div className='w-[60px] flex flex-wrap  py-2 pl-2'>
                    <span className=' flex border  bg-orange-400 px-2 rounded-full text-foodchoice-violet-600'>{ myRanks.ranking }</span>
                  </div>
                  <div className='w-fit flex flex-wrap items-center py-2'>
                    <span className=' flex h-4 w-4 bg-foodchoice-violet-600 rounded-full ring-2 ring-foodchoice-violet-300 '></span>
                    <span className=' flex text-white ml-2'>คุณ</span>
                  </div>
                </div>
                <div className=' flex-none  flex flex-wrap  p-2'>
                  <span className=' flex text-white ml-2 text-right'>{myRanks.total} คะแนน</span>
                </div>
              </div>
              :
              null
            }
            {
              leaderBoards.map(function(item, index) {
                return (
                  
                    myRanks !== null && myRanks.users_id === item.users_id ?

                    <div className=' w-full flex bg-foodchoice-orange-400  rounded-lg mb-4'>
                      <div className=' grow w-full flex flex-wrap'>
                        <div className='w-[60px] flex flex-wrap  py-2 pl-2'>
                          <span className=' flex border  bg-white px-2 rounded-full text-foodchoice-violet-600'>{ item.ranking }</span>
                        </div>
                        <div className='w-fit flex flex-wrap items-center py-2'>
                          <span className=' flex h-4 w-4 bg-foodchoice-violet-600 rounded-full ring-2 ring-foodchoice-violet-300 '></span>
                          <span className=' flex text-white ml-2'>คุณ</span>
                        </div>
                      </div>
                      <div className=' flex-none  flex flex-wrap  p-2'>
                        <span className=' flex text-white ml-2 text-right'>{item.total} คะแนน</span>
                      </div>
                    </div>
                    :

                      <div className=' w-full flex bg-white  rounded-lg mb-4'>
                      <div className=' grow w-full flex flex-wrap'>
                        <div className='w-[60px] flex flex-wrap  py-2 pl-2'>
                          <span className=' flex border  bg-white px-2 rounded-full text-foodchoice-violet-600'>{ item.ranking }</span>
                        </div>
                        <div className='w-fit flex flex-wrap items-center py-2'>
                          <span className=' flex h-4 w-4 bg-foodchoice-violet-600 rounded-full ring-2 ring-foodchoice-violet-300 '></span>
                          <span className=' flex text-foodchoice-violet-600 ml-2'>{ item.display_name }</span>
                        </div>
                      </div>
                      <div className=' flex-none  flex flex-wrap  p-2'>
                        <span className=' flex text-foodchoice-gray-500ml-2 text-right'>{item.total} คะแนน</span>
                      </div>
                    </div>
                  


                )
              })
            }

            
          </div>
       </div>

      </div>
  )
}

export default LeaderBoardMemberPublishPage;