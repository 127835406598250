import React, { useState } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import logoApp from '../images/logo.png'

function LoginPage() {
  
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)
  const [ username, setUsername ] = useState("")
  const [ password, setPassword] = useState("")

  function gotoMain(){
    navigate("/")
  }




function callLoginApi( ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        username: username,
        password: password
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/authAdmin.php', requestOptions)
  .then(response => response.json())
  .then(data => validateLoginApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}

function storedToLocal( token, username ){
  localStorage.setItem("foodchoice_token", token)
  localStorage.setItem("foodchoice_username", username)
}

function validateLoginApi(data ){
  if( data.code !== undefined && data.code === 200){
    storedToLocal(data.data.result.token, data.data.result.username)
    gotoMain()
  }
  else{
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: "ไม่พบ User ในระบบ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}

  function submit(){
    if(username === "" || password === ""){
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else{
      callLoginApi()
    }
  }

  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sarabun w-full" >

        <div class="w-full flex bg-blue-700 py-4">
          <span className="text-white font-bold ml-3">FoodChoice</span>
        </div>

        <div className="mx-auto w-10/12 lg:w-8/12 xl:w-6/12  flex flex-wrap bg-white rounded-xl  my-12">
          <div className="w-full sm:w-12/12 flex flex-wrap pl-5 pb-12 pr-5">
            <div className="w-full flex flex-wrap mt-12">
              <img alt="" src={logoApp} className="h-20" />
            </div>
            <span className="w-full font-bold text-3xl mt-5">เข้าสู่ระบบ</span>
            <span className="w-full text-gray-500  text-lg  mt-2">ระบบ Backoffice ของ FoodChoice</span>


            <span className="w-full font-bold text-lg mt-5">Username</span>
            <input value={username} onChange={(e)=>{ setUsername(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="text"  />

            <span className="w-full font-bold text-lg' mt-5">Password</span>
            <input value={password} onChange={(e)=>{ setPassword(e.target.value)}} className="w-full border rounded-md px-1 py-2 content-end" type="password"  />
          
            <button onClick={submit} className={"w-full flex text-white bg-green-500 justify-center py-2 rounded-lg mt-5"} >เข้าสู่ระบบ</button>
          

          </div>

    
        </div>


      </div>
  )
}

export default LoginPage;