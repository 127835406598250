import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Header from '../pages/Header.js'
import { formatDateThaiFull } from '../utils/thai-date-time-format.ts'

import iconDel from '../images/ic_delete_small.svg';
import iconEdit from '../images/ic_edit_small.svg';

function NewsMainPage() {
  const navigate = useNavigate();

  const [ items, setItems ] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    callGetUserApi()
  }, [])



  const gotoCreate = () => {
    navigate("/news/create")
  }

  const gotoEdit = (item) => {
    navigate("/news/detail", {
      state : {
        id : item.message_id
      }
    })
  }
  
  function callGetUserApi( page, per_page ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getMessage.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    setItems(data.data.result )
  }
}




function callDeleteMessageApi( item ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        "message_id": item.message_id,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/deleteMessage.php', requestOptions)
  .then(response => response.json())
  .then(data => validateDeleteMessageApi(data), (error) => {
    if (error) {
      // handle error here
    
    }
  });

}

function validateDeleteMessageApi(data ){
  callGetUserApi()
}


const gotoChallenge = () => {
  navigate("/challenge/main")
}


  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button onClick={gotoChallenge} className='button-non-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button className='button-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>

          <div className='w-full flex mt-6'>
            <div className='w-full grow flex flex-wrap'>
              <div className='w-fit flex items-end'>
                <span className=' text-foodchoice-red-500 text-3xl font-bold'>ข่าวประชาสัมพันธ์</span>
              </div>
              <div className='w-fit flex pl-6 items-end'>
                <span className=' text-black text-lg'>หน้าหลัก</span>
              </div>
            </div>
            <div className=' flex-none flex flex-wrap'>
              <div className='w-fit flex'>
                <button onClick={gotoCreate} className='w-full button-red flex flex-wrap'>
                  <div className='flex items-center my-auto'>
                    <span className='  text-white text-xl font-medium px-12'>+ สร้างข่าว</span>
                  </div>
                </button>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6  '>
            <div className='w-full flex flex-wrap'>
              <span className=' w-full text-black text-lg'> แบบฟอร์มการสร้าง ข่าว</span>
            </div>
            <div className='w-full flex flex-wrap mt-2 '>
              {
                items.map(function(item , index){
                  return (

                    <div className='w-full flex rounded-lg   border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 mb-4 '>
                    <div className=' grow w-full flex flex-wrap'>
                      <span className=' w-[200px] text-foodchoice-gray-500 text-md'>{ formatDateThaiFull(item.create_at) }</span>
                      <span className=' w-fit text-black text-md'>{ item.content }</span>
                    </div>
                    <div className=' flex-none  flex flex-wrap'>
                      <div 
                      onClick={ (e) => { gotoEdit(item) }}
                      className=' flex flex-wrap'>
                        <div className='w-fit flex my-auto'>
                          <img
                            src={iconEdit}
                            className="w-6 h-6"
                            alt=""
                            />
                        </div>
                        <span className=' w-fit text-foodchoice-gray-500 text-md ml-2'>แก้ไข</span>
                      </div>
                      <div 
                      onClick={(e) => {  callDeleteMessageApi(item) }}
                      className=' flex flex-wrap ml-4'>
                        <div className='w-fit flex my-auto'>
                          <img
                            src={iconDel}
                            className="w-6 h-6"
                            alt=""
                            />
                        </div>
                        <span className=' w-fit text-foodchoice-gray-500 text-md ml-2'>ลบ</span>
                      </div>
                    </div>
                  </div>
                  )
                })
              }
            </div>

          </div>
         
        </div>

      </div>
  )
}

export default NewsMainPage;