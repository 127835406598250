import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



import DataTable from 'react-data-table-component';

import iconGroup1 from '../images/ic_group_1.svg';
import iconGroup2 from '../images/ic_group_2.svg';
import iconGroup3 from '../images/ic_group_3.svg';
import iconGroup4 from '../images/ic_group_4.svg';
import iconGroup5 from '../images/ic_group_5.svg';

function ScanFoodChoice(props) {


  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)

  const [ page, setPage ] = useState(1)
  const [ perPage, setPerPage ] = useState(10)
  const [ totalRowAll, setTotalRowAll ] = useState(10)
  const [ isResetTable, setIsResetTable ] = useState(false)
  const [ items, setItems ] = useState([])
  const [ word, setWord ] = useState("")

  const [ menuId, setMenuId ] = useState(props.menu)

  const [ conditionId, setConditionId ] = useState("")
  const [ criteraiId, setCriteraiId ] = useState("")
  const [ scoreId, setScoreId ] = useState("0")

  const [ crustaceans, setCrustaceans ] = useState(false)
  const [ eggs, setEggs ] = useState(false)
  const [ fish, setFish ] = useState(false)
  const [ gluten, setGluten ] = useState(false)
  const [ milk, setMilk ] = useState(false)
  const [ nut, setNut ] = useState(false)
  const [ sulfites, setSulfites ] = useState(false)

  const [ foodchoiceData, setFoodchoiceData ] = useState(null)
  const [ foodchoiceRelationshipData, setFoodchoiceRelationshipData ] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    setMenuId(props.menu)
  }, [ props.menu ])
  

  


function callGetFoodChoiceApi(barcode){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        barcode: barcode,
        "filters": {
            "crustaceans": crustaceans,
            "eggs": eggs,
            "fish": fish,
            "gluten": gluten,
            "milk": milk,
            "nut": nut,
            "sulfites": sulfites
        }
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/app/product.php', requestOptions)
  .then(response => response.json())
  .then(data => validateGetFoodChoiceApi(data), (error) => {
    if (error) {
      // handle error here
    
    }
  });

}

function validateGetFoodChoiceApi( data ){
  if( data.data.result !== undefined){
    setFoodchoiceData(data.data.result)
    setFoodchoiceRelationshipData([])
    callGetProductsApi( 1, true )
  }
}



  function callGetProductsApi( page, isClear ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          page: page,
          barcode: word,
          "is_valid": null,
          "criteria" : null ,
          "sorts": {
              "score": (scoreId === "0")? true : false,
              "cal": (scoreId === "1")? true : false,
              "sugar": (scoreId === "2")? true : false,
              "fat": (scoreId === "3")? true : false,
              "protein": (scoreId === "4")? true : false,
              "calcium": (scoreId === "5")? true : false,
              "sodium": (scoreId === "6")? true : false,
              "saturated_fat": (scoreId === "6")? true : false,
              "vit_b2": (scoreId === "7")? true : false,
          },
          "filters": {
              "crustaceans": crustaceans,
              "eggs": eggs,
              "fish": fish,
              "gluten": gluten,
              "milk": milk,
              "nut": nut,
              "sulfites": sulfites
          }
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/app/get_relation_products.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetProductApi(data, isClear), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }



  function validateGetProductApi(data, isClear ){
    if( data.data.result !== undefined){

      let newList = [...foodchoiceRelationshipData]
      if(isClear){
        newList = data.data.result
      }
      else{
        newList = [...foodchoiceRelationshipData, ...data.data.result]
      }
      

      setFoodchoiceRelationshipData(newList)
      setTotalRowAll(data.data.total)
    }
  }





function handleKeyPress(event){
  if(event.key === 'Enter'){
    let word = event.target.value
    setWord(word)
    submit( menuId )
  }
}

function getComponentGroupsImages(group_id) {
  let result = null
  if(group_id === 3){
    result = iconGroup1
  } else if(group_id === 4){
    result = iconGroup2
  } else if(group_id === 5){
    result = iconGroup3
  } else if(group_id === 1){
    result = iconGroup4
  } else if(group_id === 2){
    result = iconGroup5
  }
  if(result !== null){
    return (
      <img
      src={result}
      className="w-10 h-10 mx-2"
      alt=""
      />
    )
  }
  return null
}

function submit( menuId ){
    if(word.length >= 3){
      setIsResetTable(true)
      callGetFoodChoiceApi( word  )
    }
    else{
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูล",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
  }


  function gotoNextPage( page ){

    setPage(page)
    callGetProductsApi(page ,false)
  }

  function getComponentGroup(data){
    let description = "";
    for(let i = 0; i < data.length; i++) {
      description += data[i].group_name+" "
    }
    return description
  }

  return (
    <div className="flex flex-wrap w-full">
      <div className=" w-full  flex-wrap bg-white mx-1  md:m-4 rounded-lg py-6 px-2 md:px-4">
          
          <div className='flex-wrap mb-2'>
            <div className='w-full flex'>
              <span className=' w-full text-lg md:text-2xl font-bold  '>Scan ผลิตภัณฑ์</span>
            </div>
          </div>
          

          <div className='flex flex-wrap w-full mb-2 mt-4'>



              <div className='flex flex-wrap w-full mt-2'>
      
                <div className="flex-wrap   w-full flex  my-auto mx-2  ">
                  <input value={word} onChange={ (e)=>{ setWord(e.target.value) }} onKeyPress={(e)=>{handleKeyPress(e)}} type={"text"} className="w-[220px] py-3 px-6 border border-gray-300 rounded-md text-sm" placeholder='ค้นหา Barcode' />
                </div>

                <div className="flex-wrap  w-1/4  hidden  my-4">
                  <span className=' w-full text-xs md:text-md  '>เงื่อนไข</span>
                  <select value={conditionId} onChange={ (e) => { setConditionId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={""}>ไม่ระบุ</option>
                    <option value={"1"}>ครบ</option>
                    <option value={"0"}>ไม่ครบ</option>
                  </select>
                </div>

                <div className="hidden flex-wrap  w-1/4    my-4">
                  <span className=' w-full text-xs md:text-md  '>กลุ่มเกณฑ์กรมอนามัย</span>
                  <select value={criteraiId} onChange={ (e) => { setCriteraiId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={""}>ไม่ระบุ</option>
                    <option value={"1"}>นม</option>
                    <option value={"2"}>เครื่องดื่ม</option>
                    <option value={"3"}>ขนม</option>
                  </select>
                </div>

                <div className="flex-wrap  w-1/4  flex  my-4">
                  <span className=' w-full text-xs md:text-md  '>เรียงตาม</span>
                  <select value={scoreId} onChange={ (e) => { setScoreId(e.target.value) }} className=' py-3 px-6 border border-gray-300 rounded-md text-sm'>
                    <option value={"0"}>คะแนน</option>
                    <option value={"1"}>พลังงาน</option>
                    <option value={"2"}>น้ำตาล</option>
                    <option value={"3"}>ไขมัน</option>
                    <option value={"4"}>โปรตีน</option>
                    <option value={"5"}>แคลเซียม</option>
                    <option value={"6"}>โซเดียม</option>
                    <option value={"7"}>ไขมันอิ่มตัว</option>
                    <option value={"8"}>วิตามินบี</option>
                  </select>
                </div>

                <div className="flex-wrap  w-full  flex  my-4">
                  <span className=' w-full text-xs md:text-md  '>ข้อมูลการแพ้ ไม่มีส่วนประกอบ</span>
                  <div className='w-full flex'>
                    <input className='my-auto mr-4' type={"checkbox"} 
                        onChange={ (e)=>{
                          console.log(e.target.checked)
                          setCrustaceans(e.target.checked)
                        } } 
                    /> <span>สัตว์น้ำเปลือกแข็ง</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setEggs(e.target.checked)
                        } } 
                    /> <span>ไข่</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setFish(e.target.checked)
                        } }                     
                    /> <span>ปลา</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setGluten(e.target.checked)
                        } }                     
                    /> <span>กลูเตน</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setMilk(e.target.checked)
                        } }                     
                    /> <span>นม</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setNut(e.target.checked)
                        } }                    
                    /> <span>ถั่ว</span>
                    <input className='my-auto mr-4 ml-6' type={"checkbox"} 
                        onChange={ (e)=>{
                          setSulfites(e.target.checked)
                        } }                    
                    /> <span>ซัลไฟต์</span>
                  </div>
                </div>

                <div className="flex-wrap   w-full flex  my-auto mx-2  ">
                  <button onClick={ ()=> { submit( menuId ) } }  className='bg-green-600 px-4 py-2 rounded-lg text-white'>ค้นหา</button>
                </div>



              {/* <div className=' flex-wrap w-full mt-12'>
                <div className='flex'>
                  <span className=' w-full text-xs md:text-md  '>จำนวนผลลัพธ์</span>
                </div>
                <div className='flex'>
                  <span className=' text-sm md: text-md bg-gray-200 py-2 px-3 md:py-4 md:px-6 rounded-lg font-bold text-center'>{totalRowAll} รายการ</span>
                </div>
              </div> */}
              
              </div>

    
            

          </div>

          <div className='w-full flex flex-wrap'>

          {
                (foodchoiceData !== null)?

                  <div  className='flex flex-wrap w-full px-3 lg:px-6 border-b border-gray-300 py-6'>
                    <div className='w-full flex flex-wrap mt-3 mb-6'>
                      <span className='text-lg font-bold'>  Foodchoice</span>
                    </div>
                    <div className='w-full flex flex-wrap text-sm'>
                      <div className='w-full'>
                        <span className=' text-lg font-bold'>{ foodchoiceData.overview.name }</span>
                      </div>
                      <div className='w-full'>
                        <span className=' text-lg'>ต่อ { foodchoiceData.overview.per_portion } { foodchoiceData.overview.per_portion_packaging } ( { foodchoiceData.overview.per_portion_gram } )</span>
                      </div>
                      <div className='w-full mt-2'>
                        <span className=' text-sm'>วันที่แก้ไขล่าสุด : { foodchoiceData.update_at }</span>
                      </div>
                    </div>

                    <div className='w-full  mt-6 flex-wrap flex justify-center'>
                    {
                      foodchoiceData.nutrients.map(function(item, index){
                        return (
                          <div className="flex w-[80px] h-[120px] mx-2">
                            <div className="flex-wrap w-[80px]  h-[120px] rounded-t-full " style={{ backgroundColor : item.score.color }}>
                              <div className="flex-wrap mt-[8px] mx-[5px] rounded-full bg-white w-[70px] h-[70px] flex align-middle ">
                                <div className="w-full flex-wrap my-auto">
                                  <span className="flex w-full justify-center text-md">{ item.value }</span>
                                  <span className="flex w-full justify-center text-sm">{ item.unit }</span>
                                </div>
                              </div>

                              <div className="w-full flex-wrap my-auto mt-1">
                                  <span className="flex w-full justify-center text-sm text-white">{ item.description }</span>
                                  <span className="flex w-full justify-center text-xs text-white">{ item.percentage }</span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    </div>


                    <div className='w-full flex flex-wrap text-sm mt-6'>
                      <div className='w-full'>
                        <span className=' text-lg'>กลุ่ม {((foodchoiceData.overview.criteria_depart_of_health) !== null )? foodchoiceData.overview.criteria_depart_of_health.description : ""}</span>
                      </div>
                      <div className='w-full'>
                        <span className=' text-lg'>กลุ่มส่วนประกอบ {((foodchoiceData.component_groups) !== null )? getComponentGroup(foodchoiceData.component_groups) : ""}</span>
                      </div>
                      <div className='w-full  mt-2 flex flex-wrap'>
                        {
                          foodchoiceData.component_groups.map(function(item, index){
                            return (
                              getComponentGroupsImages(parseInt(item.group_id))
                            )
                          })
                        }
                    
                      
                      
                      </div>
                      
                      <div className='w-full mt-2'>
                        <span className=' text-md'>น้ำตาล { (foodchoiceData.sugar_add_on > 0)? foodchoiceData.sugar_add_on : " - "} ช้อนชา</span>
                      </div>
                      <div className='w-full mt-2'>
                        <span className=' text-md text-red-500'>ข้อมูลสำหรับผู้แพ้อาหาร: {foodchoiceData.allergens.summary}</span>
                      </div>
                      {
                        foodchoiceData.recommendation !== null ?

                          <div className='w-full mt-2 flex flex-wrap'>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับโรคความดันโลหิตสูง:</span>
                            <span>{ foodchoiceData.recommendation.for_high_blood_pressure }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับโรคอ้วน:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_obesity }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับโรคเบาหวาน:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_diabetes }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับหญิงตั้งครรภ์:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_pregnant_women }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับเด็ก:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_children }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับโรคไขมันในเลือดสูง:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_hypercholesterolemia }</span>
                            <span className='w-full font-bold mt-2'>คำแนะนำสำหรับโรคไต:</span>
                            <span className='w-full'>{ foodchoiceData.recommendation.for_kidney_disease }</span>

                          </div>
                          :
                          null
                      }
                    </div>

                  </div>
                  :
                  null
          }

          </div>


          <div className='w-full flex flex-wrap'>
            <div className='w-full flex flex-wrap mt-3 my-6 justify-center'>
                <span className='text-lg font-bold '>  ผลิตภัณฑ์ที่เกี่ยวข้อง</span>
            </div>
          {
            (foodchoiceRelationshipData === null)?
              null
              :
                
                  (foodchoiceRelationshipData).map(function(item, index){

                    return (
                      <div  className='flex flex-wrap w-full px-3 lg:px-6 border-b border-gray-300 py-6'>

                        <div className='w-full flex flex-wrap text-sm'>
                        
                          <div className='w-full'>
                            <span className=' text-lg font-bold'>#{ (index+1) }</span>
                          </div>
                          <div className='w-full'>
                            <span className=' text-lg font-bold'>{ item.overview.name }</span>
                          </div>
                          <div className='w-full'>
                            <span className=' text-lg'>ต่อ { item.overview.per_portion } { item.overview.per_portion_packaging } ( { item.overview.per_portion_gram } )</span>
                          </div>
                          <div className='w-full mt-2'>
                            <span className=' text-sm'>วันที่แก้ไขล่าสุด : { item.update_at }</span>
                          </div>
                        </div>
                        <div className='w-full  mt-6 flex-wrap flex justify-center'>
                        {
                          item.nutrients.map(function(item, index){
                            return (
                              <div className="flex w-[80px] h-[120px] mx-2">
                                <div className="flex-wrap w-[80px]  h-[120px] rounded-t-full " style={{ backgroundColor : item.score.color }}>
                                    <div className="flex-wrap mt-[8px] mx-[5px] rounded-full bg-white w-[70px] h-[70px] flex align-middle ">
                                      <div className="w-full flex-wrap my-auto">
                                        <span className="flex w-full justify-center text-md">{ item.value }</span>
                                        <span className="flex w-full justify-center text-sm">{ item.unit }</span>
                                      </div>
                                    </div>

                                    <div className="w-full flex-wrap my-auto mt-1">
                                        <span className="flex w-full justify-center text-sm text-white">{ item.description }</span>
                                        <span className="flex w-full justify-center text-xs text-white">{ item.percentage }</span>
                                    </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        </div>



                        <div className='w-full flex flex-wrap text-sm mt-6'>
                          <div className='w-full'>
                            <span className=' text-lg'>กลุ่ม {((item.overview.criteria_depart_of_health) !== null )? item.overview.criteria_depart_of_health.description : ""}</span>
                          </div>
                          <div className='w-full'>
                            <span className=' text-lg'>กลุ่มส่วนประกอบ {((item.component_groups) != null )? getComponentGroup(item.component_groups) : "-"}</span>
                          </div>
                          <div className='w-full mt-2 flex flex-wrap'>
                            {
                              item.component_groups.map(function(itemg, index){
                                return ( getComponentGroupsImages(parseInt(itemg.group_id)) )
                              })
                            }
                        
                          
                          
                          </div>
                          <div className='w-full mt-2'>
                            <span className=' text-md'>น้ำตาล { (item.sugar_add_on > 0)? item.sugar_add_on : " - "} ช้อนชา</span>
                          </div>
                          <div className='w-full mt-2'>
                            <span className=' text-md text-red-500'>ข้อมูลสำหรับผู้แพ้อาหาร: {item.allergens.summary}</span>
                          </div>
                        </div>

                      </div>
                    )



                  }
                  
                  )

          }

          <div className="flex-wrap   w-full flex  my-auto mx-2  ">
            {
              ( foodchoiceData === null)?
                null:

                <button onClick={ ()=> { gotoNextPage( (page+1) ) } }  className='bg-green-600 px-6 py-2 my-12 rounded-lg text-white'>
                  เพิ่มเติม {(page+1)}
                </button>
            }
          </div>

          </div>


      </div>
    </div>
  );

}

export default ScanFoodChoice;