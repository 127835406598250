import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Header() {
  
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal)


  useEffect(() => {
    window.scrollTo(0, 0)
    isLogin()
  }, [])

  function isLogin(){
    let token = localStorage.getItem("foodchoice_token")
    if(token === null || token === undefined) {
      logout()
    }
    if(token.length > 0){

    }
    else{
      logout()
    }
  }

  function gotoUserManage(){
    navigate("/user")
  }

  function gotoHome(){
    navigate("/")
  }

  function gotoMessage(){
    navigate("/message")
  }

  function gotoChallenge(){
    navigate("/challenge/main")
  }

  function logout(){
    localStorage.setItem('foodchoice_menu_id', 1)
    localStorage.setItem("foodchoice_token", "")
    navigate("/login")
  }

  function getUsername(){
    return localStorage.getItem("foodchoice_username")
  }

  return (
    // <div class="w-full flex bg-blue-700 py-6 z-50">
    <div class="w-full flex bg-foodchoice-yellow-500 py-6 z-50">
    <div className="grow w-full flex">
      <span onClick={gotoHome} className=" text-foodchoice-violet-500 font-bold ml-3">FoodChoice</span>
      <button onClick={gotoChallenge} className=" text-foodchoice-red-500  px-3">Challenge</button>
    </div>
    <div className="flex-none flex flex-wrap">

      <button onClick={gotoUserManage} className="px-2 text-black text-xs">รายชื่อผู้ใช้งาน</button>
      <div className="flex  border-l border-black pl-4">
        <button onClick={logout} className="text-black text-xs ml-3">{getUsername()} ( ออกจากระบบ )</button>
      </div>
    </div>
  </div>
  )
}

export default Header;