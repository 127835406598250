import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Header from '../pages/Header.js'


import iconTile from '../images/ic_tile.svg';
import iconArrowRight from '../images/ic_arrow_right.svg';
import iconPeople from '../images/ic_people_green.svg';
import iconDel from '../images/ic_delete_orange.svg';


function ChallengeTeamDetailPage() {
  
  const navigate = useNavigate();
  const { state } = useLocation()
  const [ challengeId, setChallengeId ] = useState('')
  const [ challengeTeamId, setChallengeTeamId ] = useState('')

  const [ memberList, setMemberList] = useState([])

  useEffect(() => {
    window.scrollTo(0, 0)
    setChallengeId(state.challenge_id)
    setChallengeTeamId(state.challenge_team_id)
    callGetUserApi(state.challenge_team_id)
  }, [])



  function callGetUserApi( challenge_team_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_team_id: challenge_team_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallengeMemberInTeam.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    const result = (data.data.result )
    if( result !== undefined && result !== null){
      setMemberList(result)
    }
  
  }
}

  function deleteMember(item) {
    callDeleteMemberApi(item)
  }



  function callDeleteMemberApi( item ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          user_id: item.user_id,
          challenge_id: challengeId,
          challenge_team_id: challengeTeamId
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/deleteChallengeMemberInTeam.php', requestOptions)
    .then(response => response.json())
    .then(data => validateDeleteMemberApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateDeleteMemberApi(data ){
  callGetUserApi( challengeTeamId)
}

const gotoHome = (file) => {
  navigate("/challenge/main" , { replace: true })
}

const gotoBack = (file) => {
  navigate("/challenge/detail", {
    state : {
      challenge_id: challengeId,
    }
  })
}


const gotoNews = () => {
  navigate('/news/main')
}

  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12 py-[20px]">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button className='button-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button onClick={gotoNews} className='button-non-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6'>
            <div className='w-fit flex'>
              <span className=' text-foodchoice-red-500 text-3xl font-bold'>Challenge</span>
            </div>
            <div className='w-fit flex pl-6 items-end'>
              <span onClick={gotoHome}  className=' text-black text-lg'>หน้าหลัก</span>
              <span onClick={gotoBack}   className=' text-black text-lg ml-2'>&lt;&lt; แก้ไข Challenge</span>
              <span className=' text-foodchoice-violet-500 text-lg ml-2'>&lt;&lt; ดู/แก้ไข สมาชิกทีม</span>
            </div>
          </div>

       
                <div className='w-full flex flex-wrap mt-6 '>
                <div className='w-full flex flex-wrap'>
                  <div className='w-fit flex'>
                    <span className=' text-foodchoice-violet-500 text-2xl'>ทีม</span>
                  </div>
                  <div className='w-fit flex items-end'>
                    <span className=' text-black text-md ml-4'>จำนวนสมาชิก { memberList.length } คน</span>
                  </div>
                  
                </div>
    
                <div className='w-full flex flex-wrap bg-foodchoice-blue-400 p-6 mt-2'>
                  <div className='w-full flex flex-wrap mb-2'>
                    <div className='w-1/12 flex flex-wrap py-2 pl-2'>
                      <span className=' text-foodchoice-gray-500'>ลำดับที่</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap items-center py-2'>
                      <span className=' text-foodchoice-gray-500'>ชื่อสมาชิก</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap  py-2'>
                      <span className=' text-foodchoice-gray-500'>อีเมล</span>
                    </div>
                    <div className='w-2/12 flex flex-wrap py-2'>
                      <span className=' text-foodchoice-gray-500'>เบอร์โทรศัพท์</span>
                    </div>
                    <div className='w-1/12 flex flex-wrap  py-2'>
                    
                    </div>
                    <div className='w-2/12 flex flex-wrap  py-2'>
                    
                    </div>
                    <div className='w-2/12 flex flex-wrap'>
              
                    </div>
                  </div>
                  {
                    memberList.map(function(item, index) {
                      return (
                        <div className='w-full flex flex-wrap mb-2'>
                          <div className='w-1/12 bg-white flex flex-wrap py-2 pl-2'>
                            <span className=' text-black'>{ (index+1) }</span>
                          </div>
                          <div className='w-2/12 bg-white flex flex-wrap items-center py-2'>
                            <span className=' text-black'>{ item.display_name }</span>
                          </div>
                          <div className='w-2/12  bg-white flex flex-wrap  py-2'>
                            <span className=' text-black'>{ item.email }</span>
                          </div>
                          <div className='w-2/12 bg-white  flex flex-wrap py-2'>
                            <span className=' text-black'>{ item.tel }</span>
                          </div>
                          <div className='w-1/12 flex flex-wrap  py-2'>
                                
                              <div 
                              onClick={ (e) => { deleteMember(item) }}
                              className='w-fit cursor-pointer  flex bg-white border border-foodchoice-orange-500 ml-4 py-2 px-4'>
                                <div className='w-fit flex my-auto '>
                                  <img
                                    src={iconDel}
                                    className="w-4 h-4"
                                    alt=""
                                    />
                                </div>
                                <div className='w-fit ml-4'>
                                  <span className=' text-black'>ลบ</span>
                                </div>
                              </div>
                          </div>
                          <div className='w-2/12 flex flex-wrap  py-2'>
                          
                          </div>
                          <div className='w-2/12 flex flex-wrap'>
                  
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
               


                </div>  
              
          
        </div>



      </div>
  )
}

export default ChallengeTeamDetailPage;