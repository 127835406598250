import React, { useState, useEffect, useRef } from 'react';
import  { useNavigate, useLocation,  } from 'react-router-dom'

import Header from '../pages/Header.js'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import { MapInteractionCSS } from 'react-map-interaction';

import EditProductPage from '../components/EditProductPage.js';

function NewProductDetailPage( props ) {
  
  const navigate = useNavigate();
  const { state } = useLocation()

  const MySwal = withReactContent(Swal)


  useEffect(() => {
    window.scrollTo(0, 0)
    setBarcode(state.barcode)
    callGetImageProductApi(state.barcode)
  }, [])


  const [ rotateImageViewDegree, setRotateImageViewDegree ] = useState(0)
  const [ imgControlValue, setImgControlValue ] = useState(
    {
      scale: 1,
      translation: { x: 0, y: 0 }
    }
  )
  const [ imagePreview, setImagePreview ] = useState("")
  const [ imageType, setImageType ] = useState(1)
  const [ imageTypeListAll, setImageTypeListAll ] = useState([])
  const [ imageTypeList, setImageTypeList ] = useState([])


  function getImageList(){

    let oldList = [...imageTypeListAll]
    let result = []
    for(let i = 0; i < oldList.length; i++){
      let img = oldList[i]
      if(parseInt(img.image_type.id) === imageType){
        result.push(img)
      }
  
    }
    //setImageTypeList(result)
    if(result.length > 0){
      //setImagePreview(result[0].path)
    }
    //console.log(result)
    return result
  }

  const [ barcode, setBarcode ] = useState("")
  

  function callGetImageProductApi( barcode ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        barcode: barcode
      })
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getProductsImageByBarcode.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetImageProductApi(data), (error) => {});
  
  }

  function validateGetImageProductApi( data ){

    if( data.data !== undefined){
        let result = data.data.result
        setImageTypeListAll(result)
    }
  }



  return (
      <div className="flex flex-wrap bg-white font-sarabun w-full justify-center " >


        <div class="w-full flex ">
          <Header/>
        </div>

        <div class="w-full flex">

            { /* Image Editor */}
            <div className='w-2/6 mx-auto h-screen   flex-wrap px-1 lg:px-6 py-10 '>
              <div className='w-full '>
                <select value={imageType} onChange={(e)=>{ setImageType(parseInt(e.target.value))}}  className="flex border-grey-500 border text-sm py-2 px-3 rounded-md">
                  <option value={1}>ด้านหน้าผลิตภัณฑ์</option>
                  <option value={2}>ข้อมูลโภชนาการ</option>
                  <option value={3}>ส่วนประกอบโดยประมาณ</option>
                  <option value={4}>เลขที่ อย. 13 หลัก</option>
                </select>
              </div>
              <div className="my-2 w-full">
                  <button onClick={(e)=>{ setRotateImageViewDegree( (rotateImageViewDegree-10) < 0 ? 360 : (rotateImageViewDegree-10)  )  }} className="bg-white hover:bg-gray-400  p-2 border border-gray-400 text-sm mr-1">หมุนซ้าย</button>
                  <button onClick={(e)=>{ setRotateImageViewDegree( (rotateImageViewDegree+10) > 360 ? 0 : (rotateImageViewDegree+10)  )  }} className="bg-white hover:bg-gray-400 my-1 p-2 border border-gray-400 text-sm">หมุนขวา</button>
                  <button onClick={(e)=>{ setRotateImageViewDegree(  0 ); setImgControlValue(  { scale: 1, translation: { x: 0, y: 0 }});  }} className="bg-white hover:bg-gray-400  p-2 border border-gray-400 text-sm ml-1">Reset</button>
                
              </div>
                
              <div className=' relative w-full h-[400px]  mt-2  bg-gray-200  align-middle '>
              
                <MapInteractionCSS
                    value={ imgControlValue }
                    onChange={(value) => setImgControlValue(value)}
                >
                        <img src={imagePreview}  style={{transform: "rotate("+(rotateImageViewDegree)+"deg)"}} /> 
                </MapInteractionCSS>
          

              </div>
              <div className='w-full flex  flex-wrap mt-2 '>
                {
                  getImageList().map(function(item, index){
                    return (
                      <div className='w-2/4 lg:w-1/4 px-2 py-2'>
                        <img onClick={(e)=>{ setImagePreview(item.path) }} src={item.path} alt="img-product"/>
                    </div>
                    )
                  })
                }
              </div>
            </div>

            <div className='w-4/6  flex-wrap '>
              <EditProductPage barcode={barcode}  is_new={true}  />
            </div>

        </div>
        
      </div>
  )
}

export default NewProductDetailPage;