import { Suspense }  from 'react';
import  { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css';

import LoginPage from '../src/pages/LoginPage'

import SlidingMenu from '../src/pages/SlidingMenu'

import EditorDetailPage from '../src/pages/EditorDetailPage'
import HomePage from '../src/pages/HomePage'
import UserPage from '../src/pages/UserPage'
import UserNewPage from '../src/pages/UserNewPage'
import UserEditPage from '../src/pages/UserEditPage'
import UserHistoryPage from '../src/pages/UserHistoryPage'
import ProductHistoryDetail from '../src/pages/ProductHistoryDetail'
import ComponentEditPage from '../src/pages/ComponentEditPage'
import NewProductDetailPage from '../src/pages/NewProductDetailPage'
import ProblemProductDetailPage from '../src/pages/ProblemProductDetailPage'

import MessagePage from '../src/pages/MessagePage'
import MessageNewPage from '../src/pages/MessageNewPage'
import MessageEditPage from '../src/pages/MessageEditPage'

import ChallengePage from '../src/pages/ChallengePage'
import ChallengeNewPage from '../src/pages/ChallengeNewPage'
import ChallengeEditPage from '../src/pages/ChallengeEditPage'
import ChallengeRankingPage from '../src/pages/ChallengeRankingPage'

import ChallengeMainPage from '../src/pages/ChallengeMainPage'
import ChallengeDetailPage from '../src/pages/ChallengeDetailPage'
import ChallengeMemberDetailPage from '../src/pages/ChallengeMemberDetailPage'
import ChallengeTeamDetailPage from '../src/pages/ChallengeTeamDetailPage'
import ChallengeTeamRankingPage from '../src/pages/ChallengeTeamRankingPage'
import ChallengeCreatePage from '../src/pages/ChallengeCreatePage'



import NewsMainPage from '../src/pages/NewsMainPage'
import NewsCreatePage from '../src/pages/NewsCreatePage'
import NewsDetailPage from '../src/pages/NewsDetailPage'

import LeaderBoardTeamPage from '../src/pages/LeaderBoardTeamPage'
import LeaderBoardMemberPage from '../src/pages/LeaderBoardMemberPage'


import LeaderBoardMemberPublishPage from '../src/pages/LeaderBoardMemberPublishPage'
import LeaderBoardTeamPublishPage from '../src/pages/LeaderBoardTeamPublishPage'

function App() {
  return (
  <Suspense  fallback="loading">
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/detail" element={<EditorDetailPage />} />
        <Route exact path="/detail/history" element={<ProductHistoryDetail />} />
        
        <Route exact path="/user" element={<UserPage />} />
        <Route exact path="/user/create" element={<UserNewPage />} />
        <Route exact path="/user/edit" element={<UserEditPage />} />
        <Route exact path="/user/history" element={<UserHistoryPage />} />

        <Route exact path="/component/edit" element={<ComponentEditPage />} />

        <Route exact path="/newproduct/detail" element={<NewProductDetailPage />} />
        <Route exact path="/problemproduct/detail" element={<ProblemProductDetailPage />} />

        <Route exact path="/message" element={<MessagePage />} />
        <Route exact path="/message/create" element={<MessageNewPage />} />
        <Route exact path="/message/edit" element={<MessageEditPage />} />
        
        <Route exact path="/challenge" element={<ChallengePage />} />
        {/* <Route exact path="/challenge/create" element={<ChallengeNewPage />} /> */}
        <Route exact path="/challenge/edit" element={<ChallengeEditPage />} />
        <Route exact path="/challenge/ranking" element={<ChallengeRankingPage />} />

        <Route exact path="/challenge/main" element={<ChallengeMainPage />} />
        <Route exact path="/challenge/detail" element={<ChallengeDetailPage />} />
        <Route exact path="/challenge/member" element={<ChallengeMemberDetailPage />} />
        <Route exact path="/challenge/team" element={<ChallengeTeamDetailPage />} />
        <Route exact path="/challenge/team/ranking" element={<ChallengeTeamRankingPage />} />
        <Route exact path="/challenge/create" element={<ChallengeCreatePage />} />
        
        
        <Route exact path="/news/main" element={<NewsMainPage />} />
        <Route exact path="/news/create" element={<NewsCreatePage />} />
        <Route exact path="/news/detail" element={<NewsDetailPage />} />

        <Route exact path="/leaderboard/team" element={<LeaderBoardTeamPage />} />
        <Route exact path="/leaderboard/member" element={<LeaderBoardMemberPage />} />



        <Route exact path="/score/member" element={<LeaderBoardMemberPublishPage />} />
        <Route exact path="/score/team" element={<LeaderBoardTeamPublishPage />} />       
        
      </Routes>
    </BrowserRouter>
  </Suspense>

  );
}

export default App;
