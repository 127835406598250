import React, { useState, useEffect } from 'react';
import  { useNavigate } from 'react-router-dom'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Header from '../pages/Header.js'
import SlidingMenu from '../pages/SlidingMenu.js'

import ProductList from '../components/ProductList.js';
import FoodChoiceList from '../components/FoodChoiceList.js';
import ScanFoodChoice from '../components/ScanFoodChoice.js';
import ComponentList from '../components/ComponentList.js';
import NewProductList from '../components/NewProductList.js';
import ProblemProductList from '../components/ProblemProductList.js';

function HomePage() {
  
  const navigate = useNavigate();


  const [ summary, setSummary ] = useState({

  })

  
  const [ menuId, setMenuId ] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0)
    getDefaultMenuId()
    callSummaryProductsApi()
  }, [ ])


  function getDefaultMenuId(){
    const prevMenu = localStorage.getItem('foodchoice_menu_id')
    console.log("prevMenu: "+prevMenu)
    if (prevMenu !== undefined && prevMenu !== null && prevMenu !== '' ) {
      setMenuId(parseInt(prevMenu))
    } else {
      localStorage.setItem('foodchoice_menu_id', 1)
      setMenuId(1)
    }
  }


  function onHandlerSelectMenu( id ){
    console.log(id)
    setMenuId(id)
  }


function callSummaryProductsApi( ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
        }
      )
    };

    fetch('https://foodchoice.in.th/api/v1/web/getCountProducts.php', requestOptions)
    .then(response => response.json())
    .then(data => validateSummaryProductApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });

  }

  function validateSummaryProductApi(data ){
    if( data.data !== undefined){
      setSummary(data.data)
    }
  }

  function changeMenu(menuId){
    localStorage.setItem('foodchoice_menu_id', menuId)
    console.log("changeMenu:" +menuId)
    setMenuId(parseInt(menuId))
  }


  function getContent( menuId ){
    console.log('getContent: '+menuId)
    if(menuId  < 5){
      return   <ProductList menu={menuId}></ProductList>
    }
    else if(menuId  === 5){
      return <FoodChoiceList menu={menuId}></FoodChoiceList>
    }
    else if(menuId  >= 7 && menuId <= 8){
      return <ComponentList menu={menuId}></ComponentList>
    }
    else if(menuId  === (101)){
      return <NewProductList menu={menuId}></NewProductList>
    }
    else if( menuId  === (102)){
      return <ProblemProductList menu={menuId}></ProblemProductList>
    }
    else {
      return <ScanFoodChoice />
    }
  }


  return (
      <div className="antialiased  flex flex-wrap bg-gray-200 font-sarabun w-full overflow-x-hidden" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap md:flex-nowrap bg-gray-200 mt-4">
          <div className=" md:hidden w-full flex flex-wrap my-2 px-1">
            <span className='w-full'>เลือกเมนู</span>
            <select value={menuId} onChange={ (e) => { changeMenu(e.target.value) } } className='mt-2 w-full py-4 px-2 rounded-lg'>
              <option value={'1'}>จำนวนทั้งหมดในระบบ</option>
              <option value={'2'}>ข้อมูลที่แสดงผล</option>
              <option value={'4'}>ข้อมูลที่ลบแล้ว</option>
              <option value={'7'}>ส่วนประกอบ: จัดกลุ่มแล้ว</option>
              <option value={'8'}>ส่วนประกอบ: ยังไม่ได้จัดกลุ่ม</option>
              <option value={'5'}>รายการผลิตภัณฑ์ Foodchoice</option>
              <option value={'6'}>Scan ผลิตภัณฑ์ Foodchoice</option>
              <option value={'101'}>รายงานผลิตภัณฑ์ใหม่</option>
              <option value={'102'}>รายงานผลิตภัณฑ์พบปัญหา</option>
            </select>
          </div>
          <div className='hidden lg:flex flex-none w-[300px]  overflow-y-auto h-fit min-h-screen my-4 mx-2'>
            <SlidingMenu data={summary} onSelectMenu={onHandlerSelectMenu} />
          </div>
          <div className="grow w-full flex flex-wrap  m-0 md:m-4 ">

              {
                getContent(menuId)
              }
          
            
          </div>
        </div>

      </div>
  )
}

export default HomePage;