import React, { useState, useEffect } from 'react';
import  { useNavigate, useLocation } from 'react-router-dom'


import Header from '../pages/Header.js'


import iconTile from '../images/ic_tile.svg';
import iconArrowRight from '../images/ic_arrow_right.svg';
import iconPeople from '../images/ic_people_green.svg';
import iconDel from '../images/ic_delete_orange.svg';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ChallengeDetailPage() {
  const navigate = useNavigate();
  const { state } = useLocation()
  const MySwal = withReactContent(Swal)

  
  const [ challengeId, setChallengeId ] = useState('')
  const [ topic, setTopic ] = useState('')
  const [ startDate, setStartDate ] = useState('')
  const [ endDate, setEndDate ] = useState('')
  const [ owner, setOwner ] = useState('')
  const [ imagePreview, setImagePreview ] = useState('')
  const [ imageCover, setImageCover ] = useState(null)
  const [ description, setDescription ] = useState('')
  const [ challengeType, setChallengeType ] = useState(1)
  const [ isPublic, setIsPublic ] = useState(1)

  const [ newTeamName, setNewTeamName ] = useState('')
  const [ newTeamNameForms, setNewTeamNameForms ] = useState([])
  const [ teams, setTeams ] = useState([])

  const [ activityCode, setActivityCode ] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    setChallengeId(state.challenge_id)
    callGetUserApi(state.challenge_id)
  }, [])



  function callGetUserApi( challenge_id ){
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(
        { 
          challenge_id: state.challenge_id
        }
      )
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/getChallengeDetail.php', requestOptions)
    .then(response => response.json())
    .then(data => validateGetUserApi(data), (error) => {
      if (error) {
        // handle error here
      }
    });
  
  }

function validateGetUserApi(data ){
  if( data.data.result !== undefined){
    const result = (data.data.result )
    if( result !== undefined && result !== null){
      setTopic(result.name)
      setStartDate(result.start_date)
      setEndDate(result.end_date)
      setOwner(result.owner)
      setImagePreview(result.cover)
      setDescription(result.description)
      setChallengeType(parseInt(result.challenge_type.id))
      setIsPublic(parseInt(result.is_public))

      setActivityCode(result.code)

      const t = result.teams;
      const ts = []
      const tForms = []
      for(let i = 0; i < t.length; i++) {
        const tName = t[i];
        ts.push({
          id: tName.challenge_team_id,
          name: tName.name,
          code: tName.code,
          is_new: false,
          is_edit: false,
          is_del: false
        })
        tForms.push(tName.name)
      }
      setTeams(ts)
      setNewTeamNameForms(tForms)
    }
  
  }
}



  const onChangeTopic = (event) => {
    setTopic(event.target.value)
  }  
  const onChangeStartDate = (event) => {
    setStartDate(event.target.value)
  }
  const onChangeEndDate = (event) => {
    setEndDate(event.target.value)
  }
  const onChangeOwner = (event) => {
    setOwner(event.target.value)
  }
  const onChangeImageCover = (event) => {
    if(event.target.files != null &&  event.target.files.length > 0){
      setImageCover(event.target.files[0])
      previewImage(event.target.files[0])
    }

  }
  const onChangeDescription = (event) => {
    setDescription(event.target.value)
  }
  const onChangeChallengeType = (event) => {
    setChallengeType(parseInt(event.target.value))
  }
  const onChangeIsPublic = (event) => {
    setIsPublic(parseInt(event.target.value))
  }
  

  const previewImage = (file) => {
    const objectUrl = URL.createObjectURL(file)
    setImagePreview(objectUrl)
  }
  
  const gotoBack = (file) => {
    navigate("/challenge/main" , { replace: true })
  }

  
  function submit(){

    if( topic === "" ||  owner === "" || startDate === "" || endDate === "" ||  description === ""  ) {
      MySwal.fire({
        icon: 'warining',
        title: <p>ขออภัย</p>,
        text: "กรุณาระบุข้อมูลให้ครบถ้วน",
        confirmButtonText: '<span class="flex w-full">ตกลง</span>',
        confirmButtonColor: '#009640',
      })
    }
    else {
      callInsertMessagerApi()
    }

    

  }

  function callInsertMessagerApi(  ){

    const formData = new FormData();
    
    formData.append("challenge_id", challengeId)
    formData.append("cover", imageCover)
    formData.append("name", topic)
    formData.append("owner", owner)
    formData.append("description", description)
    formData.append("prize", "")
    formData.append("start_date", startDate)
    formData.append("end_date", endDate)
    formData.append("is_public", isPublic)
    formData.append("challenge_type_id", challengeType)
    formData.append("teams", JSON.stringify(teams))
    const requestOptions = {
      method: 'POST',
      body: formData
    };
  
    fetch('https://foodchoice.in.th/api/v1/web/updateChallenge.php', requestOptions)
    .then(response => response.json())
    .then(data => validateInsertUserApi(data), (error) => {
      if (error) {
        // handle error here
        MySwal.fire({
          icon: 'warining',
          title: <p>ขออภัย</p>,
          text: error,
          confirmButtonText: '<span class="flex w-full">ตกลง</span>',
          confirmButtonColor: '#009640',
        })
      }
    });
  
  }


function validateInsertUserApi(data ){
  if( data.code === 200){
    MySwal.fire({
      icon: 'warining',
      title: <p>บันทึก</p>,
      text: "บันทึกสำเร็จ",
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
    gotoBack()
  }
  else {
    MySwal.fire({
      icon: 'warining',
      title: <p>ขออภัย</p>,
      text: data.message,
      confirmButtonText: '<span class="flex w-full">ตกลง</span>',
      confirmButtonColor: '#009640',
    })
  }
}




const addTeam =  async () => {
  const code = Math.floor(Math.random() * 100000 ) + 10000
  await callInsertTeam( newTeamName, code)

  setNewTeamName('')
}

const deleteTeam = (data, index) => {
  if(data.id !== ''){
    callDeleteTeam(data.id)
  } else {
    callGetUserApi()
  }

}

const onChangeFromTeamName = (data, index) => {
  let teamsList = [...teams]
  teamsList[index].name = data;
  teamsList[index].is_edit = true;

  const prevTeamForms = [...newTeamNameForms]
  prevTeamForms[index] = data

  setTeams(teamsList)
  setNewTeamNameForms(prevTeamForms)
}


function callInsertTeam(newTeamName, code ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        challenge_id: state.challenge_id,
        name: newTeamName,
        code: code
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/insertChallengeTeam.php', requestOptions)
  .then(response => response.json())
  .then(data => validateInsertChallengeTeamApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}


function validateInsertChallengeTeamApi(data ){
  callGetUserApi()
}



function callDeleteTeam( challenge_team_id ){
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(
      { 
        challenge_team_id: challenge_team_id,
      }
    )
  };

  fetch('https://foodchoice.in.th/api/v1/web/deleteChallengeTeam.php', requestOptions)
  .then(response => response.json())
  .then(data => validateInsertChallengeTeamApi(data), (error) => {
    if (error) {
      // handle error here
    }
  });

}


function validateInsertChallengeTeamApi(data ){
  callGetUserApi()
}

const gotoMember = (item) => {
  navigate("/challenge/team", {
    state : {
      challenge_team_id : item.id,
      challenge_id : challengeId
    }
  })
}

  
const gotoNews = () => {
  navigate('/news/main')
}
  return (
      <div className="antialiased h-full min-h-screen   flex-wrap bg-white font-sarabun w-full" >

        <div class="w-full flex ">
          <Header/>
        </div>

  
        <div className="w-full flex flex-wrap bg-white px-12 py-[20px]">

          <div className='w-full flex flex-wrap mt-4'>
            <div className='w-fit flex flex-wrap'>
              <div className='w-fit'>
                <button className='button-active'>Challenge</button>
              </div>
            </div>
            <div className='w-fit flex flex-wrap ml-4'>
              <div className='w-fit'>
                <button onClick={gotoNews} className='button-non-active'>ข่าวประชาสัมพันธ์</button>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6'>
            <div className='w-fit flex'>
              <span className=' text-foodchoice-red-500 text-3xl font-bold'>Challenge</span>
            </div>
            <div className='w-fit flex pl-6 items-end'>
              <span onClick={gotoBack}  className=' text-black text-lg'>หน้าหลัก</span>
              <span className=' text-foodchoice-violet-500 text-lg ml-2'>&lt;&lt; แก้ไข  Challenge</span>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6 '>
            <span className=' w-full text-black text-lg'> แก้ไข Challenge</span>
          </div>
          <div className='w-full flex flex-wrap mt-2 '>
            <div className='w-1/3  flex flex-wrap px-2 '>
              <div className='w-full flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>
                <span className=' text-foodchoice-gray-500 '>หัวข้อ Challenge *</span>
                <div className='w-full flex flex-wrap'>
                <input type={"search"} 
                  value={topic}
                  onChange={ (e) => { onChangeTopic(e) }}
                  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                </div>
                <span className=' text-foodchoice-gray-500 mt-4'>วันเริ่มต้นกิจกรรม *</span>
                <div className='w-full flex flex-wrap'>
                <input type={"date"} 
                  value={startDate}
                  onChange={ (e) => { onChangeStartDate(e) }}
                  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                </div>
                <span className=' text-foodchoice-gray-500 mt-4 '>วันสิ้นสุดกิจกรรม *</span>
                <div className='w-full flex flex-wrap'>
                <input type={"date"} 
                  value={endDate}
                  onChange={ (e) => { onChangeEndDate(e) }}
                  className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                </div>
              </div>
            </div>

            <div className='w-1/3 flex flex-wrap  px-2 '>
              <div className='w-full flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>

                <span className=' text-foodchoice-gray-500 '>ผู้จัดกิจกรรม *</span>
                <div className='w-full flex flex-wrap'>
                  <input type={"search"} 
                    value={owner}
                    onChange={ (e) => { onChangeOwner(e) }}
                    className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                  </div>

                  <span className=' text-foodchoice-gray-500 mt-4'>รูปภาพกิจกรรม *</span>
                  <div className={( imagePreview !== '' ? ' h-fit ' : ' h-[200px] ')+' w-full relative   flex flex-wrap  border-grey-500 border text-sm rounded-md  border-grey-500 bg-white'}>
                      {
                        imagePreview !== "" ?
                
                          <div className=' relative h-fit w-full'>
                            <img alt="" src={imagePreview} width='100%' height={'100%'}  className=' object-cover' />
                          </div>
                        :
                        null
                      }     
                      <div className=' absolute h-[200px]  w-full  text-foodchoice-gray-500  text-center '>
                        <div className=' relative w-full h-[200px]  py-24'>

                          <span className=' h-[200px]  relative text-foodchoice-gray-500 w-full text-center '>กดเพื่อเพิ่มรูป</span>

                          <input
                            onChange={(e) => { onChangeImageCover(e) }}
                            accept='image/png, image/jpg'
                            className="  absolute top-0 h-[200px]   w-full  cursor-pointer  block opacity-0 pin-r pin-t" type="file" name="vacancyImageFiles" />
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className='w-1/3 flex flex-wrap  px-2 '>
              <div className='w-full flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>
                  <span className=' text-foodchoice-gray-500 mt-4'>รายละเอียดกิจกรรม *</span>
                  <div className='w-full  flex-wrap'>
                    <textarea 
                    value={description}
                    onChange={ (e) => { onChangeDescription(e) }}
                    rows={8} 
                    className=' w-full justify-start border-grey-500 border text-sm px-3 rounded-md  border-grey-500 bg-white'/>
                  </div>
              </div>
            </div>


          </div>  
          <div className='w-full flex flex-wrap mt-6 '>
            <div className='w-full  flex flex-wrap px-2'>
              <div className='w-full flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>
                <span className=' text-foodchoice-gray-500 '>ประเภทการแข่งขัน *</span>
                <div className='w-full mt-4 '>
                  <input 
                  type={"radio"} 
                  className="mr-2" 
                  defaultChecked
                  disabled
                  checked={ challengeType === 1}
                  onChange={ (e) => { onChangeChallengeType(e) }}
                  name="radio-type" value={1} />บุคคลทั่วไป
                </div>
                <div className='w-full mt-4 '>
                  <input 
                  type={"radio"} 
                  className="mr-2" 
                  disabled
                  checked={ challengeType === 2}
                  onChange={ (e) => { onChangeChallengeType(e) }}
                  name="radio-type" value={2} />ทีม
                </div>
                <div className='w-full mt-4 '>
                  <input 
                  type={"radio"} 
                  className="mr-2" 
                  disabled
                  checked={ challengeType === 3}
                  onChange={ (e) => { onChangeChallengeType(e) }}
                  name="radio-type" value={3} />กิจกรรม
                </div>


                <div className={ ( challengeType === 2 ? ' flex ' : ' hidden ')+'w-full  flex-wrap pl-12 mt-4'}>

                  <div className='w-full flex flex-wrap'>
                
                    <div className='w-[180px] flex ml-10 justify-center'>
                      <span className=' text-foodchoice-gray-500'>ชื่อทีม</span>
                    </div>

                    <div className='w-[180px] flex  ml-10 justify-center'>
                      <span className=' text-foodchoice-gray-500'>รหัสทีม</span>
                    </div>

                   

                  </div>
                  {
                    teams.map(function(item, index){
                      return (
                        
                        <div 
                        
                        className='w-full flex flex-wrap  mt-4'>
                        <div className='w-fit flex'>
                          <div className='w-fit flex my-auto'>
                            <img
                              src={iconTile}
                              className="w-6 h-6"
                              alt=""
                              />
                          </div>
                        </div>
                        <div className='w-[180px] flex ml-4'>
                          <input type={"search"} 
                          value={item.name}
                          onChange={ (e) => { onChangeFromTeamName(e.target.value, index)}}
                          className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                        </div>
                        <div className='w-fit flex ml-4'>
                          <div className='w-fit flex my-auto'>
                            <img
                              src={iconArrowRight}
                              className="w-6 h-6"
                              alt=""
                              />
                          </div>
                        </div>

                        <div className='w-[180px] flex  ml-4'>
                          <input type={"search"} 
                          value={item.code}
                          disabled
                          className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                        </div>

                        <div 
                        onClick={ (e) => { gotoMember(item) }}
                        className='w-fit cursor-pointer  flex bg-white border border-foodchoice-green-500 ml-4 py-2 px-4'>
                          <div className='w-fit flex my-auto '>
                            <img
                              src={iconPeople}
                              className="w-4 h-4"
                              alt=""
                              />
                          </div>
                          <div className='w-fit ml-4'>
                            <span className=' text-black'>ดู/แก้ไข สมาชิกทีม</span>
                          </div>
                        </div>

        
                        <div 
                        onClick={ (e) => { deleteTeam(item, index) }}
                        className='w-fit cursor-pointer  flex bg-white border border-foodchoice-orange-500 ml-4 py-2 px-4'>
                          <div className='w-fit flex my-auto '>
                            <img
                              src={iconDel}
                              className="w-4 h-4"
                              alt=""
                              />
                          </div>
                          <div className='w-fit ml-4'>
                            <span className=' text-black'>ลบ</span>
                          </div>
                        </div>

                        </div>
                      )
                    })
                  }



                  <div className='w-full flex flex-wrap  mt-4'>
                    <div className='w-fit flex'>
                      <div className='w-fit flex my-auto'>
                        <img
                          src={iconTile}
                          className="w-6 h-6"
                          alt=""
                          />
                      </div>
                    </div>
                    <div className='w-[180px] flex ml-4'>
                      <input type={"search"} 
                      value={newTeamName}
                      onChange={ (e) => { setNewTeamName(e.target.value) }}
                      className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                    </div>

     

                  </div>

                  <div className='w-full flex flex-wrap mt-4'>
                    <div 
                    onClick={addTeam}
                    className='w-fit cursor-pointer text-foodchoice-violet-500  flex bg-white border border-foodchoice-violet-500  ml-4 py-2 px-4'>
                      <div className='w-fit flex my-auto '>
                        +
                      </div>
                      <div className='w-fit ml-4'>
                        <span className='text-foodchoice-violet-500'>เพิ่มทีม</span>
                      </div>
                    </div>
                  </div>


                </div>

                <div className={ ( challengeType === 3 ? ' flex ' : ' hidden ')+'w-full  flex-wrap pl-12 mt-4'}>

                  <div className='w-full flex flex-wrap'>

                    <div className='w-[180px] flex  ml-10 justify-center'>
                      <span className=' text-foodchoice-gray-500'>รหัส</span>
                    </div>

                   

                  </div>
                  <div 
                        
                        className='w-full flex flex-wrap  mt-4'>
                        <div className='w-fit flex'>
                          <div className='w-fit flex my-auto'>
                            <img
                              src={iconTile}
                              className="w-6 h-6"
                              alt=""
                              />
                          </div>
                        </div>

                        <div className='w-[180px] flex  ml-4'>
                          <input type={"search"} 
                          value={activityCode}
                          disabled
                          className={"border-grey-500 border w-full text-sm py-2 px-3 rounded-md  border-grey-500"} />
                        </div>


                        </div>





                </div>
              </div>
            </div>
          </div>
          

          <div className='w-full flex flex-wrap mt-6 '>
            <div className='w-full  flex flex-wrap px-2'>
              <div className='w-full flex-wrap border shadow-md border-foodchoice-yellow-500 p-4 bg-foodchoice-blue-400 '>
                <span className=' text-foodchoice-gray-500 '>การบริหารกิจกรรม *</span>
                <div className='w-fit mt-4 '>
                  <input 
                  type={"radio"} 
                  className="mr-2" 
                  defaultChecked
                  checked={ isPublic === 1 }
                  onChange={ (e) => { onChangeIsPublic(e) }}
                  name="radio-public" value={1} />เปิด
                </div>
                <div className='w-fit mt-4 '>
                  <input 
                  type={"radio"} 
                  className="mr-2" 
                  checked={ isPublic === 0 }
                  onChange={ (e) => { onChangeIsPublic(e) }}
                  name="radio-public" value={0} />ปิด
                </div>
              </div>
            </div>
          </div>

          <div className='w-full flex flex-wrap mt-6 '>
            <div className='w-full  flex flex-wrap px-2'>
              <div className='w-fit flex'>
                <button 
                onClick={submit}
                className='w-full button-violet flex flex-wrap'>
                  <div className='flex items-center my-auto'>
                    <span className='  text-white text-xl font-medium px-12'>บันทึก</span>
                  </div>
                </button>
              </div>
              <div className='w-fit flex ml-5'>
                <button 
                onClick={gotoBack}
                className='w-full button-orange flex flex-wrap'>
                  <div className='flex items-center my-auto'>
                    <span className='  text-white text-xl font-medium px-12'>ยกเลิก</span>
                  </div>
                </button>
              </div>'
            </div>
          </div>
        </div>



      </div>
  )
}

export default ChallengeDetailPage;